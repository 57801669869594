/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { logoutUser } from "redux/UserAuthentication/LoginActions";
import { studentsLifetimePlan } from "util/DataList";
import { useEffect, useState } from "react";

const AdminNavbar = (props) => {

  const history = useHistory()
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true)
  const [isUAS, setUAS] = useState()
  const [profilePictureFilename, setProfilePictureFilename] = useState('')

  const [isStudent, setStudent] = useState()

    
  useEffect(() => {
    if(isLoading){
       Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
    }else{
      Swal.close()
    }
  },[isLoading])

  useEffect(() => {
    if(props.userDetails.coach === true || props.userDetails.coach == "true" ){
      setStudent(false)
      setLoading(false)
    }
    if(props.userDetails.admin === true || props.userDetails.admin == "true" ){
      setStudent(false)
      setLoading(false)
    }
  },[props.userDetails])


  useEffect(() => {
    if(props.userDetails.profilePicture !== undefined){
      setProfilePictureFilename(props.userDetails.profilePicture)
    }
  }, [props.userDetails])

  useEffect(() => {
    if(props.userDetails.ultimateAdvisingStudent !== undefined){
      if(props.userDetails.ultimateAdvisingStudent == "true" || props.userDetails.ultimateAdvisingStudent === true || studentsLifetimePlan.includes(props.userDetails.email)){
        setUAS(true)
        setLoading(false)
          
      }else{
        setUAS(false)
        setLoading(false)
      }
    }
    
  }, [props.userDetails]);

  useEffect(() => {
    if(props.createProfilePicture.profilepicture.length){
      setProfilePictureFilename(props.createProfilePicture.profilepicture[0].profilePicture);
    } 
  }, [props.createProfilePicture])
  
  const logoutSubmit = (e) =>{
    e.preventDefault();
    dispatch(logoutUser());
  }

  const [name, setName] = useState('');

  useEffect(() => {

    if(typeof props.userDetails.name != "undefined"){
      setName(props.userDetails.name);
    }
    
  }, [props.userDetails])

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            {/* {
              !props.userDetails.ultimateAdvisingStudent || props.userDetails.ultimateAdvisingStudent == "false" ? 
              <a className={props.adminUser.admin ? 'btn btn-danger font-weight-bold shadow-none--hover disabled' : 'btn btn-danger font-weight-bold shadow-none--hover'} onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}} >
                <span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO ULTIMATE ADVISING STUDENT</a>
              : ""
            } */}
             {
              isLoading === false ? 
                isStudent ?
                  // props.userDetails.ultimateAdvisingStudent === false || props.userDetails.ultimateAdvisingStudent == "false" ? 
                  isUAS === false ? 
                    <a className={props.adminUser.admin ? 'btn btn-danger font-weight-bold shadow-none--hover disabled' : 'btn btn-danger font-weight-bold shadow-none--hover'} onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}} ><span className="fas fa-lock text-yellow"></span>&nbsp;UPGRADE TO ULTIMATE ADVISING STUDENT</a>
                    : <a className={props.adminUser.admin ? 'btn btn-warning font-weight-bold shadow-none--hover disabled' : 'btn btn-warning font-weight-bold shadow-none--hover'} onClick={(e) => {e.preventDefault(); history.push('/client/tpc-events')}} ><span className="ni ni-calendar-grid-58 text-white"></span>&nbsp;&nbsp;TPC EVENTS</a>
                : <a className={props.adminUser.admin ? 'btn btn-warning font-weight-bold shadow-none--hover disabled' : 'btn btn-warning font-weight-bold shadow-none--hover'} onClick={(e) => {e.preventDefault(); history.push('/client/tpc-events')}} ><span className="ni ni-calendar-grid-58 text-white"></span>&nbsp;&nbsp;TPC EVENTS</a>
             :<a className={props.adminUser.admin ? 'btn btn-warning font-weight-bold shadow-none--hover disabled' : 'btn btn-warning font-weight-bold shadow-none--hover'} onClick={(e) => {e.preventDefault(); history.push('/client/tpc-events')}} ><span className="ni ni-calendar-grid-58 text-white"></span>&nbsp;&nbsp;TPC EVENTS</a>
             
             }
             
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {profilePictureFilename ? <div className="card-profile-div-sm">
                    <div className="card-profile-image">
                      
                        <div className="profile-picture-sm" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/` + profilePictureFilename+`)`}}></div>
                      
                    </div>
                  </div> :
                  <div className="card-profile-image ">
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                      
                      <div className="rounded-circle profile-letter-sm ">{props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].fullName.trim()[0] : name.trim()[0]}</div>
                    {/* </a> */}
                  </div>}
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].fullName : name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {props.userDetails.admin == "true" ?
                  <DropdownItem to="/admin/my-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>

                  :

                  <DropdownItem to="/client/my-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                  </DropdownItem>
                }
                
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem href="#" onClick={(e) => logoutSubmit(e)}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = state  => {
  return {
      userDetails : state.loginAuth.userDetails,
      createProfilePicture : state.createProfilePicture,
      updateUserProfile : state.updateUserProfile,
      adminUser : state.adminUser
  }
    
}



export default connect(mapStateToProps)(AdminNavbar);
