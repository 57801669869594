import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MentionsInput, Mention} from "react-mentions";
import '../../assets/css/mention-style.css';
// import { Mention, MentionsInput } from 'react-mentions';
// import styles from './FormInputStyle.module.css';
// import mentionsInputStyle from './mentionsInputStyle';
// import mentionStyle from './mentionStyle';

import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
  CardText,
  Button,
  Row,
  Col,
  Badge,
  Input,
  Image,
  Form,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import LazyLoad from "react-lazyload";
import Header from "components/Headers/Header";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { app, db } from "../../firebase";
import {
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import Post from "components/Community/Post";
import SavedPost from "components/Community/SavedPost";
import SearchPost from "components/Community/SearchPost";
import { sendEmailNotification } from "redux/Community/EmailAction";
import {
  createCommunityPost,
  createCommunityPostReset,
  fetchLeaderboard,
  createCommunityLikePost,
  createCommunityLikeComment,
} from "redux/Community/CommunityAction";

import Payment from "./Payment";
import { studentsLifetimePlan } from "util/DataList";
const OpenCommunity = (props) => {
  // console.log(props)
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    getValues,
    unregister,
    watch,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();
  const [allPosts, setAllPosts] = useState([]);
  const [savePosts, setSavePosts] = useState([]);
  const [fileItemsLimit, setFileItemsLimit] = useState(10);
  const [imagePreview, setImagePreview] = useState([]);
  const [postContent, setPostContent] = useState("");
  const [category, setCategory] = useState("General");
  const fileInputRef = useRef(null);
  let toastId = useRef(null);
  const [anonymous, setAnonymous] = useState(false);
  const [searchPost, setSearchPost] = useState("");
  const [searchedPost, setSearchedPost] = useState([]);
  const [leaderboard, setleaderboard] = useState([]);
  const [leaderboardLoading, setFetchLoading] = useState([]);

  const searchAPost = async (value) => {
    let search = await arraySearch(allPosts, value);
    setSearchedPost(search);
    setSearchPost(value);
  };
  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      return (
        value.textContent.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.userHandle.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.category.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const catoptions = ["All", "General", "Classes", "MCAT"];

  const categoryOptions = [
    { value: "General", label: "General" },
    { value: "Classes", label: "Classes" },
    { value: "MCAT", label: "MCAT" },
  ];

  const [activeTab, setActiveTab] = useState("All");

  const [allUser, setAllUser] = useState([]);
  const [mentionValue, setMentionValue] = useState([]);
  
  useEffect(() => {
    const fetchAllUser  = async () => {
      let users = [];
      const userCollection = collection(db, "users");
      const getUser = query(userCollection, orderBy('name'));
      const querySnapshot = await getDocs(getUser);
      querySnapshot.forEach((doc) => {
        let v = doc.data();
         users.push({
          id: v.email,
          display: v.name,
        });
        setAllUser(users);
      });
    };
    fetchAllUser();
  }, [])

  const searchUser = (search) => {
    return allUser.filter(user => user.display.match(new RegExp(search, "i")));
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //Show posts in realtime
  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "communityPosts"), orderBy("createdAt", "desc")),
      (snapshot) => {
        setAllPosts(
          snapshot.docs.map((doc) => ({ ...doc.data(), postid: doc.id }))
        );
      },
      (error) => {
        console.log("sdds");
      }
    );
    return unsub;
  }, []);

  useEffect(() => {
    if (typeof props.userDetails.handle != "undefined") {
      const unsubsave = onSnapshot(
        query(
          collection(db, "communitySavePosts"),
          where("userHandle", "==", props.userDetails.handle),
          orderBy("createdAt", "desc")
        ),
        (snapshot) => {
          setSavePosts(
            snapshot.docs.map((doc) => ({ ...doc.data(), saveid: doc.id }))
          );
        },
        (error) => {}
      );
      return unsubsave;
    }
  }, []);

  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const createPost = () => {
    setBackdrop(true);
    setModal(true);
  };

  //Notification
  const showToastMessage = (msg) => {
    if (msg.status == "success") {
      toast.success(msg.text, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(msg.text, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const updateToastMessage = (msg) => {
    if (msg.status == "success") {
      toast.success(msg.text, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(msg.text, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Forms
  const handleChange = (event) => {
    setPostContent(event.target.value);
  };
  const handleCategoryChange = (data) => {
    setCategory(data.value);
  };

  // Files
  const clickInputFile = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const maxVideoSizeInMB = 20;
      const maxImageSizeInMB = 10;
      const allowedImgTypes = [
        "image/jpg",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];
      const allowedVideoTypes = ["video/mp4"];
      if (e.target.files.length > fileItemsLimit) {
        Swal.fire({
          title: "Upload limit!",
          text: " Files cannot be more than " + fileItemsLimit + " items",
          icon: "error",
        });
      } else {
        let pushItem = [];
        for (let i = 0; i < e.target.files.length; i++) {
          // console.log(e.target.files[i].name);
          // console.log(e.target.files.item(i).name);
          let fileSizeInMB = e.target.files[i].size / (1024 * 1024);
          if (allowedVideoTypes.includes(e.target.files[i].type)) {
            if (fileSizeInMB > maxVideoSizeInMB) {
              Swal.fire({
                title: "Maximum limit!",
                text:
                  e.target.files[i].name +
                  " File size exceeds the maximum limit of " +
                  maxVideoSizeInMB +
                  "MB",
                icon: "error",
              });
              return;
            } else {
              pushItem.push(e.target.files[i]);
            }
          } else if (allowedImgTypes.includes(e.target.files[i].type)) {
            if (fileSizeInMB > maxImageSizeInMB) {
              Swal.fire({
                title: "Maximum limit!",
                text:
                  e.target.files[i].name +
                  " File size exceeds the maximum limit of " +
                  maxImageSizeInMB +
                  "MB",
                icon: "error",
              });
              return;
            } else {
              pushItem.push(e.target.files[i]);
            }
          } else {
            Swal.fire({
              title: "Invalid file!",
              text: " Upoad a valid file ",
              icon: "error",
            });
            return;
          }
        }
        setImagePreview(pushItem);
      }
    }
  };

  useEffect(() => {
    if (fileInputRef) fileInputRef.current = null;
  }, [imagePreview]);

  const removeFile = (i) => {
    const newPreview = imagePreview.filter((item, index) => index !== i);
    setImagePreview(newPreview);
  };

  useEffect(() => {
    if (props.updateUserCommunitySettings.updateusercommunitysettings.length) {
      if (
        typeof props.updateUserCommunitySettings.updateusercommunitysettings[0]
          .anonymousPost &&
        props.updateUserCommunitySettings.updateusercommunitysettings[0]
          .anonymousPost === true
      ) {
        console.log(
          props.updateUserCommunitySettings.updateusercommunitysettings
        );
        setAnonymous(
          props.updateUserCommunitySettings.updateusercommunitysettings[0]
            .anonymousPost
        );
      } else {
        console.log("not");
      }
    } else {
      if (
        typeof props.userDetails.anonymousPost !== "undefined" &&
        props.userDetails.anonymousPost === true
      ) {
        console.log(props.userDetails);
        setAnonymous(props.userDetails.anonymousPost);
      } else {
        console.log("not2");
      }
    }
  }, [props.userDetails, props.updateUserCommunitySettings]);

  const onSubmit = async (data) => {
    const email = postContent.match(/[^(]+(?=\))/g);
    const usernameList = postContent.match(/\[(.*?)\]/g);
    let newContent = postContent;
    if(email) {
      let formattedUsers = [];
    usernameList.forEach(user => {
      const newUser = user.replace(/[\[\]']+/g, '');
      const trimmedUser = newUser.trimStart().split(" ");
      formattedUsers.push(trimmedUser[0]);
    });

    const user= {
      userName: formattedUsers,
    };

    const recipient = {
      email: email,
    };
    dispatch(sendEmailNotification({user: user, recipient: recipient }));

    mentionValue.forEach(mention => {
      const displayValue = mention.match(/\[(.*?)\]/g)[0].replace(/[\[\]']+/g, '');
      newContent = newContent.replaceAll(mention, `<span class='text-primary'>${displayValue}</span>`);
      setPostContent(newContent);
    });
    }
    if (category) {
      const formData = new FormData();
      formData.append("userHandle", props.userDetails.handle);
      formData.append("textContent", newContent);
      formData.append("userId", props.userDetails.userId);
      formData.append("category", category);

      if (imagePreview.length) {
        for (let i = 0; i < imagePreview.length; i++) {
          formData.append(`files[${i}]`, imagePreview[i]);
        }
      }

      if (anonymous === true) {
        formData.append("anonymous", 1);
        dispatch(createCommunityPost(formData));
        setImagePreview([]);
        setPostContent("");
      } else {
        dispatch(createCommunityPost(formData));
        setImagePreview([]);
        setPostContent("");
      }
    } else {
      Swal.fire({
        title: "No category!",
        text: "Please select a category.",
        icon: "info",
      });
    }
  };

  useEffect(() => {
    dispatch(fetchLeaderboard());
  }, []);

  useEffect(() => {
    if (props.fetchLeaderboard.loading) {
      setFetchLoading(true);
    } else {
      if (props.fetchLeaderboard.lb.length) {
        setleaderboard(props.fetchLeaderboard.lb);
        setFetchLoading(false);
      } else if (typeof props.fetchLeaderboard.error !== "undefined") {
        setFetchLoading(false);
      }
    }
  }, [props.fetchLeaderboard]);

  const loadContent = () => {
    for (let i = 0; i < 5; i++) {
      return (
        <ListGroupItem>
          <div className="d-flex justify-content-between align-items-center">
            <div className="media">
              <img
                src="https://picsum.photos/id/135/318/180?grayscale&blur=10"
                className="mr-3 rounded-circle placeholder placeholder-glow"
                alt="..."
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <div className="media-body">
                <h5 className=" placeholder-glow">
                  <span className=" placeholder">Something</span>
                </h5>
                <small className=" placeholder-glow">
                  <span className="placeholder">Something</span>
                </small>
              </div>
            </div>
            <div className="">
              <h1 className=" placeholder-glow">
                <span className=" placeholder">Something</span>
              </h1>
            </div>
          </div>
        </ListGroupItem>
      );
    }
  };

  useEffect(() => {
    if (props.createCommunityPost.loading) {
      toastId.current = toast("Creating a new post...", {
        type: toast.TYPE.INFO,
      });
    } else {
      if (props.createCommunityPost.createcommunitypost.length) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: "Sucessfully created a new post!",
        });

        toastId = null;
      } else if (typeof props.createCommunityPost.error !== "undefined") {
        const msg = {
          status: "error",
          text: "Oopps! something went wrong",
        };

        showToastMessage(msg);
      }
    }
  }, [props.createCommunityPost]);


  const [plan, setPlan] = useState("");
  const [subscribe, setSubscribe] = useState("lock");
  
  const fetchSubscriptionDetails = () => {
    if (
      props.userDetails.ultimateAdvisingStudent === true ||
      props.userDetails.ultimateAdvisingStudent == "true" ||
      studentsLifetimePlan.includes(props.userDetails.email)
    ) {
      setSubscribe("unlock");
    } else {
      if (props.fetchDetailsSubscription.fetchdetailssubscription.length) {
        const subsDetails =
          props.fetchDetailsSubscription.fetchdetailssubscription[0];

        if (subsDetails.status == "active" || subsDetails.status == "trialing") {
          setSubscribe("unlock");
        } else {
          const date = new Date();
          const secondsSinceEpoch = Math.floor(date.getTime() / 1000);

          if (subsDetails.cancel_at_period_end) {
            if (secondsSinceEpoch > subsDetails.canceled_at) {
              setSubscribe("lock");
            } else {
              setSubscribe("unlock");
            }
          } else {
            setSubscribe("lock");
          }
        }
      } else {
        setSubscribe("lock");
      }
    }
  };


  useEffect(() => {
    fetchSubscriptionDetails();
  }, [props.fetchDetailsSubscription, studentsLifetimePlan, props.userDetails]);

  return (
    <>
      <ToastContainer
        autoClose={3000}
        pauseOnFocusLoss={false}
        newestOnTop={true}
        theme="light"
      />

        { subscribe == "lock" ? (
          <>
            <div className="custom-modal parent-of-modal">
              <div
                className="custom-modal-backdrop"
                style={{ marginTop: "100px" }}
              >
                <div className="custom-modal-content">
                  <Container fluid className="mt--">
                    {plan == "" ? (
                      <>
                        <Row>
                          <div className="col">
                            <Row>
                              <Col lg="12" className="mt-3">
                                <Card className="border-0 shadow ">
                                  <CardBody>
                                    <Row>
                                      <Col lg="12">
                                        <p className="display-3 mb-0 text-center text-dark">
                                          Choose your Premed Tracker Plan
                                        </p>
                                        <p className="text-center h3 text-gray">
                                          Empower your medical journey through
                                          tailored subscription plans crafted
                                          for aspiring doctors.
                                        </p>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                        <Row className="mt-4">
                          <div className="col">
                            <Row>
                              <Col
                                xl="6"
                                lg="6"
                                md="12"
                                className="d-flex mt-3"
                              >
                                <Card className="border-0 shadow ">
                                  <CardHeader
                                    className="bg-info d-flex justify-content-center align-items-center"
                                    style={{ minHeight: "250px" }}
                                  >
                                    <div>
                                      <h1 className="h2 mb-0 text-center text-white">Monthly Pro Plan</h1>
                                      <h2 className="display-2 mb-0 text-center text-white">$35</h2>
                                      <p className='display-4 font-italic text-center text-white'>per month</p>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row className="pl-2 pr-2 h-100">
                                      <Col
                                        lg="12"
                                        className="d-flex align-items-center flex-column h-100"
                                        style={{ minHeight: "250px" }}
                                      >
                                        <h3 className="h3 mb-0 text-dark mb-3">
                                          Comprehensive package offering full
                                          access to all features and
                                          functionalities of the service,
                                          catering to users who want an
                                          all-inclusive experience with no
                                          limitations.
                                        </h3>
                                        <button
                                          className="btn btn-info mt-auto"
                                          disabled={
                                            props.userDetails.admin
                                              ? "true"
                                              : ""
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPlan(
                                              "premedTrackerProPlanMonthly35"
                                            );
                                          }}
                                        >
                                          Choose plan
                                        </button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col
                                xl="6"
                                lg="6"
                                md="12"
                                className="d-flex mt-3"
                              >
                                <Card className="border-0 shadow w-100">
                                  <CardHeader
                                    className="bg-warning d-flex justify-content-center align-items-center"
                                    style={{ minHeight: "250px" }}
                                  >
                                    <div>
                                      <h1 className="h2 mb-0 text-center text-white">Yearly Pro Plan</h1>
                                      <h2 className="display-2 mb-0 text-center text-white">$385</h2>
                                      <p className='display-4 font-italic text-center text-white mb-0'>per year - save $35!</p>
                                      <p className='h2 text-yellow text-center'>1 month free!</p>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row className="pl-2 pr-2 h-100">
                                      <Col
                                        lg="12"
                                        className="d-flex align-items-center flex-column h-100"
                                        style={{ minHeight: "250px" }}
                                      >
                                        <h3 className="h3 mb-0 text-dark mb-3">
                                          Comprehensive package offering full
                                          access to all features and
                                          functionalities of the service,
                                          catering to users who want an
                                          all-inclusive experience with no
                                          limitations.
                                        </h3>
                                        <button
                                          className="btn btn-warning mt-auto"
                                          disabled={
                                            props.userDetails.admin
                                              ? "true"
                                              : ""
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPlan(
                                              "premedTrackerProPlanYearly385"
                                            );
                                          }}
                                        >
                                          Choose plan
                                        </button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                              {/* <Col
                                xl="4"
                                lg="6"
                                md="12"
                                className="d-flex mt-3"
                              >
                                <Card className="border-0 shadow w-100">
                                  <CardHeader
                                    className="bg-red d-flex justify-content-center align-items-center"
                                    style={{ minHeight: "250px" }}
                                  >
                                    <div>
                                      <h1 className="h2 mb-0 text-center text-white">
                                        Lifetime
                                      </h1>
                                      <h2 className="display-2 mb-0 text-center text-white">
                                        $297
                                      </h2>
                                      <p className="display-4 font-italic text-center text-white mb-0">
                                        one-time
                                      </p>
                                      <p className="h2 text-yellow text-center">
                                        (limited to first 100 students)
                                      </p>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <Row className="pl-2 pr-2 h-100">
                                      <Col
                                        lg="12"
                                        className="d-flex align-items-center flex-column h-100"
                                        style={{ minHeight: "250px" }}
                                      >
                                        <h3 className="h3 mb-0 text-dark mb-3">
                                          Unlock unlimited access to all
                                          features and services for a lifetime.
                                          Enjoy the convenience of a one-time
                                          payment while experiencing the full
                                          range of benefits without any time
                                          constraints.
                                        </h3>
                                        <button
                                          className="btn btn-danger mt-auto"
                                          disabled={
                                            props.userDetails.admin
                                              ? "true"
                                              : ""
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPlan(
                                              "premedTrackerProPlanLifetime"
                                            );
                                          }}
                                        >
                                          Choose plan
                                        </button>
                                      </Col> */}
                                    {/* </Row>
                                  </CardBody>
                                </Card>
                              </Col> */}
                            </Row>
                          </div>
                        </Row>
                      </>
                    ) : (
                      <Payment selectedPlan={plan}></Payment>
                    )}
                  </Container>
                </div>
              </div>
            </div>
          </>
        ) : (
      <Container className="">
        <Row>
          <Col lg="8">
            <FormGroup>
              <InputGroup className="mb-4">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Search a post"
                  type="text"
                  onChange={(e) => searchAPost(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {searchPost ? (
          searchedPost.length ? (
            <SearchPost value={searchPost} items={searchedPost} />
          ) : (
            "No results found"
          )
        ) : (
          <>
            <Row>
              <Col lg="8" className="d-flex justify-content-between">
                <div>
                  <h1>Pro Member</h1>
                  <p className="text-muted">{allPosts.length + " Posts"}</p>
                </div>
                <div>
                  <Button
                    color="success"
                    className="float-right"
                    onClick={createPost}
                  >
                    Create Post
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="8" className="mt-3 mb-4 d-flex justify-content-center">
                <Nav fill pills>
                  {catoptions.length > 0
                    ? catoptions.map((c, i) => {
                        return (
                          <NavItem className="ml-1" key={i}>
                            <NavLink
                              key={c}
                              className={classnames({
                                active: activeTab === c,
                              })}
                              onClick={() => {
                                toggle(c);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {c}
                            </NavLink>
                          </NavItem>
                        );
                      })
                    : ""}
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col lg="8" className="mt-3 p-2">
                <TabContent activeTab={activeTab}>
                  {catoptions.map((c, i) => {
                    return (
                      <TabPane tabId={c} key={i}>
                        {allPosts.length > 0
                          ? allPosts.map((val, key) => {
                              return c == "All" ? (
                                <Post key={c + "-" + val.postid} item={val} userList={allUser}/>
                              ) : c == val.category ? (
                                <Post key={c + "-" + val.postid} item={val} userList={allUser}/>
                              ) : (
                                ""
                              );
                            })
                          : ""}
                      </TabPane>
                    );
                  })}
                </TabContent>
              </Col>
              <Col lg="4" className="mt-3 ">
                <Card className="shadow-lg  bg-white rounded mb-5">
                  <CardBody className="p-0">
                    <CardTitle tag="h3" className="text-center m-3">
                      Community Leaderboard{" "}
                    </CardTitle>

                    <ListGroup flush>
                      {leaderboardLoading ? (
                        <div className="m-5">
                          <Alert color="secondary">
                            Collecting data.. Loading{" "}
                            <Spinner size="sm" color="primary" />{" "}
                          </Alert>
                        </div>
                      ) : leaderboard.length ? (
                        leaderboard.slice(0, 10).map((v, i) => {
                          return (
                            <ListGroupItem key={i}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="media">
                                  {v.user.profilePicture ? (
                                    <img
                                      src={
                                        "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                                        v.user.profilePicture
                                      }
                                      className="mr-3 rounded-circle d-none d-md-block d-lg-block"
                                      alt="..."
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  ) : (
                                    <div className="rounded-circle profile-letter-all-users mr-3 ">
                                      {typeof v.user.name != "undefined"
                                        ? v.user.name.trim()[0]
                                        : ""}
                                    </div>
                                  )}
                                  <div className="">
                                    <h5 className="mt-2 mb-0 ">
                                      {v.user.name}
                                    </h5>
                                  </div>
                                </div>
                                <div className="">
                                  <h1>
                                    <span className="text-muted">
                                      {i + 1 + " "}
                                    </span>
                                    <i
                                      className={
                                        "fa fa-trophy " +
                                        (i == 0
                                          ? "text-yellow"
                                          : i == 1
                                          ? "text-info "
                                          : i == 2
                                          ? "text-warning"
                                          : "text-light")
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </h1>
                                </div>
                              </div>
                            </ListGroupItem>
                          );
                        })
                      ) : (
                        <div className="m-5">
                          <Alert color="primary">No data available...</Alert>
                        </div>
                      )}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
          // <Col lg="12">
          //   <Row>
          //     <Col lg="8" className="mt-3 mb-4">
          //       <Card className="shadow-lg  bg-white rounded ">
          //         <CardBody>
          //           <CardTitle tag="h5"> </CardTitle>

          //           <div className="d-flex flex-row align-items-center mb-1">
          //             {typeof props.userDetails.profilePicture !=
          //             "undefined" ? (
          //               <img
          //                 src={
          //                   "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
          //                   props.userDetails.profilePicture
          //                 }
          //                 className="mr-3 rounded-circle"
          //                 alt="..."
          //                 style={{
          //                   width: "70px",
          //                   height: "70px",
          //                 }}
          //               />
          //             ) : (
          //               <div
          //                 className="rounded-circle profile-letter-all-users mr-3"
          //                 style={{
          //                   width: "70px",
          //                   height: "70px",
          //                 }}
          //               >
          //                 {typeof props.userDetails.name != "undefined"
          //                   ? props.userDetails.name.trim()[0]
          //                   : ""}
          //               </div>
          //             )}

          //             <div className="media-body">
          //               <TextareaAutosize
          //                 className="inputCustom form-control"
          //                 placeholder="Write a post."
          //                 style={{
          //                   overflow: "hidden",
          //                   resize: "none",
          //                 }}
          //                 onChange={handleChange}
          //                 value={postContent}
          //               />
          //             </div>
          //           </div>
          //           <div
          //             className="d-flex flex-row align-items-center mb-1 ml-6"
          //             style={{ overflowY: "auto" }}
          //           >
          //             {imagePreview
          //               ? imagePreview.map((file, i) => {
          //                   return (
          //                     <div style={{ position: "relative" }}>
          //                       <Button
          //                         close
          //                         outline
          //                         onClick={(event) => removeFile(i)}
          //                       />
          //                       {file.type == "video/mp4" ? (
          //                         <video controls width="250" height="150">
          //                           <source
          //                             type={file.type}
          //                             src={URL.createObjectURL(file)}
          //                           />
          //                         </video>
          //                       ) : (
          //                         <img
          //                           src={URL.createObjectURL(file)}
          //                           alt=""
          //                           height="150"
          //                           width="250"
          //                           className=" m-2 rounded d-block "
          //                           style={{ overflowY: "scroll" }}
          //                         />
          //                       )}
          //                     </div>
          //                   );
          //                 })
          //               : ""}
          //           </div>

          //           <div className="border-top mt-3 mb-2"></div>
          //           <div className="d-flex  justify-content-between ">
          //             <div className="p-2 bg-white">
          //               <input
          //                 type="file"
          //                 id="files"
          //                 accept="video/*,image/*"
          //                 multiple
          //                 onChange={(event) => handleFileChange(event)}
          //                 ref={fileInputRef}
          //                 style={{ display: "none" }}
          //               />
          //               <Button
          //                 onClick={(event) => clickInputFile(event)}
          //                 color=""
          //                 style={{
          //                   fontSize: "16px",
          //                 }}
          //               >
          //                 <i className="fas fa-solid fa-camera fa-2xs "></i>
          //               </Button>
          //             </div>
          //             <div className="p-2 bg-white">
          //               <Select
          //                 className="float-left"
          //                 placeholder="Select a category"
          //                 options={categoryOptions}
          //                 onChange={handleCategoryChange}
          //                 defaultValue={{
          //                   label: "General",
          //                   value: "General",
          //                 }}
          //                 value={categoryOptions.find(
          //                   (option) => option.value === category
          //                 )}
          //               />
          //             </div>
          //             <div className="p-2 bg-white">
          //               <Button
          //                 color="primary"
          //                 style={
          //                   {
          //                     // fontSize: '16px',
          //                   }
          //                 }
          //                 disabled={
          //                   postContent || imagePreview.length ? false : true
          //                 }
          //                 onClick={onSubmit}
          //               >
          //                 Post
          //               </Button>
          //             </div>
          //           </div>
          //         </CardBody>
          //       </Card>
          //     </Col>
          //   </Row>

          //   {/* //Start Display Posts  */}
          //   <Row>
          //     <Col lg="8" className="mt-3 mb-4 d-flex justify-content-center">
          //       <Nav fill pills>
          //         {catoptions.length > 0
          //           ? catoptions.map((c, i) => {
          //               return (
          //                 <NavItem className="ml-1" key={i}>
          //                   <NavLink
          //                     className={classnames({
          //                       active: activeTab === c,
          //                     })}
          //                     onClick={() => {
          //                       toggle(c);
          //                     }}
          //                     style={{ cursor: "pointer" }}
          //                   >
          //                     {c}
          //                   </NavLink>
          //                 </NavItem>
          //               );
          //             })
          //           : ""}
          //       </Nav>
          //     </Col>
          //   </Row>
          //   <Row className="pl-4 pr-4 mt-3">
          //     <Col
          //       lg="8"
          //       className="mt-3 d-flex align-items-center justify-content-center"
          //     >
          //       <TabContent activeTab={activeTab}>
          //         {catoptions.map((c, i) => {
          //           return (
          //             <TabPane tabId={c} key={i}>
          //               <Row className="" style={{ width: "100%" }}>
          //                 <Col lg="12">
          //                   {allPosts.length > 0
          //                     ? allPosts.map((val, key) => {
          //                         return c == "All" ? (
          //                           <Post
          //                             key={c + "-" + val.postid}
          //                             item={val}
          //                           />
          //                         ) : c == val.category ? (
          //                           <Post
          //                             key={c + "-" + val.postid}
          //                             item={val}
          //                           />
          //                         ) : (
          //                           ""
          //                         );
          //                       })
          //                     : ""}
          //                 </Col>
          //               </Row>
          //             </TabPane>
          //           );
          //         })}
          //       </TabContent>
          //     </Col>
          //     <Col lg="4" className="mt-3 ">
          //       <Card className="shadow-lg  bg-white rounded mb-5">
          //         <CardBody className="p-0">
          //           <CardTitle tag="h3" className="text-center m-3">
          //             Community Leaderboard{" "}
          //           </CardTitle>

          //           <ListGroup flush>
          //             {leaderboardLoading ? (
          //               <div className="m-5">
          //                 <Alert color="secondary">
          //                   Collecting data.. Loading{" "}
          //                   <Spinner size="sm" color="primary" />{" "}
          //                 </Alert>
          //               </div>
          //             ) : leaderboard.length ? (
          //               leaderboard.slice(0, 10).map((v, i) => {
          //                 return (
          //                   <ListGroupItem>
          //                     <div className="d-flex justify-content-between align-items-center">
          //                       <div className="media">
          //                         {v.user.profilePicture ? (
          //                           <img
          //                             src={
          //                               "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
          //                               v.user.profilePicture
          //                             }
          //                             className="mr-3 rounded-circle d-none d-md-block d-lg-block"
          //                             alt="..."
          //                             style={{
          //                               width: "50px",
          //                               height: "50px",
          //                             }}
          //                           />
          //                         ) : (
          //                           <div className="rounded-circle profile-letter-all-users mr-3 ">
          //                             {typeof v.user.name != "undefined"
          //                               ? v.user.name.trim()[0]
          //                               : ""}
          //                           </div>
          //                         )}
          //                         <div className="">
          //                           <h5 className="mt-2 mb-0 ">
          //                             {v.user.name}
          //                           </h5>
          //                         </div>
          //                       </div>
          //                       <div className="">
          //                         <h1>
          //                           <span className="text-muted">
          //                             {i + 1 + " "}
          //                           </span>
          //                           <i
          //                             className={
          //                               "fa fa-trophy " +
          //                               (i == 0
          //                                 ? "text-yellow"
          //                                 : i == 1
          //                                 ? "text-info "
          //                                 : i == 2
          //                                 ? "text-warning"
          //                                 : "text-light")
          //                             }
          //                             aria-hidden="true"
          //                           ></i>
          //                         </h1>
          //                       </div>
          //                     </div>
          //                   </ListGroupItem>
          //                 );
          //               })
          //             ) : (
          //               <div className="m-5">
          //                 <Alert color="primary">No data available...</Alert>
          //               </div>
          //             )}
          //           </ListGroup>
          //         </CardBody>
          //       </Card>
          //       {/* <Card className="shadow-lg  bg-white rounded mb-5">
          //         <CardImg
          //           alt="Card image cap"
          //           src="https://picsum.photos/256/186"
          //           style={{
          //             height: 150,
          //           }}
          //           top
          //           width="100%"
          //         />
          //         <CardBody>
          //           <CardTitle tag="h5">Favorites</CardTitle>
          //           <CardSubtitle tag="h6" className="mb-2 text-muted">
          //             Your saved posts goes here.
          //           </CardSubtitle>

          //           <ListGroup flush>
          //             {savePosts.length > 0
          //               ? savePosts.map((v, i) => {
          //                   return <SavedPost key={v.saveid} item={v} />;
          //                 })
          //               : ""}
          //           </ListGroup>
          //         </CardBody>
          //       </Card> */}
          //     </Col>
          //   </Row>
          //   <Row className="pl-4 pr-4 mt-3"></Row>
          //   {/* //End Display Posts  */}
          // </Col>
        )}

        <Row className="mt-5"></Row>
        <Modal isOpen={modal} toggle={modalToggle} backdrop={backdrop} centered>
          <ModalHeader toggle={modalToggle}>Create Post</ModalHeader>
          <ModalBody className="pt-1">
            <div className="d-flex flex-row  mb-1">
              <img
                src={
                  "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                  props.userDetails.profilePicture
                }
                className="mr-2 rounded-circle"
                alt={props.userDetails.name}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <div className="media-body">
              <MentionsInput 
                onChange={handleChange} 
                value={postContent}
                className="mentionWrapper"
                placeholder="Type something here." 
                allowSuggestionsAboveCursor={true}
                allowSpaceInQuery={true}>
                  <Mention 
                  className="mentionedFriend"
                  appendSpaceOnAdd={true} 
                  data={searchUser} 
                  onAdd={(id, display) => {
                    setMentionValue([...mentionValue, "@[" + display + "](" + id + ")"]);
                    }}/>
                </MentionsInput>
              </div>
            </div>
            <div
              className="d-flex flex-row align-items-center mb-1 ml-6"
              style={{ overflowY: "auto" }}
            >
              {imagePreview
                ? imagePreview.map((file, i) => {
                    return (
                      <div style={{ position: "relative" }} key={file.name}>
                        <Button
                          close
                          outline
                          onClick={(event) => removeFile(i)}
                        />
                        {file.type == "video/mp4" ? (
                          <video controls width="250" height="150">
                            <source
                              type={file.type}
                              src={URL.createObjectURL(file)}
                            />
                          </video>
                        ) : (
                          <img
                            src={URL.createObjectURL(file)}
                            alt=""
                            height="150"
                            width="250"
                            className=" m-2 rounded d-block "
                            style={{ overflowY: "scroll" }}
                          />
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="border-top mt-3 mb-2"></div>
            <div className="d-flex align-items-center  justify-content-between ">
              <div className=" bg-white">
                <input
                  type="file"
                  id="files"
                  accept="video/*,image/*"
                  multiple
                  onChange={(event) => handleFileChange(event)}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <Button
                  onClick={(event) => clickInputFile(event)}
                  color=""
                  // style={{
                  //     fontSize: '16px',
                  // }}
                >
                  <i className="fas fa-solid fa-camera "></i>
                </Button>
              </div>
              <div>
                <Row>
                  <Col lg="12">
                    <Select
                      className="float-left"
                      placeholder="Select a category"
                      options={categoryOptions}
                      onChange={handleCategoryChange}
                      defaultValue={{ label: "General", value: "General" }}
                      value={categoryOptions.find(
                        (option) => option.value === category
                      )}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={onSubmit}
                  disabled={postContent || imagePreview.length ? false : true}
                >
                  Post
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Container>
      ) }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    createCommunityPost: state.createCommunityPost,
    fetchLeaderboard: state.fetchLeaderboard,
    updateUserProfile: state.updateUserCommunitySettings,
    updateUserCommunitySettings: state.updateUserCommunitySettings,
    sendEmailNotification: state.sendEmailNotification,
    fetchDetailsSubscription: state.fetchDetailsSubscription
  };
};

export default connect(mapStateToProps)(OpenCommunity);
