import React, {useRef, useState, useEffect} from 'react'
import Header from 'components/Headers/Header';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Media,
    Alert,
    Spinner,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
  
} from "reactstrap";

import {
  fetchUserDetails,
} from "redux/ManageUsers/ManageUsersAction";
import {fetchAppHourUsedLogs,fetchPremiumHourLogs} from "redux/Logs/LogsAction";
import StudentPremiumHourLogs from "components/Logs/StudentPremiumHourLogs";
import StudentFreeHourLogs from "components/Logs/StudentFreeHourLogs";
import Select from "react-select";
import DataTable from "react-data-table-component";
import ReactDatetime from "react-datetime";
import { connect, useDispatch } from 'react-redux';
import classnames from "classnames";
import moment from "moment-timezone";

const UserLogHistory = (props) => {
    const dispatch = useDispatch()

    const [userDetailsLoading, setUserDetailsLoading] = useState();
    const [selectedUser, setSelectedUser] = useState([]);

    //Modal Behaviour 
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const showLogHistory = (e, userId) =>{
        dispatch(fetchUserDetails({ userId: userId }));
        setBackdrop(true);
        setModal(true);
    }
    const [totalHour, setTotalHour] = useState(0)
    const calculateTotalHours = (selectedUser) =>{
         if(typeof selectedUser.totalHoursUsed !== "undefined"){
            let total = 0
            selectedUser.totalHoursUsed.map((v,i)=>(
                total+= Number(v.value)
            ))
            setTotalHour(total)
        }else{
            setTotalHour(0)
        }
    }

    
	useEffect(() => {
		if(props.fetchUserDetails.loading){
			setUserDetailsLoading(true)
		}else{
			setUserDetailsLoading(!userDetailsLoading)
			if (props.fetchUserDetails.userdetails.length) {
				setSelectedUser(props.fetchUserDetails.userdetails[0])
                calculateTotalHours(props.fetchUserDetails.userdetails[0])
            }
		}
		
	}, [props.fetchUserDetails]);

    //Tabs
    const mainopt = ["Coach/App Logs", "Premium Hour Logs"];
    const [mainActiveTab, setMainActiveTab] = useState("Coach/App Logs");

    const toggleMain = (tab) => {
        if (mainActiveTab !== tab) setMainActiveTab(tab);
    };

    return (
      <>
        <button
            onClick={(e) =>showLogHistory(e, props.userId)}
                className="btn btn-sm btn-primary"
        >
            Logs History
        </button>

        <Modal
            isOpen={modal}
            toggle={modalToggle}
            backdrop={backdrop}
            size="xl"
            centered
            >
            <ModalHeader toggle={modalToggle}>
               
            </ModalHeader>
            <ModalBody className="pt-1">
                {  userDetailsLoading ? 
                         <div className="m-5">
							<Alert color="secondary" className="text-center">
								Fetching student details.. Please wait while loading {" "}
								<Spinner size="sm" color="primary" />{" "}
							</Alert>
						</div>
                    :
                    <>
                    <Row>
                        <Col className="mt-3 mb-4 d-flex justify-content-center">
                            <Nav fill pills>
                                { mainopt.length > 0
                                    ? mainopt.map((c, i) => {
                                        return (
                                        <NavItem className="ml-1" key={i}>
                                            <NavLink
                                            key={c}
                                            className={classnames({
                                                active: mainActiveTab === c,
                                            })}
                                            onClick={() => {
                                                toggleMain(c);
                                            }}
                                            style={{ cursor: "pointer" }}
                                            >
                                            {c}
                                            </NavLink>
                                        </NavItem>
                                        );
                                    })
                                    :  ""
                                }
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" className="mt-3 p-2">
                            <TabContent activeTab={mainActiveTab}>
                                {mainopt.map((mc, i) => (
                                <TabPane tabId={mc} key={i}>
                                    {
                                        mc == "Coach/App Logs" ?
                                        <Card className="mb-3 shadow">
                                            <CardBody>
                                                <Row>
                                                    <Col xl="6">
                                                        {
                                                            selectedUser ? 
                                                                <Media className="align-items-center">
                                                                    {
                                                                        typeof selectedUser.profilePicture != "undefined" ? (
                                                                            <div className="media">
                                                                                <img 
                                                                                    src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
                                                                                    className="mr-3" 
                                                                                    alt="..." 
                                                                                    style={{
                                                                                        width: "60px",
                                                                                        height: "60px", 
                                                                                    }}
                                                                                />
                                                                                <div className="media-body">
                                                                                    <h5 className="mt-0">{selectedUser.name}</h5>
                                                                                    <small>{selectedUser.email}</small>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                    
                                                                        ) : 
                                                                        (
                                                                            <div className="media">
                                                                                <div className="rounded-circle profile-letter-all-users mr-2" 
                                                                                    style={{
                                                                                    width: "60px",
                                                                                    height: "60px",
                                                                                        fontSize: "16px"
                                                                                        }}>
                                                                                        {selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
                                                                                </div>
                                                                                <div className="media-body">
                                                                                    <h5 className="my-0">{selectedUser.name}</h5>
                                                                                    <small>{selectedUser.email}</small>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    
                                                                </Media>
                                                            : ""
                                                        }
                                                    </Col>
                                                    <Col xl="6"  className="mt-2">
                                                        <Card className="card-stats mb-4 mb-xl-1 w-100 shadow">
                                                            <CardBody>
                                                                <Row>
                                                                    <div className="col">
                                                                        <CardTitle
                                                                            tag="h5"
                                                                            className="text-uppercase mb-0"
                                                                        >
                                                                            Application Hours Used
                                                                        </CardTitle>
                                                                    
                                                                    </div>
                                                                    <Col className="col-auto">
                                                                        <span className="h2 font-weight-bold mb-0">
                                                                            {totalHour}
                                                                        </span>
                                                                        {/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                            <i className="fas fa-atom" />
                                                                        </div> */}
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="12 " className="mt-2"><StudentFreeHourLogs studenthandle={props.handle} /></Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        :   
                                        <Card className="mb-3 shadow">
                                            <CardBody>
                                                <Row>
                                                    <Col xl="6">
                                                        {
                                                            selectedUser ? 
                                                                <Media className="align-items-center">
                                                                    {
                                                                        typeof selectedUser.profilePicture != "undefined" ? (
                                                                            <div className="media">
                                                                                <img 
                                                                                    src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
                                                                                    className="mr-3" 
                                                                                    alt="..." 
                                                                                    style={{
                                                                                        width: "60px",
                                                                                        height: "60px", 
                                                                                    }}
                                                                                />
                                                                                <div className="media-body">
                                                                                    <h5 className="mt-0">{selectedUser.name}</h5>
                                                                                    <small>{selectedUser.email}</small>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                    
                                                                        ) : 
                                                                        (
                                                                            <div className="media">
                                                                                <div className="rounded-circle profile-letter-all-users mr-2" 
                                                                                    style={{
                                                                                    width: "60px",
                                                                                    height: "60px",
                                                                                        fontSize: "16px"
                                                                                        }}>
                                                                                        {selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
                                                                                </div>
                                                                                <div className="media-body">
                                                                                    <h5 className="my-0">{selectedUser.name}</h5>
                                                                                    <small>{selectedUser.email}</small>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    
                                                                </Media>
                                                            : ""
                                                        }
                                                    </Col>
                                                    <Col xl="6"  className="mt-2">
                                                        <Card className="card-stats mb-4 mb-xl-0 w-100 shadow">
                                                            <CardBody>
                                                                <Row>
                                                                    <div className="col">
                                                                        <CardTitle
                                                                            tag="h5"
                                                                            className="text-uppercase mb-0"
                                                                        >
                                                                            Remaining Premium Hours
                                                                        </CardTitle>
                                                                    
                                                                    </div>
                                                                    <Col className="col-auto">
                                                                        <span className="h2 font-weight-bold mb-0">
                                                                            {selectedUser.tutorHours}
                                                                        </span>
                                                                    
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col lg="12" className="mt-2"><StudentPremiumHourLogs studenthandle={props.handle} /></Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    }

                                </TabPane>
                                    ))
                                }
                            </TabContent>
                        </Col>
                    </Row>
                    </>
                }
                {/* <Row>
                    {  userDetailsLoading ? 
                        <Col lg="12"> 
							<Alert color="secondary" className="text-center">
								Fetching student details.. Please wait while loading {" "}
								<Spinner size="sm" color="primary" />{" "}
							</Alert>
						</Col>
                    :
                    <>
                        <Col lg="12"> 
                            <Card className="mb-3 shadow">
                                <CardBody>
                                    <Row>
                                        <Col xl="6">
                                            {
                                                selectedUser ? 
                                                    <Media className="align-items-center">
                                                        {
                                                            typeof selectedUser.profilePicture != "undefined" ? (
                                                                <div className="media">
                                                                    <img 
                                                                        src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
                                                                        className="mr-3" 
                                                                        alt="..." 
                                                                        style={{
                                                                            width: "60px",
                                                                            height: "60px", 
                                                                        }}
                                                                    />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">{selectedUser.name}</h5>
                                                                        <small>{selectedUser.email}</small>
                                                                    
                                                                    </div>
                                                                </div>
                                                        
                                                            ) : 
                                                            (
                                                                <div className="media">
                                                                    <div className="rounded-circle profile-letter-all-users mr-2" 
                                                                        style={{
                                                                        width: "60px",
                                                                        height: "60px",
                                                                            fontSize: "16px"
                                                                            }}>
                                                                            {selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h5 className="my-0">{selectedUser.name}</h5>
                                                                        <small>{selectedUser.email}</small>
                                                                    
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </Media>
                                                : ""
                                            }
                                        </Col>
                                        <Col xl="6"  className="mt-2">
											<Card className="card-stats mb-4 mb-xl-1 w-100 shadow">
												<CardBody>
													<Row>
														<div className="col">
															<CardTitle
																tag="h5"
																className="text-uppercase mb-0"
															>
																Application Hours Used
															</CardTitle>
														
														</div>
														<Col className="col-auto">
															<span className="h2 font-weight-bold mb-0">
																{totalHour}
															</span>
															
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
                                        <Col lg="12 " className="mt-2"><StudentFreeHourLogs studenthandle={props.handle} /></Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="12"> 
                            <Card className="mb-3 shadow">
                                <CardBody>
                                    <Row>
                                        <Col xl="6">
                                            {
                                                selectedUser ? 
                                                    <Media className="align-items-center">
                                                        {
                                                            typeof selectedUser.profilePicture != "undefined" ? (
                                                                <div className="media">
                                                                    <img 
                                                                        src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
                                                                        className="mr-3" 
                                                                        alt="..." 
                                                                        style={{
                                                                            width: "60px",
                                                                            height: "60px", 
                                                                        }}
                                                                    />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">{selectedUser.name}</h5>
                                                                        <small>{selectedUser.email}</small>
                                                                    
                                                                    </div>
                                                                </div>
                                                        
                                                            ) : 
                                                            (
                                                                <div className="media">
                                                                    <div className="rounded-circle profile-letter-all-users mr-2" 
                                                                        style={{
                                                                        width: "60px",
                                                                        height: "60px",
                                                                            fontSize: "16px"
                                                                            }}>
                                                                            {selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h5 className="my-0">{selectedUser.name}</h5>
                                                                        <small>{selectedUser.email}</small>
                                                                    
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </Media>
                                                : ""
                                            }
                                        </Col>
                                        <Col xl="6"  className="mt-2">
                                            <Card className="card-stats mb-4 mb-xl-0 w-100 shadow">
                                                <CardBody>
                                                    <Row>
                                                        <div className="col">
                                                            <CardTitle
                                                                tag="h5"
                                                                className="text-uppercase mb-0"
                                                            >
                                                                Remaining Premium Hours
                                                            </CardTitle>
                                                        
                                                        </div>
                                                        <Col className="col-auto">
                                                            <span className="h2 font-weight-bold mb-0">
                                                                {selectedUser.tutorHours}
                                                            </span>
                                                        
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="12" className="mt-2"><StudentPremiumHourLogs studenthandle={props.handle} /></Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </>
                        }
                </Row> */}
               
            
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={modalToggle}>
                Close
                </Button>
            </ModalFooter>
        </Modal>
      </>
    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchUserDetails:state.fetchUserDetails,
        fetchAppHourUsedLogs: state.fetchAppHourUsedLogs,
	    fetchPremiumHourLogs: state.fetchPremiumHourLogs,

    }
      
}

export default connect(mapStateToProps)(UserLogHistory)
