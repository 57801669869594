import Header from 'components/Headers/Header'
import React from 'react'


import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
} from "reactstrap";

import WorkActivitiesModal from 'components/Modal/WorkActivitiesModal';
import WorkActivitiesTable from 'components/Table/WorkActivitiesTable';

import { connect, useDispatch } from 'react-redux';
import {updateWorkActivityModal, newWorkActivity} from 'redux/WorkActivities/WorkActivitiesActions'
const WorkActivities = (props) => {
    const dispatch = useDispatch();

    const modalAddWorkActivities = props.updateWorkActivityModalStatus.status;

    const toggleAddWorkActivities = () =>{
      dispatch(updateWorkActivityModal(!props.updateWorkActivityModalStatus.status));
      dispatch(newWorkActivity({status: true}))
    } 

    // useEffect(() => {
      
  
      if(modalAddWorkActivities && props.createWorkActivities.workactivity.length && typeof props.createWorkActivities.error === "undefined"){
        dispatch(updateWorkActivityModal(false));
      }
  
      if(modalAddWorkActivities && props.updateWorkActivities.workactivity.length && typeof props.updateWorkActivities.error === "undefined"){
        dispatch(updateWorkActivityModal(false));
      }
  
      if(modalAddWorkActivities && typeof props.createWorkActivities.error !== "undefined"){
        dispatch(updateWorkActivityModal(false));
      }
  
      if(modalAddWorkActivities && typeof props.updateWorkActivities.error !== "undefined"){
        dispatch(updateWorkActivityModal(false));
      }

    // }, [])
    
    

    

    return (
    <>
        <Header></Header>
        <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='float-right'>
                    <Button color="primary" disabled={props.adminUser.admin ? "true" : ""} onClick={() => toggleAddWorkActivities()}>Add Work/Activities</Button>
                </div>
              </CardHeader>
              <WorkActivitiesTable />
              
            </Card>
          </div>
        </Row>
      </Container>
        <WorkActivitiesModal toggleAddWorkActivities={toggleAddWorkActivities} modalAddWorkActivities={modalAddWorkActivities} />
    </>
  )
}

const mapStateToProps = state => {
  return {
    createWorkActivities : state.createWorkActivities,
    updateWorkActivities : state.updateWorkActivities,
    updateWorkActivityModalStatus : state.updateWorkActivityModalStatus,
    editWorkActivity : state.editWorkActivity,
    adminUser : state.adminUser
  }
}



export default connect(mapStateToProps)(WorkActivities)