import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	CardFooter,
	Container,
	Row,
	Button,
	Table,
	Media,
	Badge,
	Pagination,
	PaginationItem,
	PaginationLink,
	Modal,
	Form,
	Col,
	Label,
	FormGroup,
    FormFeedback, 
	Input,
	ModalHeader,
	ModalFooter,
	ModalBody,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	CustomInput,
	Alert,
	Spinner,
	Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  fetchAllUsers,
  fetchUserDetails,
  updateUserDetails,
  updateUserDetailsReset,
  updateMultiUsers,
  updateMultiUsersReset,
  
} from "redux/ManageUsers/ManageUsersAction";
import {fetchUniversities}  from "redux/Users/UsersActions";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import { customDropdown } from "util/reactCustomStyles";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import ReactDatetime from "react-datetime";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { app, db } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import {fetchAppHourUsedLogs,fetchPremiumHourLogs,sendEmailApplicationHourUsed} from "redux/Logs/LogsAction";
import Logs from "components/Logs/Logs";
import StudentFreeHourLogs from "components/Logs/StudentFreeHourLogs";
import UserLogHistory from "components/Logs/UserLogHistory";
import StudentPremiumHourLogs from "components/Logs/StudentPremiumHourLogs";
import classnames from "classnames";





const ManageUsers = (props) => {
  	const dispatch = useDispatch();

  	// const [allUsers, setData] = useState([]);
  	const [allUsers2, setData2] = useState([]);

	const [loading, setLoading] = useState(true);
	const [userDetailsLoading, setUserDetailsLoading] = useState();
	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
  	const [filter, setFilter] = useState([]);

	const [checkUsers, setCheckUsers] = useState([]);
	const [school, setSchool] = useState('');
	const [universities, setUniversities] = useState([])

	const subscriptionFilter = [
		{ value: "", label: "ALL" },
		{ value: "Free Account", label: "Free Account" },
		{ value: "PRO Member", label: "PRO Member" },
		{ value: "Ultimate Advising Student", label: "Ultimate Advising Student" },
	];

	const [selectedHour, setSelectedHour] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [psValue, setPsValue] = useState(0)
    const [actValue, setActValue] = useState(0)
    const [miValue, setMiValue] = useState(0)
    const [secValue, setSecValue] = useState(0)
    const [generalValue, setGeneralValue] = useState(0)
    const [totalHour, setTotalHour] = useState(0)

	useEffect(() => {
		register("name");

		register("mcatDate");
		register("tutorHours");
		register("inputUworld");
		register("inputAamc");
		register("email");
		register("homeAddress");

		register("tpcEntryDate");
		register("mcatDate");
		register("schoolYear");
		register("university");

		register("selectedCountry");
		register("selectedCity");
		register("selectedState");
		register("postalCode");
		register("tutorHours");
		register("shadowGoal");
		register("clinicalGoal");
		register("volunteerGoal");

		register("ultimateAdvisingStudent");
		register("coach");
		register("proMemberStudent");
		
		register("assignedCoach");

		register("matriculated");

		// dispatch(
		// fetchAllUsers({
		// 	route: "",
		// 	order: order,
		// 	by: by,
		// 	searchFullName: searchFullName,
		// 	subscriptionFilter: subscription,
		// })
		// );
	}, []);

	useEffect(() => {
		if (props.fetchAllUsers.loading) {
			Swal.fire({
				allowOutsideClick: false,
				didOpen: () => {
				Swal.showLoading();
				},
			});
		} else {
			
			if (props.fetchAllUsers.allusers.length) {
				setData(props.fetchAllUsers.allusers);
				setFilter(props.fetchAllUsers.allusers);	
			}else{
				setData([]);
				setFilter([]);	
			}
			Swal.close();
			setLoading(false)
		}
	}, [props.fetchAllUsers]);

  	// useEffect(() => {
	// 	if(filter.length){
	// 		Swal.close();
	// 		setLoading(false)
	// 	}
  	// }, [filter]);

	const {
		register,
		getValues,
		unregister,
		watch,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	// useEffect(() => {
	// 	const fetchAllUsers = async () => {
	// 	const querySnapshot = await getDocs(collection(db, "users"));
	// 	let users = [];
	// 	querySnapshot.forEach((doc) => {
	// 		let v = doc.data();
	// 		users.push({
	// 		value: v.handle,
	// 		label: v.name,
	// 		image: typeof v.profilePicture != "undefined" ? v.profilePicture : "",
	// 		});
	// 	});
	// 	users.unshift({ value: "", label: "Select Users" });
	// 	setCheckUsers(users);
	// 	};
	// 	fetchAllUsers();
	// }, []);

	const [modalUpdateUser, setModalUpdateUser] = useState(false);
	const [modalUpdateMultiUsers, setModalUpdateMultiUsers] = useState(false);
	const [selectedUsers, setselectedUsers] = useState([]);

	const selectUsers = (e) => {
		const values = [...e].map((opt) => opt.value);
		setselectedUsers(values);
		setValue("selectUsers", values);
	};

	useEffect(() => {
		if(props.fetchUserDetails.loading){
			setUserDetailsLoading(true)
		}else{
			setUserDetailsLoading(!userDetailsLoading)
			if (props.fetchUserDetails.userdetails.length) {
				

				setSelectedUser(props.fetchUserDetails.userdetails[0])
				calculateTotalHours(props.fetchUserDetails.userdetails[0])
				setValue("name", props.fetchUserDetails.userdetails[0].name);

				setValue("email", props.fetchUserDetails.userdetails[0].email);
				setValue(
					"homeAddress",
					typeof props.fetchUserDetails.userdetails[0].homeAddress != "undefined"
					? props.fetchUserDetails.userdetails[0].homeAddress
					: ""
				);

				setValue(
					"tpcEntryDate",
					props.fetchUserDetails.userdetails[0].tpcEntryDate
				);
				setValue("mcatDate", props.fetchUserDetails.userdetails[0].mcatDate);
				setValue(
					"schoolYear",
					typeof props.fetchUserDetails.userdetails[0].schoolYear != "undefined"
					? props.fetchUserDetails.userdetails[0].schoolYear
					: ""
				);
				setValue(
					"university",
					typeof props.fetchUserDetails.userdetails[0].university != "undefined"
					? props.fetchUserDetails.userdetails[0].university
					: ""
				);

				setValue(
					"selectedCountry",
					typeof props.fetchUserDetails.userdetails[0].selectedCountry !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].selectedCountry
					: ""
				);
				setValue(
					"selectedCity",
					typeof props.fetchUserDetails.userdetails[0].selectedCity != "undefined"
					? props.fetchUserDetails.userdetails[0].selectedCity
					: ""
				);
				setValue(
					"selectedState",
					typeof props.fetchUserDetails.userdetails[0].selectedState !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].selectedState
					: ""
				);
				setValue(
					"postalCode",
					typeof props.fetchUserDetails.userdetails[0].postalCode != "undefined"
					? props.fetchUserDetails.userdetails[0].postalCode
					: ""
				);
				setValue("tutorHours", props.fetchUserDetails.userdetails[0].tutorHours);
				setValue("shadowGoal", props.fetchUserDetails.userdetails[0].shadowGoal);
				setValue(
					"clinicalGoal",
					props.fetchUserDetails.userdetails[0].clinicalGoal
				);
				setValue(
					"volunteerGoal",
					typeof props.fetchUserDetails.userdetails[0].volunteerGoal !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].volunteerGoal
					: 0
				);

				setValue("mcatDate", props.fetchUserDetails.userdetails[0].mcatDate);
				setValue("tutorHours", props.fetchUserDetails.userdetails[0].tutorHours);
				setValue(
					"inputUworld",
					props.fetchUserDetails.userdetails[0].subscriptions.includes("uworld")
					? "claimed"
					: "unclaimed"
				);
				setValue(
					"inputAamc",
					props.fetchUserDetails.userdetails[0].subscriptions.includes("aamc")
					? "claimed"
					: "unclaimed"
				);

				setValue(
					"ultimateAdvisingStudent",
					typeof props.fetchUserDetails.userdetails[0].ultimateAdvisingStudent !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].ultimateAdvisingStudent
					: false
				);
				setValue(
					"coach",
					typeof props.fetchUserDetails.userdetails[0].coach != "undefined"
					? props.fetchUserDetails.userdetails[0].coach
					: false
				);

				setValue(
					"assignedCoach",
					typeof props.fetchUserDetails.userdetails[0].assignedCoach !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].assignedCoach
					: ""
				);
				if (
					typeof props.fetchUserDetails.userdetails[0].assignedCoach !=
					"undefined"
				) {
					setAssignedCoach(props.fetchUserDetails.userdetails[0].assignedCoach);
				} else {
					setAssignedCoach("");
				}

				setValue(
					"matriculated",
					typeof props.fetchUserDetails.userdetails[0].matriculated !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].matriculated
					: ""
				);
				if (
					typeof props.fetchUserDetails.userdetails[0].matriculated !=
					"undefined"
				) {
					setSchool(props.fetchUserDetails.userdetails[0].matriculated);
				} else {
					setSchool("");
				}


				setValue(
					"proMemberStudent",
					typeof props.fetchUserDetails.userdetails[0].proMemberStudent !=
					"undefined"
					? props.fetchUserDetails.userdetails[0].proMemberStudent
					: false
				);

			}

		}
		
	}, [props.fetchUserDetails]);

	const toggleUpdateUser = () => {
		setModalUpdateUser(!modalUpdateUser);
	};

	const toggleUpdateMultiUsers = () => {
		setModalUpdateMultiUsers(!modalUpdateMultiUsers);
	};

	const editUserDetails = (e, data) => {
		e.preventDefault();
		setModalUpdateUser(data.modal);
		dispatch(fetchUserDetails({ userId: data.userId }));

		dispatch(fetchUniversities({}));

		setValue("name", "");
		setValue("email", "");
		setValue("homeAddress", "");
		setValue("tpcEntryDate", "");
		setValue("mcatDate", "");
		setValue("schoolYear", "");
		setValue("university", "");
		setValue("selectedCountry", "");
		setValue("selectedCity", "");
		setValue("selectedState", "");
		setValue("postalCode", "");
		setValue("tutorHours", "");
		setValue("shadowGoal", "");
		setValue("clinicalGoal", "");
		setValue("volunteerGoal", "");
		setValue("assignedCoach", "");

		// setValue("ultimateAdvisingStudent", "");
		// setValue("coach",  "");
	};

	const updateMultipleUsers = () => {
		setValue("tutorHours", "");
		setValue("assignedCoach", "");
		setAssignedCoach("");
		setValue("selectUsers", "");
		setValue("ultimateAdvisingStudent", "");
		setValue("proMemberStudent", "");

		const fetchAllUsers = async () => {
			const querySnapshot = await getDocs(collection(db, "users"));
			let users = [];
			querySnapshot.forEach((doc) => {
				let v = doc.data();
				users.push({
				value: v.handle,
				label: v.name,
				image: typeof v.profilePicture != "undefined" ? v.profilePicture : "",
				});
			});
			users.unshift({ value: "", label: "Select Users" });
			setCheckUsers(users);
		};

		fetchAllUsers();

		setModalUpdateMultiUsers(true);
	};

	const onSubmitUpdate = (data) => {
		data.subscriptions = [];

		if (data.inputAamc == "claimed") {
		data.subscriptions.push("aamc");
		}
		if (data.inputUworld == "claimed") {
		data.subscriptions.push("uworld");
		}
		dispatch(updateMultiUsers(data));
	};

  //   const Checkbox = ({ obj, onChange }) => {
  //     return (
  //       <CustomInput
  //         type="checkbox"
  //         id={`custom-checkbox-${obj.handle}`}
  //         name={obj.handle}
  //         value={obj.checked}
  //         onChange={() => onChange({ ...obj, checked: !obj.checked })}
  //       />
  //     );
  //   };

	const onSubmit = (data) => {
		data.subscriptions = [];

		if (data.inputAamc == "claimed") {
		data.subscriptions.push("aamc");
		}
		if (data.inputUworld == "claimed") {
		data.subscriptions.push("uworld");
		}
		data.handle = props.fetchUserDetails.userdetails[0].handle;
		dispatch(updateUserDetails(data));
	};

	useEffect(() => {
		if (props.updateUserDetails.loading) {
		Swal.fire({
			allowOutsideClick: false,
			didOpen: () => {
			Swal.showLoading();
			},
		});
		} else {
		Swal.fire({
			title: "Success!",
			text: "Successfully updated user!",
			icon: "success",
			timer: 3000,
		}).then(() => {
			// dispatch(updateUserDetailsReset());
			// dispatch(
			//   fetchAllUsers({
			//     route: "",
			//     subscriptionFilter: subscription,
			//   })
			// );
		});
		}
	}, [props.updateUserDetails]);

	const handleViewUser = (e, userDetails) => {
		e.preventDefault();
		Swal.fire({
			title: "View as user",
			text: "Do you want to view as "+userDetails.name+"?",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Yes",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(getUserData({ user: userDetails }));
			}
		});
		
	};

   

	const [logsData, setLogsData] = useState([]);
    useEffect(() => {
        if (props.fetchAppHourUsedLogs.loading) {
        } else {
            if (props.fetchAppHourUsedLogs.logs.length) {
                setLogsData(props.fetchAppHourUsedLogs.logs)
            }else{
                setLogsData([]) 
            }	
        }
	}, [props.fetchAppHourUsedLogs]);


	const [subscription, setSubscription] = useState("Ultimate Advising Student");
	function handleSubscriptionFilterOnChange(e) {
		setSubscription(e.value);
	}

   	const [getByCoach, setGetByCoach] = useState("");
  	const  handleGetByCoach = (e) => {
    	setGetByCoach(e.value);
		 dispatch(
		fetchAllUsers({
				route: "",
				coachFilter: e.value,
			})
		);

  	}

	// useEffect(() => {
	// 	dispatch(
	// 		fetchAllUsers({
	// 			route: "",
	// 			coachFilter: getByCoach,
	// 		})
	// 	);
	// }, [getByCoach]);

  useEffect(() => {
    dispatch(
      fetchAllUsers({
        route: "",
        subscriptionFilter: subscription,
      })
    );
    dispatch(fetchCoaches({}));
  }, [subscription]);

  const handleAssignedCoach = (selectedOption) => {
    setAssignedCoach(selectedOption.value);
    setValue("assignedCoach", selectedOption.value);
  };

  const resetFilter = () => {
	dispatch(
      fetchAllUsers({
        route: "",
        subscriptionFilter: "",
      })
    );

	setSubscription("")
	setGetByCoach("")

  }
  	const [coaches, setCoaches] = useState([]);
	const [coachOption, setCoachOption] = useState([]);
  	const [assignedcoach, setAssignedCoach] = useState("");

  useEffect(() => {
    if (props.fetchCoaches.fetchcoaches.length) {
      	const coachesArr = props.fetchCoaches.fetchcoaches[0];
      	setCoaches(coachesArr);

		const coachesOption = props.fetchCoaches.fetchcoaches[0];
		coachesOption.unshift({ value: "", label: "Select Coach" });
	  	setCoachOption(coachesOption);
    }
  }, [props.fetchCoaches]);


	useEffect(() => {
		if(props.fetchUniversities.universities.length){
		let universitiesList = props.fetchUniversities.universities[0].universities.sort()
		universitiesList = [...new Set(universitiesList.map((value, index) => {return {value:value, label: value}}))];
		setUniversities(universitiesList)
		}
	}, [props.fetchUniversities.universities])

	const handleUniversityChange = (selectedOption) => {
		setValue("matriculated", selectedOption.value);
		setSchool(selectedOption.value);
	};

	 const useHoursObj = [
        {category: "personal_statement", value: Number(psValue), prev: 0, max: 7},
        {category: "activities", value: Number(actValue) , prev: 0, max: 5},
        {category: "mock_interview", value: Number(miValue) , prev: 0, max: 2},
        {category: "secondaries", value: Number(secValue) , prev: 0, max: 6},
        {category: "general_coaching", value: Number(generalValue) , prev: 0, max: 6},
    ];

	const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }

	const columns = [
		{
			name: "Name",
			sortable: "true",
			// wrap: true,
			width: '250px',
			selector: (row) => {
				return(
					<Media className="align-items-center"  onClick={(e) => handleViewUser(e, row)} role="button">
						{
							typeof row.profilePicture != "undefined" ? (
							<div className="card-profile-div-sm card-profile-div-md">
								<div className="card-profile-image">
									<div
									className="profile-picture-sm profile-picture-md"
									style={{
										width: "38px",
										height: "38px",
										backgroundImage:
											'url("https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/' +
											row.profilePicture +
											'")',
									}}
									>
										
									</div>
								</div>
							</div>
							) : 
							(
							<div className="rounded-circle profile-letter-all-users mr-2" 
							style={{
							width: "30px",
							height: "30px",
								fontSize: "16px"
								}}>
								{row.name !== undefined ? row.name.trim()[0].toUpperCase() : "1" }
							</div>
							)
						}
						<span className="mb-0 text-sm" >{row.name}</span>
					</Media>
				)
			}
		},
		{
			name: "Log Hours",
			width: '200px',
			cell: (row) => (
				<>
					<button
						onClick={(e) =>
							editUserDetails(e, {
							modal: true,
							userId: row.userId,
							})
						}
							className="btn btn-sm btn-primary"
						>
						Edit
					</button>
					<button
                            onClick={(e) =>editStudentDetails(e, row.userId)}
                                className="btn btn-sm btn-primary text-truncate"
                            >
                            Log Hours
                    </button>
                    {/* <button
                            onClick={(e) =>managePremiumHours(e, row.userId)}
                                className="btn btn-sm btn-primary"
                            >
                        Premium Hours
                    </button> */}
					{/* <UserLogHistory userId={row.userId} handle={row.handle}/> */}
				</>
			),
			center: "true",
			wrap: "true"
		},
		{
			name: "Email",
			sortable: true,
			selector: (row) => {
				return row.email
			}
		},
		{
			name: "Subscription",
			center: "true",
			sortable: "true",
			selector: (row) =>   {
				return (
					row.ultimateAdvisingStudent == "true" || row.ultimateAdvisingStudent === true
					? "Ultimate Advising Student"
					: typeof row.subsId != "undefined"
					? "PRO Member"
					: "Free Account"
								
				)
			},
			
		},
		{
			name: "Coach",
			sortable: "true",
			center: "true",
			selector: (row) => {
				return row.mycoach ?  row.mycoach.name : "N/A";
			}
		},
		{
			name: "Matriculated",
			sortable: "true",
			center: "true",
			selector: (row) => {
				return  row.matriculated ? row.matriculated : 'N/A'
			}
		},
		{
			name: "Entry Date",
			sortable: "true",
			selector: (row) => {
				return convertDate(row.createdAt);
			}
		},
		
	];

	const tableHeaderstyle = {
		headCells: {
		style: {
			fontWeight: "bold",
			fontSize: "14px",
			// backgroundColor: "#ccc",
		},
		},
	};

	const [sessionDate, setSessionDate] = useState("")
    const [sessionFrom, setSessionFrom] = useState("")
    const [sessionTo, setSessionTo] = useState("")
    
    const handleChangeDate = (e) =>{
		if(typeof e === "object"){
        	setSessionDate(new Date(e.toJSON()));
		}else{
			setSessionDate("");
		}
    }
    const handleChangeFrom = (e) =>{
		if(typeof e === "object"){
        	setSessionFrom(new Date(e.toJSON()));
		}else{
			setSessionFrom("")
		}
    }
    const handleChangeTo = (e) =>{
		if(typeof e === "object"){
			setSessionTo(new Date(e.toJSON()));
		}else{
			setSessionTo("")
		}    
    }


	const convertTimeToUTC = (time) => {
        return moment(moment(time).utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

	const  saveUserSession = () =>{
        setHideError(false)

        let errors = 0
		if(!sessionDate || !sessionFrom || !sessionTo || !comment || !assignedcoach){
			errors = 1
		}else if(psValue === 0 && actValue === 0 && miValue === 0 && secValue === 0 && generalValue === 0 && phour === 0) {
			setNoValueError("Please input hour to a category to apply changes.")
			errors = 1
		}else if(Number(phour) > Number(selectedUser.tutorHours)){
			setPhour(0)
			errors = 1
			setHourErrorMsg( "Deduct hour should not be greater than the current value " )
		}else{
			errors = 0
		}


        if(typeof selectedUser.totalHoursUsed !== "undefined"){

            let updatedHour = []
           
            useHoursObj.forEach(function(d,i){
             
                if(typeof selectedUser.totalHoursUsed[i] !== "undefined"){
                    if(d.category == selectedUser.totalHoursUsed[i].category){
                        let updateValue =   Number(d.value) + Number(selectedUser.totalHoursUsed[i].value)
                        d.prev = selectedUser.totalHoursUsed[i].value
                        d.value = updateValue
                    }
                }
                updatedHour.push(d)
            });
          
            if(errors == 1){
              
            }else{
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to add hours?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes,'
                }).then((result) => {
                    if (result.isConfirmed) {
						setModal1(false)
                        const setData = {
                            by: props.userDetails.handle,
                            updatedBy: assignedcoach, //? assignedcoach : props.userDetails.handle,
                            handle: selectedUser.handle,
                            updateUsedHour: updatedHour,
                            date: moment.utc(sessionDate).format(),
                            startTime: moment.utc(sessionFrom).format(),
                            endTime: moment.utc(sessionTo).format(),
                            comment: comment,
                            mailDetails: {
                                coach: props.userDetails.name,
                                coachEmail: props.userDetails.email,
                                student: selectedUser.name,
                                studentEmail: selectedUser.email,

                            }
                        }
						if(phour != 0){
							setData.updatePremiumHour = {
								category: "premium_hour",
								prev: Number(selectedUser.tutorHours),
								value : Number(selectedUser.tutorHours) - Number(phour)
							}
						}
                        selectedUser.totalHoursUsed = updatedHour
						selectedUser.tutorHours = Number(selectedUser.tutorHours) - Number(phour)
                        calculateTotalHours(selectedUser)
						console.log(setData)
                        dispatch(updateUserDetails(setData));
						setSessionDate("")
						setSessionFrom("")
						setSessionTo("")
                        setPsValue(0)
                        setActValue(0)
                        setMiValue(0)
                        setSecValue(0)
                        setGeneralValue(0)
						setPhour(0)
						setSelectedHour(0)
                        dispatch(fetchAppHourUsedLogs({handle: selectedUser.handle}))
                        // dispatch(sendEmailApplicationHourUsed(setData))
                        setHideError(true)
                        setNoValueError("")
						setHourErrorMsg("")
                        setComment("")
                    
                    }
                })
            }
           

        }else{  
            if(errors == 1){
            }else{
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to add hours?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes,'
                }).then((result) => {
                    if (result.isConfirmed) {
						setModal1(false)
                        const setData = {
                            by: props.userDetails.handle,
                            updatedBy: assignedcoach, //? assignedcoach : props.userDetails.handle,
                            handle: selectedUser.handle,
                            updateUsedHour: useHoursObj,
							
                           	date: moment.utc(sessionDate).format(),
                            startTime: moment.utc(sessionFrom).format(),
                            endTime: moment.utc(sessionTo).format(),
                            comment: comment,
                            mailDetails: {
                                coach: props.userDetails.name,
                                coachEmail: props.userDetails.email,
                                student: selectedUser.name,
                                studentEmail: selectedUser.email,

                            }
                        }
						if(phour != 0){
							setData.updatePremiumHour = {
								category: "premium_hour",
								prev: Number(selectedUser.tutorHours),
								value : Number(selectedUser.tutorHours) - Number(phour)
							}
						
						}
                        selectedUser.totalHoursUsed = useHoursObj
						console.log(setData)
                        calculateTotalHours(selectedUser)
                        dispatch(updateUserDetails(setData));
						setSessionDate("")
						setSessionFrom("")
						setSessionTo("")
                        setPsValue(0)
                        setActValue(0)
                        setMiValue(0)
                        setSecValue(0)
                        setGeneralValue(0)
						setPhour(0)
						setSelectedHour(0)
                        // dispatch(fetchAppHourUsedLogs({handle: selectedUser.handle}))
                        // dispatch(sendEmailApplicationHourUsed(setData))
                        setHideError(true)
                        setNoValueError("")
						setHourErrorMsg("")
                        setComment("")
        
                    }
                })
            }
        }

    }

	const calculateTotalHours = (selectedUser) =>{
         if(typeof selectedUser.totalHoursUsed !== "undefined"){
            let total = 0
            selectedUser.totalHoursUsed.map((v,i)=>(
                total+= Number(v.value)
            ))
            setTotalHour(total)
        }else{
            setTotalHour(0)
        }
    }

	const  editStudentDetails = (e,userID) =>{
		console.log(assignedcoach)
		setAssignedCoach("")
		setSessionDate()
        setSessionFrom()
        setSessionTo()
        setComment()
		setPsValue(0)
        setActValue(0)
		setMiValue(0)
      	setSecValue(0)
        setGeneralValue(0)
		setPhour(0)
		setSelectedHour(0)
        setHideError(true)
        setNoValueError("")
		setHourErrorMsg("")
        setSelectedUser([])
        dispatch(fetchUserDetails({ userId: userID }));
        setModal1(true)
    }

	const managePremiumHours = (e,userID) =>{

		setAssignedCoach("")
		setHideError(true)
		setSessionDate()
        setSessionFrom()
        setSessionTo()
        setComment()
        dispatch(fetchUserDetails({ userId: userID }));
        setModal2(true)
    }

	const [modal1, setModal1] = useState(false);
    const modalToggle1 = () => setModal1(!modal1);
    const [backdrop1, setBackdrop1] = useState(true);

	const [modal2, setModal2] = useState(false);
    const modalToggle2 = () => setModal2(!modal2);
    const [backdrop2, setBackdrop2] = useState(true);

	const hourOptions = [
        {
            label: '.5',
            value: '0.5',
        },
        {
            label: '.75',
            value: '0.75',
        },
        {
            label: '1',
            value: '1',
        },
        {
            label: '1.5',
            value: '1.5',
        },
        {
            label: '2',
            value: '2',
        },
    ];

    const handleHours = (data) =>{
        console.log(data)
        if(data.category == "personal_statement"){
            setPsValue(data.value)
        }else if(data.category == "activities"){
            setActValue(data.value)
        }
        else if(data.category == "mock_interview"){
            setMiValue(data.value)
        }else if(data.category == "secondaries"){
            setSecValue(data.value)
        }else if(data.category == "general_coaching"){
            setGeneralValue(data.value)
        }else{
			setPhour(data.value)

		}
        setNoValueError("")
    }

	const [noValueError, setNoValueError] = useState()
	const [hideError, setHideError] = useState(true)
    const [hourErrorMsg,setHourErrorMsg] = useState("")
    const [phour, setPhour] = useState(0)

    const handleHourChange = (e) => {
        const hvalue = e.target.value
        setPhour(hvalue)   
    }

    const [comment, setComment] = useState("")
    const handleCommentChange = (e) =>{
        setComment(e.target.value)
    }

    const logPremiumHour = (e,mode) => {
        setHideError(false)
    
        let caclHour = 0
        let errors = 0
		//    if(props.userDetails.admin){
		// 		if(!phour || !comment || !assignedcoach){
		// 			errors = 1
		// 		}else{
		// 			errors = 0
		// 		}
		// 	}else{
		// 		if(!assignedcoach || !phour || !comment || !sessionDate || !sessionFrom || !sessionTo ){
		// 			errors = 1
		// 		}else{
		// 			errors = 0
		// 		}
		// 	}
		if(!assignedcoach || !phour || !comment || !sessionDate || !sessionFrom || !sessionTo ){
			errors = 1
		}else{
			errors = 0
		}
		
		if(Number(phour) > Number(selectedUser.tutorHours)){
			setPhour("")
			errors = 1
			setHourErrorMsg( "Deduct hour should not be greater than the current value " )
		}
            
        caclHour =  Number(selectedUser.tutorHours) - Number(phour)
        if(errors == 1){
            
        }else{
            const logs = {
                by: props.userDetails.handle,
                coachHandle: assignedcoach,// ? assignedcoach : props.userDetails.handle,
                handle: selectedUser.handle,
                premItem: {
                    previousHour : selectedUser.tutorHours,
                    mode: mode,
                    hour: phour,
                    currentTotal: String(caclHour),
                    comment: comment,
					date: moment.utc(sessionDate).format(),
					startTime: moment.utc(sessionFrom).format(),//convertTimeToUTC(sessionFrom).format(), 
					endTime: moment.utc(sessionTo).format(),
                },
                mailDetails: {
                    coach: props.userDetails.name,
                    coachEmail: props.userDetails.email,
                    student: selectedUser.name,
                    studentEmail: selectedUser.email,
                }
            }
            dispatch(updateUserDetails(logs));
            selectedUser.tutorHours= String(caclHour)
			setSessionDate("")
			setSessionFrom("")
			setSessionTo("")
            setPhour("")
            setComment("")
            setAssignedCoach("")
            setHideError(true)
            dispatch(fetchPremiumHourLogs({handle: selectedUser.handle}))
            // dispatch(sendEmailApplicationHourUsed(logs))
        }
    }

	

	useEffect(() => {
		const result = data.filter((item) => {
			if(item){
				return (
					modifiedSearch(item.email).match(search.toLocaleLowerCase()) || 
					modifiedSearch(item.name).match(search.toLocaleLowerCase()) ||
					modifiedSearch(item.matriculated).match(search.toLocaleLowerCase()) ||
					modifiedSearch(item.mycoach).match(search.toLocaleLowerCase()) ||
					convertDate(item.createdAt).toLowerCase().match(search.toLocaleLowerCase()) 
				)
			}
		});
		
    	setFilter(result);
		
	}, [search]);

	const modifiedSearch = (item) =>  {
		if(item !== undefined){
			if(item.name !== undefined){
				return  item.name.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}

	 //Tabs
    const mainopt = ["Log Hours", "View History"];
    const [mainActiveTab, setMainActiveTab] = useState("Log Hours");

    const toggleMain = (tab) => {
        if (mainActiveTab !== tab) setMainActiveTab(tab);
    };

	

  return (
		<>
			<Header></Header>
			<Container className="mt--7" fluid>
				<Row>
					<Col>
						<Card className="shadow">
						<CardHeader className="border-0">
							<Row>
							<Col md="12">
								<button
								className="btn btn-primary float-right"
								onClick={(e) => updateMultipleUsers(e)}
								>
								Update Multiple Users
								</button>
							</Col>
							</Row>
							<Row>
							<Col md="4" className="mb-2">
								<label
									className="form-control-label"
									htmlFor="filterBySubs"
								>
									Filter By Subscription
								</label>
								<Select
								id="filterBySubs"
								placeholder="Select Subscription"
								options={subscriptionFilter}
								styles={customDropdownWithBorder}
								onChange={(e) => handleSubscriptionFilterOnChange(e)}
								value = {
									subscriptionFilter.filter(option => 
									option.label === subscription)
								}
								
								menuPortalTarget={document.body}
								/>
							</Col>
							</Row>
							<Row>
								<Col md="4">
									<label
										className="form-control-label"
										htmlFor="filterByCoach"
									>
										Filter By Coach
									</label>
									<FormGroup>
										<Select
										placeholder="Filter By Coach"
										id="filterByCoach"
										options={coachOption}
										styles={customDropdown }
										onChange={(e) => handleGetByCoach(e)}
										menuPortalTarget={document.body}
										formatOptionLabel={(v) => (
											<div className="d-flex ">
												{v.value ? (
												v.image ? (
													<img
													className="rounded-circle  mr-2 "
													style={{ width: "25px", height: "25px" }}
													src={
														"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
														v.image
													}
													alt={v.label}
													/>
												) : (
													<div
													className="rounded-circle profile-letter-all-users mr-2 "
													style={{ width: "25px", height: "25px", fontSize: '12px'}}
													>
													{v.label ? v.label.trim()[0] : ""}
													</div>
												)
												) : (
												""
												)}

												<span>{v.label}</span>
											</div>
										)}
										
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Button onClick={resetFilter}>Reset Filter</Button>
								</Col>
							</Row>
							
						</CardHeader>
							<Row>
								<Col>
									<Card className="shadow">
										<CardBody>
											
											{ 
											
												loading ? 
													<div className="m-5">
														<Alert color="secondary" className="text-center">
															Collecting data.. Please wait while loading {" "}
															<Spinner size="sm" color="primary" />{" "}
														</Alert>
													</div>
												: data.length ? 
														<DataTable
															progressPending={loading}
															paginationRowsPerPageOptions={[5,10,30,50,100]}
															customStyles={tableHeaderstyle}
															columns={columns}
															data={filter}
															pagination
															wrap
															// selectableRows
															// fixedHeader
															selectableRowsHighlight
															highlightOnHover
															subHeader
															subHeaderComponent={
															<input
																type="text"
																className="w-50 form-control"
																placeholder="Search..."
																value={search}
																onChange={(e) => setSearch(e.target.value)}
															/>
															}
															subHeaderAlign="right"
														/>
												: 
												<Alert color="primary">No data available .</Alert>
											
											
											}
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Container>

			<Modal
				className="modal-dialog-centered"
				size="lg"
				isOpen={modalUpdateUser}
				toggle={toggleUpdateUser}
			>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ModalBody className="modal-body p-0">
						<Card className="bg-secondary shadow border-0">
						<CardHeader className="bg-transparent">
							<Row className="align-items-center">
							<Col xs="8">
								<h3 className="mb-0">Edit User</h3>
							</Col>
							<Col className="text-right" xs="4"></Col>
							</Row>
						</CardHeader>
						<CardBody style={{ maxHeight: "650px", overflowY: "auto" }}>
							<h6 className="heading-small text-muted mb-4">
							User Information
							</h6>
							<div className="pl-lg-4">
							<Row>
								<Col lg="6">
								<FormGroup>
									<label className="form-control-label" htmlFor="name">
									Full Name
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="name"
									name="name"
									value={watch("name")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label className="form-control-label" htmlFor="email">
									Email Address
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="email"
									name="email"
									value={watch("email")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="tpcEntryDate"
									>
									TPC Entry Date
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="tpcEntryDate"
									name="tpcEntryDate"
									value={watch("tpcEntryDate")}
									type="date"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="mcatDate"
									>
									MCAT Date
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="mcatDate"
									name="mcatDate"
									value={watch("mcatDate")}
									type="date"
									onChange={(e) => setValue("mcatDate", e.target.value)}
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="schoolYear"
									>
									Current School Year
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="schoolYear"
									name="schoolYear"
									value={watch("schoolYear")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="university"
									>
									Undergrad University
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="university"
									name="university"
									value={watch("university")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
							</Row>
							</div>
							<hr className="my-4" />
							<h6 className="heading-small text-muted mb-4">
							Contact Information
							</h6>
							<div className="pl-lg-4">
							<Row>
								<Col lg="12">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="homeAddress"
									>
									Address
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="homeAddress"
									name="homeAddress"
									value={watch("homeAddress")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="selectedCountry"
									>
									Country
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="selectedCountry"
									name="selectedCountry"
									value={watch("selectedCountry")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="selectedState"
									>
									State
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="selectedState"
									name="selectedState"
									value={watch("selectedState")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="selectedCity"
									>
									City
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="selectedCity"
									name="selectedCity"
									value={watch("selectedCity")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="postalCode"
									>
									Postal Code
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="postalCode"
									name="postalCode"
									value={watch("postalCode")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
							</Row>
							</div>

							<hr className="my-4" />

							<h6 className="heading-small text-muted mb-4">
							Other Information
							</h6>
							<div className="pl-lg-4">
							<Row>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="inputUworld"
									>
									UWORLD Subscription
									</label>
									<select
									id="inputUworld"
									className="form-control form-control-alternative"
									value={watch("inputUworld")}
									onChange={(e) =>
										setValue("inputUworld", e.target.value)
									}
									>
									<option value="claimed">Claimed</option>
									<option value="unclaimed">Unclaimed</option>
									</select>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="inputAamc"
									>
									AAMC Subscription
									</label>
									<select
									id="inputAamc"
									className="form-control form-control-alternative"
									value={watch("inputAamc")}
									onChange={(e) =>
										setValue("inputAamc", e.target.value)
									}
									>
									<option value="claimed">Claimed</option>
									<option value="unclaimed">Unclaimed</option>
									</select>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="tutorHours"
									>
									Premium Tutoring Hours
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="tutorHours"
									name="tutorHours"
									value={watch("tutorHours")}
									type="number"
									onChange={(e) =>
										setValue("tutorHours", e.target.value)
									}
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="shadowHours"
									>
									Shadow Hours
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="shadowHours"
									name="shadowHours"
									value={watch("shadowGoal")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="clinicalHours"
									>
									Clinical Hours
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="clinicalHours"
									name="clinicalHours"
									value={watch("clinicalGoal")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="volunteerHours"
									>
									Volunteer Hours
									</label>
									<Input
									className="form-control-alternative is-invalid "
									id="volunteerHours"
									name="volunteerHours"
									value={watch("volunteerGoal")}
									type="text"
									disabled="disabled"
									/>
								</FormGroup>
								</Col>

								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="proMemberStudent"
									>
									Pro Member (External Payment)
									</label>

									<select
									id="proMemberStudent"
									className="form-control form-control-alternative"
									value={watch("proMemberStudent")}
									onChange={(e) =>
										setValue("proMemberStudent", e.target.value)
									}
									>
									<option value="true">Yes</option>
									<option value="false">No</option>
									</select>
								</FormGroup>
								</Col>
								
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="ultimateAdvisingStudent"
									>
									Ultimate Advising Student
									</label>

									<select
									id="ultimateAdvisingStudent"
									className="form-control form-control-alternative"
									value={watch("ultimateAdvisingStudent")}
									onChange={(e) =>
										setValue("ultimateAdvisingStudent", e.target.value)
									}
									>
									<option value="true">Yes</option>
									<option value="false">No</option>
									</select>
								</FormGroup>
								</Col>
								
								<Col lg="6">
								<FormGroup>
									<label className="form-control-label" htmlFor="coach">
									Coach
									</label>
									<select
									id="coach"
									className="form-control form-control-alternative"
									value={watch("coach")}
									onChange={(e) => setValue("coach", e.target.value)}
									>
									<option value="true">Yes</option>
									<option value="false">No</option>
									</select>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="assignedCoach"
									>
									Assigned Coach
									</label>
									<Select
									placeholder="Select Coach"
									options={coaches}
									styles={customDropdown }
									id="assignedCoach"
									name="assignedCoach"
									onChange={handleAssignedCoach}
									menuPortalTarget={document.body}
									value={coaches.find(
										(option) => option.value === assignedcoach
									)}
									/>
								</FormGroup>
								</Col>
								<Col lg="6">
								<FormGroup>
									<label
									className="form-control-label"
									htmlFor="matriculated"
									>
									Matriculated Medical School
									</label>
									<Select 
									id="matriculated"
										name="matriculated"
										placeholder="Select school" 
										options={universities} 
										styles={customDropdown }  
										onChange={handleUniversityChange}
										menuPortalTarget={document.body}
										value={universities.find((option) => option.value === school)}
									/>
								</FormGroup>
								</Col>
							</Row>
							</div>
						</CardBody>
						</Card>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" type="submit">
						Update
						</Button>{" "}
						<Button color="secondary" onClick={toggleUpdateUser}>
						Close
						</Button>
					</ModalFooter>
				</Form>
			</Modal>

			<Modal
				className="modal-dialog-centered"
				size="lg"
				isOpen={modalUpdateMultiUsers}
				toggle={toggleUpdateMultiUsers}
			>
				<ModalHeader toggle={toggleUpdateMultiUsers}>Update Users</ModalHeader>
				<Form onSubmit={handleSubmit(onSubmitUpdate)}>
				<ModalBody className="modal-body ">
					<Row>
					<Col>
						<FormGroup>
						<Select
							isMulti
							name="selectusers"
							options={checkUsers}
							className="basic-multi-select"
							classNamePrefix="select"
							onChange={selectUsers}
							formatOptionLabel={(v) => (
							<div className="d-flex ">
								{v.value ? (
								v.image ? (
									<img
									className="rounded-circle  mr-1 "
									style={{ width: "25px", height: "25px" }}
									src={
										"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
										v.image
									}
									alt={v.label}
									/>
								) : (
									<div
									className="rounded-circle profile-letter-all-users mr-1 "
									style={{ width: "25px", height: "25px" }}
									>
									{v.label ? v.label.trim()[0] : ""}
									</div>
								)
								) : (
								""
								)}

								<span>{v.label}</span>
							</div>
							)}
						/>
						</FormGroup>
					</Col>
					</Row>

					<Row>
					<Col>
						<h6 className="heading-small text-muted">
						Update The Information of the selected users
						</h6>
					</Col>
					</Row>

					<Row className="p-3">
					{/* <Col lg="6">
						<FormGroup>
						<label className="form-control-label" htmlFor="coach">
							Coach
						</label>
						<select
							id="coach"
							className="form-control form-control-alternative"
							onChange={(e) => setValue("coach", e.target.value)}
						>
							<option value="false">No</option>
							<option value="true">Yes</option>
						</select>
						</FormGroup>
					</Col> */}
					<Col lg="6">
						<FormGroup>
						<label className="form-control-label" htmlFor="assignedCoach">
							Assigned Coach
						</label>
						<Select
							placeholder="Select Coach"
							options={coaches}
							// styles={customDropdown }
							id="assignedCoach"
							name="assignedCoach"
							onChange={handleAssignedCoach}
							value={coaches.find(
							(option) => option.value === assignedcoach
							)}
						/>
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
						<label className="form-control-label" htmlFor="inputUworld">
							UWORLD Subscription
						</label>
						<select
							id="inputUworld"
							className="form-control form-control-alternative"
							onChange={(e) => setValue("inputUworld", e.target.value)}
						>
							<option value="unclaimed">Unclaimed</option>
							<option value="claimed">Claimed</option>
						</select>
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
						<label className="form-control-label" htmlFor="inputAamc">
							AAMC Subscription
						</label>
						<select
							id="inputAamc"
							className="form-control form-control-alternative"
							onChange={(e) => setValue("inputAamc", e.target.value)}
						>
							<option value="unclaimed">Unclaimed</option>
							<option value="claimed">Claimed</option>
						</select>
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
						<label className="form-control-label" htmlFor="tutorHours">
							Premium Tutoring Hours
						</label>
						<Input
							className="form-control-alternative is-invalid "
							id="tutorHours"
							name="tutorHours"
							value={watch("tutorHours")}
							placeholder="0"
							type="number"
							onChange={(e) => setValue("tutorHours", e.target.value)}
						/>
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
						<label
							className="form-control-label"
							htmlFor="ultimateAdvisingStudent"
						>
							Ultimate Advising Student
						</label>

						<select
							id="ultimateAdvisingStudent"
							name="ultimateAdvisingStudent"
							className="form-control form-control-alternative"
							onChange={(e) =>
							setValue("ultimateAdvisingStudent", e.target.value)
							}
							value={watch("ultimateAdvisingStudent")}

						>
							<option value="false">No</option>
							<option value="true">Yes</option>
						</select>
						</FormGroup>
					</Col>
					<Col lg="6">
						<FormGroup>
						<label
							className="form-control-label"
							htmlFor="proMemberStudent"
						>
							Pro Member (External Payment)
						</label>

						<select
							id="proMemberStudent"
							name="proMemberStudent"
							className="form-control form-control-alternative"
							onChange={(e) =>
							setValue("proMemberStudent", e.target.value)
							}
							value={watch("proMemberStudent")}
						>
							<option value="false">No</option>
							<option value="true">Yes</option>
						</select>
						</FormGroup>
					</Col>
					</Row>
					{/* <Form>
					<div
					className=""
					style={{ maxHeight: "500px", overflow: "scroll" }}
					>
					{
						checkUsers.length
						? checkUsers.map((v, i) => {
						
							return (
							<div className="d-flex align-items-center justify-content-between">
								<div className="media m-1">
								{typeof v.profilePicture != "undefined" ? (
									<img
									src={
										"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
										v.profilePicture
									}
									className="mr-3 rounded-circle"
									alt="..."
									style={{
										width: "30px",
										height: "30px",
									}}
									/>
								) : (
									<div
									className="rounded-circle profile-letter-all-users mr-3 "
									style={{ width: "30px", height: "30px" }}
									>
									{v.name ? v.name.trim()[0] : ""}
									</div>
								)}

								<div className=" d-flex align-items-center ">
									<h5 className="mt-0 mb-0">
									{v.name ? v.name : ""}{" "}
									{v.coach || v.coach == "true" ? (
										<Badge
										className="ml-2 text-dark"
										color="info"
										pill
										>
										Coach
										</Badge>
									) : (
										""
									)}
									</h5>
								</div>
								</div>
								<div>
								<FormGroup>
									<Checkbox
									obj={v}
									onChange={(item) => {
										setCheckUsers(
										checkUsers.map((d) =>
											d.handle === item.handle ? item : d
										)
										);
									}}
									/>
								</FormGroup>
								</div>
							</div>
							);
							
						})
						: ""
						}
					</div>
				</Form> */}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggleUpdateMultiUsers}>
					Cancel
					</Button>
					<Button color="success" type="submit">
					Update
					</Button>
				</ModalFooter>
				</Form>
			</Modal>
			<Modal
                isOpen={modal1}
                toggle={modalToggle1}
                backdrop={backdrop1}
                size="lg"
                centered
            >
                <ModalHeader toggle={modalToggle1}>
                    Manage Student's Log Hours
                </ModalHeader>
                 <ModalBody className="pt-1">
					 <Row>
                        <Col className="mt-3 mb-4 d-flex justify-content-center">
                            <Nav fill pills>
                                { mainopt.length > 0
                                    ? mainopt.map((c, i) => {
                                        return (
                                        <NavItem className="ml-1" key={i}>
                                            <NavLink
                                            key={c}
                                            className={classnames({
                                                active: mainActiveTab === c,
                                            })}
                                            onClick={() => {
                                                toggleMain(c);
                                            }}
                                            style={{ cursor: "pointer" }}
                                            >
                                            {c}
                                            </NavLink>
                                        </NavItem>
                                        );
                                    })
                                    :  ""
                                }
                            </Nav>
                        </Col>
                    </Row>
					<Row>
						<Col lg="12" className="mt-3 p-2">
							<TabContent activeTab={mainActiveTab}>
								{mainopt.map((mc, i) => (
									<TabPane tabId={mc} key={i}>
										{
											mc == "Log Hours" ?
												userDetailsLoading ? 

													<div className="m-5">
														<Alert color="secondary" className="text-center">
															Fetching student details.. Please wait while loading {" "}
															<Spinner size="sm" color="primary" />{" "}
														</Alert>
													</div>
												:
												<>
													<Card className="mb-2 shadow">
														<CardBody>
															
															<Row>
																<Col xl="6" className="d-flex align-items-center">
																	{
																		selectedUser ? 
																			<Media className="align-items-center">
																				{
																					typeof selectedUser.profilePicture != "undefined" ? (
																						<div className="media">
																							<img 
																								src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
																								className="mr-3" 
																								alt="..." 
																								style={{
																									width: "60px",
																									height: "60px", 
																								}}
																							/>
																							<div className="media-body">
																								<h4 className="mt-0">{selectedUser.name}</h4>
																								<small>{selectedUser.email}</small>
																							
																							</div>
																						</div>
																				
																					) : 
																					(
																						<div className="media">
																							<div className="rounded-circle profile-letter-all-users mr-2" 
																								style={{
																								width: "60px",
																								height: "60px",
																									fontSize: "16px"
																									}}>
																									{selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
																							</div>
																							<div className="media-body">
																								<h5 className="my-0">{selectedUser.name}</h5>
																								<small>{selectedUser.email}</small>
																							
																							</div>
																						</div>
																					)
																				}
																				
																			</Media>
																		: ""
																	}
																</Col>
																<Col xl="6"  className="mt-2">
																	<Card className="card-stats mb-4 mb-xl-1 w-100 shadow">
																		<CardBody>
																			<Row>
																				<div className="col">
																					<CardTitle
																						tag="h5"
																						className="text-uppercase mb-0"
																					>
																						Application Hours Used
																					</CardTitle>
																				
																				</div>
																				<Col className="col-auto">
																					<span className="h2 font-weight-bold mb-0">
																						{totalHour}
																					</span>
																					{/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
																						<i className="fas fa-atom" />
																					</div> */}
																				</Col>
																			</Row>
																		</CardBody>
																	</Card>
																</Col>
															</Row>
														
														</CardBody>
													</Card>
													
													<Row className="mt-3" >
													
														<Col lg="6" >
															<FormGroup>
																<Label >Date <span className="text-danger">*</span></Label>
																<ReactDatetime
																	onChange={(e) => handleChangeDate(e)}
																	timeFormat={false}
																	value={sessionDate || ""} 
																	className="w-100"
																	inputProps={{ placeholder: "Select Date" }}
																	
																/>
																<Input
																	type="hidden"
																	onChange={(e) => handleChangeDate(e)}
																	value={sessionDate} 
																	invalid={!hideError ? sessionDate ? "" : "true" : ""}
																/>
																<FormFeedback>This field is required!</FormFeedback>
															</FormGroup>
															
															<FormGroup >
																<Label >From <span className="text-danger">*</span></Label>
																<ReactDatetime
																	value={sessionFrom}
																	onChange={(e) => handleChangeFrom(e)}
																	timeConstraints={{
																	minutes: { min: 30, max: 30, step: 31 },
																	}}
																	dateFormat={false}
																	className="w-100"
																	inputProps={{ placeholder: "Select Start Time" }}
																	
																/>
																<Input
																	type="hidden"
																	value={sessionFrom}  
																	onChange={(e) => handleChangeFrom(e)}
																	invalid={!hideError ? sessionFrom ? "" : "true" : ""}
																/>
																
																<FormFeedback>This field is required!</FormFeedback>
															</FormGroup>
															<FormGroup >
																<Label >To <span className="text-danger">*</span></Label>
																<ReactDatetime
																	value={sessionTo}
																	onChange={(e) => handleChangeTo(e)}
																	timeConstraints={{
																	minutes: { min: 30, max: 30, step: 31 },
																	}}
																	dateFormat={false}
																	className="w-100"
																	inputProps={{ placeholder: "Select End Time" }}
																	invalid={!hideError ? sessionTo ? "" : "true" : ""}
																/>
																<Input
																	type="hidden"
																	value={sessionTo} onChange={(e) => handleChangeTo(e)}
																	invalid={!hideError ? sessionFrom ? "" : "true" : ""}
																/>
																<FormFeedback>This field is required!</FormFeedback>
															</FormGroup>
															
															
															
														</Col>
													
														<Col lg="6">
															{/* {
																props.userDetails.admin == true ?    */}
																<FormGroup>
																	<Label >Select Coach <span className="text-danger">*</span></Label>
																	<Select

																		options={coachOption}
																		classNamePrefix="select"
																		placeholder="Select a Coach.."
																		onChange={handleAssignedCoach}
																		value={coaches.find(
																			(option) => option.value === assignedcoach
																		)}
																		formatOptionLabel={(v) => (
																			<div className="d-flex ">
																				{v.value ? (
																				v.image ? (
																					<img
																					className="rounded-circle  mr-1 "
																					style={{ width: "25px", height: "25px" }}
																					src={
																						"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
																						v.image
																					}
																					alt={v.label}
																					/>
																				) : (
																					<div
																					className="rounded-circle profile-letter-all-users mr-1 "
																					style={{ width: "25px", height: "25px" }}
																					>
																					{v.label ? v.label.trim()[0] : ""}
																					</div>
																				)
																				) : (
																				""
																				)}

																				<span>{v.label}</span>
																			</div>
																		)}
																	/>
																</FormGroup>
																
																{/* : ""
															} */}
														
															<FormGroup>
																<Label >Comment <span className="text-danger">*</span></Label>
																<Input type="textarea" name="text" rows="6" placeholder="Write a comment here..."  value={comment} onChange={handleCommentChange} invalid={!hideError ? comment ? "" : "true" : ""}  />
																<FormFeedback>This field is required!</FormFeedback>
															</FormGroup>
														</Col>
													</Row>
													<Row>
													

														{
															typeof selectedUser.totalHoursUsed !== "undefined" 
															? 
															<>
															{
																useHoursObj.map(hObject => ({
																...hObject,
																...selectedUser.totalHoursUsed.find(myhours => myhours.category === hObject.category)
																})).map((v, i) => (
																	<Col  xl="6" className="mb-1" key={i}>
																		<Card className="card-stats mb-4 mb-xl-0 w-100">
																			<CardBody>
																				<Row>
																					<div className="col">
																						<CardTitle
																							tag="h5"
																							className="text-uppercase text-muted mb-0"
																						>
																							{v.category.replace('_',' ')}
																						</CardTitle>
																						<span className="h2 font-weight-bold mb-0">
																							{v.value}
																						</span>
																					</div>
																					<Col className="col-auto">
																						<Select 
																							
																							placeholder='Add..'
																							options={hourOptions} 
																							styles={customDropdown }  
																							onChange={(e) => handleHours({category: v.category, value: e.value})}
																							menuPortalTarget={document.body}
																							value={hourOptions.find((option) => option.value === selectedHour)}
																							
																						/>  
																					</Col>
																				</Row>
																			</CardBody>
																		</Card>
																	</Col>
															
																))
															}
																	<Col  xl="6" className="mb-1">
																		<Card className="card-stats mb-4 mb-xl-0 w-100">
																			<CardBody>
																				<Row>
																					<div className="col">
																						<CardTitle
																							tag="h5"
																							className="text-uppercase text-primary mb-0"
																						>
																						Premium Hours	
																						</CardTitle>
																						<span className="h2 font-weight-bold mb-0">
																							{selectedUser.tutorHours}
																						</span>
																					</div>
																					<Col className="col-auto">
																						<Select 
																							placeholder='Deduct..'
																							options={hourOptions} 
																							styles={customDropdown }  
																							onChange={(e) => handleHours({category: 'premium_hour', value: e.value})}
																							menuPortalTarget={document.body}
																							
																							value={hourOptions.find((option) => option.value === phour)}
																							
																						/>  
																					</Col>
																				</Row>
																			</CardBody>
																		</Card>
																	</Col>
																</>
															: 
															<>
																{
																	useHoursObj.map((v,i)=>(
																		<Col  xl="6" className="mb-1" key={i}>
																			<Card className="card-stats mb-4 mb-xl-0 w-100">
																				<CardBody>
																					<Row>
																						<div className="col">
																							<CardTitle
																								tag="h5"
																								className="text-uppercase text-muted mb-0"
																							>
																								{v.category.replace('_',' ')}
																							</CardTitle>
																							<span className="h2 font-weight-bold mb-0">
																								0
																							</span>
																						</div>
																						<Col className="col-auto">
																							<Select 
																								placeholder='Add..'
																								options={hourOptions} 
																								styles={customDropdown }  
																								onChange={(e) => handleHours({category: v.category, value: e.value})}
																								menuPortalTarget={document.body}
																								
																								value={hourOptions.find((option) => option.value === selectedHour)}
																								
																							/>  
																						</Col>
																					</Row>
																				</CardBody>
																			</Card>
																		</Col>
																	))
																}
																	<Col  xl="6" className="mb-1">
																		<Card className="card-stats mb-4 mb-xl-0 w-100">
																			<CardBody>
																				<Row>
																					<div className="col">
																						<CardTitle
																							tag="h5"
																							className="text-uppercase text-primary mb-0"
																						>
																						Premium Hours	
																						</CardTitle>
																						<span className="h2 font-weight-bold mb-0">
																							{selectedUser.tutorHours}
																						</span>
																					</div>
																					<Col className="col-auto">
																						<Select 
																							placeholder='Deduct..'
																							options={hourOptions} 
																							styles={customDropdown }  
																							onChange={(e) => handleHours({category: 'premium_hour', value: e.value})}
																							menuPortalTarget={document.body}
																							value={hourOptions.find((option) => option.value === phour)}
																							
																						/>  
																					</Col>
																				</Row>
																			</CardBody>
																		</Card>
																	</Col>
															</>
														}

													</Row>
													<Row>
														<Col lg="12"> { noValueError ? <Alert color="danger" className="mt-2">{noValueError}</Alert>  : ""}</Col>
														<Col lg="12"> { hourErrorMsg ? <Alert color="danger" className="mt-2">{hourErrorMsg}</Alert>  : ""}</Col>
													</Row>
													<Row>
														<Col xl="12" className="mt-2">
														
															{
																selectedUser.handle ? ""
																	// <StudentFreeHourLogs studenthandle={selectedUser.handle} />
																: ""
															}
																
														</Col>
														
													</Row>  
												</>
												
										:
										<Row>
											<Col lg="12" className="mt-2"><StudentFreeHourLogs studenthandle={selectedUser.handle} /></Col>
										</Row>
										}
										</TabPane>
									))
								}   
							</TabContent>           
						</Col>
					</Row> 
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle1} >
                    Close
                    </Button>
                    
                    <Button color="primary" onClick={saveUserSession} disabled={ !assignedcoach ? true : false } >
                    Save
                    </Button>
                </ModalFooter>
            </Modal>
			<Modal
                isOpen={modal2}
                toggle={modalToggle2}
                backdrop={backdrop2}
                size="lg"
                centered
            >
                <ModalHeader toggle={modalToggle2}>
                    Manage Student's Premium Hours
                </ModalHeader>
                <ModalBody className="pt-1">
					{  
						userDetailsLoading ? 

						<div className="m-5">
							<Alert color="secondary" className="text-center">
								Fetching student.. Please wait while loading {" "}
								<Spinner size="sm" color="primary" />{" "}
							</Alert>
						</div>
					:
					<>
						<Card className="mb-3 shadow">
							<CardBody>
								<Row>
									<Col xl="6">
										{
											selectedUser ? 
												<Media className="align-items-center">
													{
														typeof selectedUser.profilePicture != "undefined" ? (
															<div className="media">
																<img 
																	src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
																	className="mr-3" 
																	alt="..." 
																	style={{
																		width: "60px",
																		height: "60px", 
																	}}
																/>
																<div className="media-body">
																	<h5 className="mt-0">{selectedUser.name}</h5>
																	<small>{selectedUser.email}</small>
																
																</div>
															</div>
													
														) : 
														(
															<div className="media">
																<div className="rounded-circle profile-letter-all-users mr-2" 
																	style={{
																	width: "60px",
																	height: "60px",
																		fontSize: "16px"
																		}}>
																		{selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
																</div>
																<div className="media-body">
																	<h5 className="my-0">{selectedUser.name}</h5>
																	<small>{selectedUser.email}</small>
																
																</div>
															</div>
														)
													}
													
												</Media>
											: ""
										}
									</Col>
									<Col xl="6"  className="mt-2">
										<Card className="card-stats mb-4 mb-xl-0 w-100 shadow">
											<CardBody>
												<Row>
													<div className="col">
														<CardTitle
															tag="h5"
															className="text-uppercase mb-0"
														>
															Remaining Premium Hours
														</CardTitle>
													
													</div>
													<Col className="col-auto">
														<span className="h2 font-weight-bold mb-0">
															{selectedUser.tutorHours}
														</span>
													
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</CardBody>
						</Card>
						<Row>
							<Col lg="4" >
								<FormGroup>
									<Label >Date *</Label>
									<ReactDatetime
										onChange={(e) => handleChangeDate(e)}
										timeFormat={false}
										value={sessionDate} 
										className="w-100"
										inputProps={{ placeholder: "Select Date" }}
										
									/>
									<Input
										type="hidden"
										onChange={(e) => handleChangeDate(e)}
										value={sessionDate} 
										invalid={!hideError ? sessionDate ? "" : "true" : ""}
									/>
									<FormFeedback>This field is required!</FormFeedback>
								</FormGroup>
							
							</Col>
							<Col lg="4">
								<FormGroup >
									<Label >From *</Label>
									<ReactDatetime
										value={sessionFrom}
										onChange={(e) => handleChangeFrom(e)}
										timeConstraints={{
										minutes: { min: 30, max: 30, step: 31 },
										}}
										dateFormat={false}
										className="w-100"
										inputProps={{ placeholder: "Select Start Time"}}
										
									/>
									<Input
										type="hidden"
										value={sessionFrom}  
										onChange={(e) => handleChangeFrom(e)}
										invalid={!hideError ? sessionFrom ? "" : "true" : ""}
									/>
									
									<FormFeedback>This field is required!</FormFeedback>
								</FormGroup>
							</Col>
							<Col lg="4">
								<FormGroup >
									<Label >To *</Label>
										<ReactDatetime
										value={sessionTo}
										onChange={(e) => handleChangeTo(e)}
										timeConstraints={{
										minutes: { min: 30, max: 30, step: 31 },
										}}
										dateFormat={false}
										className="w-100"
										inputProps={{ placeholder: "Select End Time"}}
										invalid={!hideError ? sessionTo ? "" : "true" : ""}
									/>
									<Input
										type="hidden"
										value={sessionTo} onChange={(e) => handleChangeTo(e)}
										invalid={!hideError ? sessionFrom ? "" : "true" : ""}
									/>
									<FormFeedback>This field is required!</FormFeedback>
								</FormGroup>
							</Col> 
						</Row>
						<Row>
							<Col lg="5" className="mb-2">
								<FormGroup>
									<Label >Hours</Label>
									<Input type="number" name="premiumhours"  placeholder="Input hours.." value={phour} onChange={(e) => handleHourChange(e)} invalid={!hideError ? phour ? "" : "true" : ""}  />
									<FormFeedback>{hourErrorMsg ? hourErrorMsg : "The field is required!"}</FormFeedback>
								</FormGroup>

								{/* {
									props.userDetails.admin === true ?  */}
									<FormGroup>
										
										<Input type="hidden" invalid={!hideError ? assignedcoach ? "" : "true" : ""} />
										<Select
											
											options={coachOption}
											classNamePrefix="select"
											placeholder="Select a Coach.."
											onChange={handleAssignedCoach}
											value={coaches.find(
												(option) => option.value === assignedcoach
											)}
											formatOptionLabel={(v) => (
												<div className="d-flex ">
													{v.value ? (
													v.image ? (
														<img
														className="rounded-circle  mr-1 "
														style={{ width: "25px", height: "25px" }}
														src={
															"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
															v.image
														}
														alt={v.label}
														/>
													) : (
														<div
														className="rounded-circle profile-letter-all-users mr-1 "
														style={{ width: "25px", height: "25px" }}
														>
														{v.label ? v.label.trim()[0] : ""}
														</div>
													)
													) : (
													""
													)}

													<span>{v.label}</span>
												</div>
											)}
										/>
										<FormFeedback  >This field is required!</FormFeedback>
									</FormGroup>   
									{/* : ""
								}                   */}
							</Col>
							<Col lg="7">
								<FormGroup>
									<Label >Write a comment</Label>
									<Input type="textarea" name="text" rows="5" placeholder="Type here..."  value={comment} onChange={handleCommentChange} invalid={!hideError ? comment ? "" : "true" : ""}  />
									<FormFeedback>This field is required!</FormFeedback>
								
								</FormGroup>

							</Col>
						</Row>
						<Row>
							<Col xl="12" className="mt-2">
								{
									selectedUser.handle ? 
										<StudentPremiumHourLogs studenthandle={selectedUser.handle} />
									: ""
								}
							</Col>
						</Row>  
						</>
					}
                </ModalBody>
                <ModalFooter>
					<Button  color="secondary" onClick={modalToggle2}>
						Cancel		
					</Button>
                    {/* <Button color="danger"  onClick={(e) => logPremiumHour(e, "deduct")}  >
                    Deduct Hour
                    </Button> */}
                    
                    <Button color="primary" onClick={(e) => logPremiumHour(e, "deduct")} disabled={  !assignedcoach ? true : false } >
                    Log Hour
                    </Button>
                </ModalFooter>
            </Modal>
		</>
	);
};

const mapStateToProps = (state) => {
  return {
	userDetails : state.loginAuth.userDetails,
    fetchAllUsers: state.fetchAllUsers,
    fetchUserDetails: state.fetchUserDetails,
    updateUserDetails: state.updateUserDetails,
    updateMultiUsersReducer: state.updateMultiUsersReducer,
    fetchCoaches: state.fetchCoaches,
    fetchUniversities : state.fetchUniversities,
	fetchAppHourUsedLogs: state.fetchAppHourUsedLogs,
	fetchPremiumHourLogs: state.fetchPremiumHourLogs,
	sendEmailApplicationHourUsed: state.sendEmailApplicationHourUsed,
  };
};

export default connect(mapStateToProps)(ManageUsers);
