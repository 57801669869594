import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    CardImg,
    Media,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";
import { studentsLifetimePlan } from "util/DataList";
import { useHistory } from 'react-router-dom';
const CourseItemList = (props) => {

    const history = useHistory();

    let lessonNumber = 0;
    
    const [item,setItem ] = useState(props.item)
    
    item.category.forEach(element => {
        lessonNumber = lessonNumber +  element.lessons.length
    });
    const [lessonLength, setLessonNumber] = useState(lessonNumber)

    const handleStartCourse = (e, item) => {
        props.courseCallback(item)
    }

    const [subscribe, setSubscribe] = useState('lock');
    const [ultimateSubscribe, setUltimateSubscribe] = useState('lock');


    const fetchSubscriptionDetails = () => {
        // if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true" || studentsLifetimePlan.includes(props.userDetails.email)){
        if(props.userDetails.ultimateAdvisingStudent == true || props.userDetails.ultimateAdvisingStudent == "true" ){
            setSubscribe('unlock')
            setUltimateSubscribe('unlock')
        }
        else{   

            if(studentsLifetimePlan.includes(props.userDetails.email)){
                setSubscribe('unlock')
            }
            else{
                if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

                    const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]
    
                    if(subsDetails.status == "active" || subsDetails.status == "trialing"){
                        setSubscribe('unlock')
                    }
                    else{
    
                        const date = new Date();
                        const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
                        
                        if(subsDetails.cancel_at_period_end){
                            if(secondsSinceEpoch > subsDetails.canceled_at){
                                setSubscribe('lock')
                            }
                            else{
                                setSubscribe('unlock')
                            }
                        }
                        else{
                            setSubscribe('lock')
                        }
                    
                    }
    
                }
                else{
                    setSubscribe('lock')
                }
            }
            
        }
            
    }
    useEffect(() => {

        fetchSubscriptionDetails()
    
    }, [props.fetchDetailsSubscription])

    useEffect(() => {
        fetchSubscriptionDetails()

    },[])

return (
    <Col xl="4" lg="6" md="12" sm="12" className="d-flex flex-column flex-wrap">
   
    <Card className="shadow rounded mb-5 h-100" outline>
        <CardBody className="">
            <Row className="h-100 d-flex flex-row flex-wrap">
                <Col lg="12" md="12" sm="12" className=" d-flex flex-column flex-nowrap">
                    {item.courseThumbnail != "" ? 
                    
                        <CardImg
                            alt="..."
                            src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + item.courseThumbnail+``}
                            
                            top
                            // style={{height : "100%", objectFit: "fill", width:"100%"}}
                            // className="d-flex flex-column flex-wrap"
                        />
                        
                        :

                        <CardImg
                            alt="..."
                        
                            src={require("assets/img/brand/no-image.jpg")}
                            top
                            // style={{height : "100%", objectFit: "fill", width:"100%"}}
                            // className="d-flex flex-column flex-wrap"
                        />
                    }
                </Col>
                <Col lg="12" md="12" sm="12" className="d-flex flex-column flex-wrap">
                    <div className="mb-auto py-3 px-2">
                        <h3 >{item.courseTitle}</h3>
                        <small>
                            { 
                                item.courseDescription.length > 99 ?
                                // <>
                                //     {/* {item.courseDescription.slice(0, 85) } <span className="text-primary " role="button" onClick={e => handleStartCourse(e, item)}> ...read more </span> */}
                                // </>
                                <>{item.courseDescription.slice(0, 85)}...</>
                                : item.courseDescription
                            }
                            
                            
                        </small>
                    </div>
                    <div className="mb-1  px-2">
                        {/* <Button
                            color="default"
                            className="rounded-pill"
                            onClick={e => handleStartCourse(e, item)}
                            >
                            <i className="fas fa-play"></i>&nbsp;&nbsp;Start Lesson
                        </Button> */}
                        
                        {item.members == "free"?
                                                    
                            <Button
                                color="default"
                                
                                className='w-100  font-weight-bolder'
                                onClick={e => handleStartCourse(e, item)}
                                >
                                <i className="fas fa-play-circle"></i> &nbsp;Start Course
                            </Button>
                        :
                        item.members == "ultimate" ? 
                            ultimateSubscribe == "unlock" ?

                            <Button
                                color="default"
                                
                                className='w-100  font-weight-bolder'
                                onClick={e => handleStartCourse(e, item)}
                                >
                                <i className="fas fa-play-circle"></i> &nbsp;Start Course
                            </Button>

                            :

                            <Button
                                color="danger"
                                
                                className={ props.adminUser.admin ? "w-100  font-weight-bolder disabled" : "w-100  font-weight-bolder"}
                                onClick={(e) => {e.preventDefault(); history.push('/client/upgrade-uas')}}
                                >
                                <i className="fas fa-lock text-yellow"></i> &nbsp;Ultimate Advising Student
                            </Button>

                            :
                            subscribe == "unlock" ?
                            <Button
                                color="default"
                                
                                className='w-100  font-weight-bolder'
                                onClick={e => handleStartCourse(e, item)}
                                >
                                <i className="fas fa-play-circle"></i> &nbsp;Start Course
                            </Button>
                            :
                            <Button
                                color="info"
                                
                                className={ props.adminUser.admin ? "w-100  font-weight-bolder disabled" : "w-100  font-weight-bolder"}
                                onClick={(e) => {e.preventDefault(); history.push('/client/plans')}}
                                >
                                <i className="fas fa-lock text-yellow"></i> &nbsp;PRO
                            </Button>
                        }
                                                    
                        
                        <div className="mt-2 mb-1 px-1">
                            <small className="mr-3">{item.category !== undefined ? lessonLength : 0} Lessons</small>
                            <small className="mr-3">By: {item.instructorName} </small>
                        </div>
                        
                    </div>
                    
                </Col>
            </Row>
        </CardBody>
    </Card>
   

    </Col>
)


}

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchDetailsSubscription : state.fetchDetailsSubscription,
    adminUser : state.adminUser
  };
};

export default connect(mapStateToProps)(CourseItemList);