import React, {useState, useEffect} from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    CardImg,
    CardImgOverlay,
    TabContent, TabPane, Nav, NavItem, NavLink, 
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
    Alert,
    Spinner,
    Media,
    Table
} from "reactstrap";
import { connect, useDispatch } from 'react-redux';
import classnames from "classnames";
import DataTable from "react-data-table-component";
import {fetchAppHourUsedLogs} from "redux/Logs/LogsAction";
import EditAppLog from "components/Logs/EditAppLog";
import DeleteAppLog from "components/Logs/DeleteAppLog";
import moment from "moment-timezone";

const StudentFreeHourLogs = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
   
	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
  	const [filter, setFilter] = useState([]);
 
    useEffect(() => {
        if(props.studenthandle){
            dispatch(fetchAppHourUsedLogs({handle: props.studenthandle}))
        }
	}, [props.studenthandle]);

    useEffect(() => {
		if (props.fetchAppHourUsedLogs.loading) {
           setLoading(true)
		} else {
			setLoading(false)
			if (props.fetchAppHourUsedLogs.logs.length) {
                setData(props.fetchAppHourUsedLogs.logs)
                setFilter(props.fetchAppHourUsedLogs.logs)
              
			}else{
                setData([])
                setFilter([])
            }
		}
	}, [props.fetchAppHourUsedLogs]);

    useEffect(() => {
		const result = data.filter((item) => {
			if(item){
				return (
					modifiedSearch(item.item.category).match(search.toLocaleLowerCase()) || 
					modifiedSearch(item.coach.name).match(search.toLocaleLowerCase()) ||
                    modifiedSearch(item.item.comment).match(search.toLocaleLowerCase()) 
                   
				)
			}
		});
       
    	setFilter(result);
		
	}, [search]);

	const modifiedSearch = (item) =>  {
		if(item !== undefined){
			if(item !== undefined){
				return  item.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}

    const convertDate = (date) => {
        return moment(date).utcOffset(0, true).format('LL');
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

    const convertDateWithTime = (date, itemFrom,itemTo) => {
        console.log(date)
        let localdate = convertDate(date)
        let start = convertTimeToLocal(itemFrom).format("h:mm A")
        let end = convertTimeToLocal(itemTo).format("h:mm A")
        return localdate + ", "+start+"-"+end
    }

    const loadHistory = () =>{
        dispatch(fetchAppHourUsedLogs({handle: props.studenthandle}))
    }
    const [selectUserLogDetails, setSelectUserLogDetails] = useState()
   
    
    //Modal Behaviour 
   
    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);
    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    };
    const showLogDetails = (data) =>{
        console.log(data)
        setSelectUserLogDetails(data)
        setNestedModal(!nestedModal);
        
    }

   
    const columns = [
        {
            name: "Category",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return row.item && row.item.category ? (
                    <h5 className="text-uppercase mb-0">{row.item.category.replace("_", " ")}</h5>
                ) : (
                    <h5 className="text-uppercase mb-0">N/A</h5>
                );
            }
        },
        {
            name: "Current",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return row.item && row.item.value !== undefined ? (
                    <h4 className="text-primary ml-2">{Number(row.item.value)}</h4>
                ) : (
                    <h4 className="text-primary ml-2">N/A</h4>
                );
            }
        },
        {
            name: "Coach",
            sortable: true,
            wrap: true,
            selector: (row) => {
                return row.coach && row.coach.name ? row.coach.name : "N/A";
            }
        },
        {
            name: "Hour",
            sortable: true,
            wrap: true,
            selector: (row) => {
                if (row.item && row.item.category && row.item.value !== undefined && row.item.prev !== undefined) {
                    return row.item.category === "premium_hour" ? (
                        <h4 className="text-danger ml-2" onClick={(e) => showLogDetails(row)}>
                            -{Number(row.item.prev) - Number(row.item.value)}
                        </h4>
                    ) : (
                        <h4 className="text-success ml-2" onClick={(e) => showLogDetails(row)}>
                            +{Number(row.item.value) - Number(row.item.prev)}
                        </h4>
                    );
                }
                return <h4 className="text-secondary ml-2">N/A</h4>;
            }
        },
        {
            name: "Comment",
            selector: (row) => {
                return row.item && row.item.comment ? row.item.comment : "N/A";
            }
        },
        {
            name: "Date",
            // width: '200px',
            center: true,
            sortable: true,
            wrap: true,
            selector: (row) => {
                return row.date && row.startTime && row.endTime ? (
                    convertDateWithTime(row.date, row.startTime, row.endTime)
                ) : (
                    "N/A"
                );
            }
        },
        // {
        //     name: "Action",
        //     width: '200px',
        //     cell: (row) => (
        //         <>
        //             <EditAppLog item={row} />
        //             <DeleteAppLog />
        //         </>
        //     ),
        //     center: true,
        //     wrap: true
        // },
    ];
    
    
     const customTableStyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                // backgroundColor: "#ccc",
            },
        },
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    return (
        <>
        <Card className="shadow">
            <CardBody>
                
                <CardTitle>
                   <h4>Log History <span className="fa fa-sync-alt ml-2 text-primary" onClick={loadHistory} role="button" title="Reload History"></span></h4>  
                </CardTitle>
               						
		    { 							
                loading ? 
                    <div className="m-5">
                        <Alert color="secondary" className="text-center">
                            Collecting history.. Please wait while loading {" "}
                            <Spinner size="sm" color="primary" />{" "}
                        </Alert>
                    </div>
                : data.length ? 
                        <DataTable
                            progressPending={loading}
                            defaultSortFieldId="3"
                            defaultSortAsc="false"
                            paginationPerPage="5"
                            customStyles={customTableStyle}
                            columns={columns}
                            data={filter}
                            pagination
                            wrap
                            // selectableRows
                            // fixedHeader
                            selectableRowsHighlight
                            highlightOnHover
                            subHeader
                            subHeaderComponent={
                            <input
                                type="text"
                                className="w-50 form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            }
                            subHeaderAlign="right"
                        />
                : 
                <div className="m-5">
                    <Alert color="secondary" className="text-center">
                        No data available..
                    </Alert>
                </div>
            }

            </CardBody>
       </Card>
       <Modal
                isOpen={nestedModal}
                toggle={toggleNested}
               
                // size="xl"
                centered
            >
                <ModalHeader toggle={toggleNested}>
                {selectUserLogDetails ? "Docid: " + selectUserLogDetails.docid: ""}
                </ModalHeader>
                <ModalBody className="pt-1">
                  
                        {
                            selectUserLogDetails ? 
                            <>
                            <p><b>Category: </b > {<span className="text-uppercase mb-0">{selectUserLogDetails.item.category.replace("_"," ")}</span> }</p>
                            <p><b>Date: </b > {<span className="text-uppercase mb-0">{convertDateWithTime(selectUserLogDetails.date, selectUserLogDetails.startTime,  selectUserLogDetails.endTime)}</span> }</p>
                            <p><b>Value: </b > 
                            {  selectUserLogDetails.item.category == "premium_hour"  ? 
                           
                                <span className="text-danger"> -{Number(selectUserLogDetails.item.prev) - Number(selectUserLogDetails.item.value) }</span>  
                                    : 
                                <span className="text-primary"> +{Number(selectUserLogDetails.item.value) - Number(selectUserLogDetails.item.prev) }</span>   
                            }
                            </p>
                            <p><b>Coach: </b > <span className="text-uppercase mb-0">{selectUserLogDetails.coach.name}</span> </p>
                            <p><b>Comment: </b > {selectUserLogDetails.comment} </p>
                           
                           </>
                           : ""

                        }
                 
                 
                </ModalBody>
                
            </Modal>

       </>
       
    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchAppHourUsedLogs: state.fetchAppHourUsedLogs
    }
      
}

export default connect(mapStateToProps)(StudentFreeHourLogs)
