import React, {useEffect, useState} from 'react'
import { Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form } from 'reactstrap';
import Grade from 'components/Options/Courses/Grade';
import Term from 'components/Options/Courses/Term';
import { premedReqs } from 'util/DataList';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2'

import { createCourse, createCourseReset, editCourse, newCourse,updateCourse, updateCourseReset } from 'redux/Courses/CoursesActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
import { terms, schoolYears, grades, yearsListObject } from 'util/DataList';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Courses = (props) => {

  // const history = useHistory();
  const dispatch = useDispatch();

  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

  let toggleAddCourses = props.toggleAddCourses;
  let modalAddCourses = props.modalAddCourses;

  const messageRequired = "This field is required.";
  
  const [requirements, setRequirements] = useState(["default"]);

  const [courseId, setCourseId] = useState('');

  const [requirementsUpdate, setRequirementsUpdate] = useState(["default"]);


  useEffect(() => {
    register("inputCourseName", { required: messageRequired });
    register("inputCourseNumber", { required: messageRequired });
    register("inputTerm", { required: messageRequired });
    register("inputGrade", { required: messageRequired });
    register("inputCredits", { required: messageRequired, min:{value:0, message:"Credit must be greater than 0"}});
    register("inputSchool", { required: messageRequired });
    register("inputBCPM", { required: messageRequired });
    register("inputQuarter", { required: messageRequired });
    register("inputSection", { required: messageRequired });
    // register("inputYear", { required: messageRequired });
    // premedReqs.map((value,index)=>{
    //   register("inputRequirement." + index, { required: messageRequired })
    // });

    if(props.editCourse.status){
          
      const courseData  = findCourse(props.fetchCourses.courses,props.editCourse.courseId);

      setCourseId(props.editCourse.courseId);

     
     
      setValue("inputCourseName", courseData.courseName)
      setValue("inputCourseNumber", courseData.courseNumber)
      setValue("inputTerm", courseData.term)
      setValue("inputGrade", courseData.grade)
      setValue("inputCredits", courseData.credits)
      setValue("inputSchool", courseData.school)
      setValue("inputBCPM", (courseData.bcpm) ? "Yes" : "No")
      setValue("inputQuarter", (courseData.quarter) ? "Yes" : "No")
      setValue("inputSection", courseData.section)
      // setValue("inputYear", courseData.year)
      setValue("inputRequirements", courseData.reqs)
      setRequirementsUpdate(courseData.reqs);
      
      dispatch(editCourse({
          status : false,
          courseId : ""
      }));

    }
    if(props.newCourse.status){
      setCourseId('');

      setValue("inputCourseName", "")
      setValue("inputCourseNumber", "")
      setValue("inputTerm", "")
      setValue("inputGrade","")
      setValue("inputCredits", "")
      setValue("inputSchool","")
      setValue("inputBCPM", "")
      setValue("inputQuarter", "")
      setValue("inputSection", "")
      // setValue("inputYear", "")
      setValue("inputRequirements", [])
      setRequirementsUpdate([]);
      dispatch(newCourse({status : false}));
    }
    
  })

  if(props.createCourses.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.createCourses.course.length && typeof props.createCourses.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added new Course!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(createCourseReset());
        
      }

      else if(typeof props.createCourses.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to add new Course!',
            icon :'error',
            timer: 3000
            
          }
        )     
        dispatch(createCourseReset());       
        
      }

    }


    if(props.updateCourses.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.updateCourses.course.length && typeof props.updateCourses.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully updated Course!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(updateCourseReset());
        
        
      }

      else if(typeof props.updateCourses.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to update Course!',
            icon :'error',
            timer: 3000
          }
        )            

        dispatch(updateCourseReset());
        
      }

      

    }


  const handleRequirementsCheckbox = (e, index) => {

    
    if(e.target.checked){
      
      // setValue("inputRequirements." + index, e.target.value)

      // const inputRequirementsArr = getValues("inputRequirements").filter((requirement)=>{
      //   return typeof requirement !== "undefined" && requirement != ""; 
      // })
      let inputRequirementsArr = requirementsUpdate;
      inputRequirementsArr.push(e.target.value);
      
      setRequirementsUpdate(inputRequirementsArr);
    }
    else{
      // setValue("inputRequirements." + index, "")

      let inputRequirementsArr = requirementsUpdate.filter((requirement)=>{
        return  requirement != e.target.value; 
      })
      
      setRequirementsUpdate(inputRequirementsArr);
      
    }

    
    
    
  }


  const findCourse = (arr,courseId) => {
      
    return arr.find(x => x.courseId === courseId);
  }

  const onSubmit = (data) => {

    
    // const inputRequirementsArr = getValues("inputRequirements").filter((requirement)=>{
    //   return typeof requirement !== "undefined" && requirement != ""; 
    // })

    

    data.inputRequirements = requirementsUpdate;

    data.inputHandle = props.userDetails.handle;
    data.handle = props.userDetails.handle;
    data.userId = props.userDetails.userId;

    
    if(data.inputRequirements.length){
      if(courseId){
        data['docId'] = courseId;
        dispatch(updateCourse(data));
      }
      else{
        dispatch(createCourse(data));
      }


    }
    else{
      setRequirements(data.inputRequirements)
    }

  }

  
  // const [yearStarted, setYearStarted] = useState(props.userDetails.freshmanYear)
  
  // useEffect(() => {
  //   if(typeof yearStarted == "undefined"){
  //     if(props.updateUserProfile.userprofile.length){
  //       if(typeof props.updateUserProfile.userprofile[0].freshmanYear == "undefined"){
  //         Swal.fire({
  //           title: 'Update your profile.',
  //           text: "Please update your Freshman year in Profile.",
  //           icon: 'warning',
  //           confirmButtonColor: "#f5365c",
  //           confirmButtonText: 'Go to Profile',
  //           allowOutsideClick: false
  //         }).then((result) => {
    
  //           if (result.isConfirmed) {
  //             history.push('/client/my-profile')
  //           }
    
  //         });
  //       }
  //     }
  //     else{
  //       Swal.fire({
  //         title: 'Update your profile.',
  //         text: "Please update your Freshman year in Profile.",
  //         icon: 'warning',
  //         confirmButtonColor: "#f5365c",
  //         confirmButtonText: 'Go to Profile',
  //         allowOutsideClick: false
  //       }).then((result) => {
  
  //         if (result.isConfirmed) {
  //           history.push('/client/my-profile')
  //         }
  
  //       });
  //     }
      
  //   }
    
    

  // }, [])
  
  
  // useEffect(() => {
  //   if(props.updateUserProfile.userprofile.length){

      
  //     setYearStarted(props.updateUserProfile.userprofile[0].freshmanYear)
  //   }
  // }, [props.updateUserProfile])
  

  return (
    <div>
        
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddCourses} toggle={toggleAddCourses} >
          <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{courseId ? "Edit" : "Add" } Courses</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                
                  <h6 className="heading-small text-muted mb-4">
                    Course information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCourseName"
                          >
                            Course Name <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputCourseName"
                            name="inputCourseName"
                            value={watch("inputCourseName")}
                            onChange={e => setValue("inputCourseName", e.target.value)}
                            type="text"
                            />
                                
                          
                        { errors.inputCourseName && <small className="text-danger">{errors.inputCourseName.message}</small> }
                        </FormGroup>
                        
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCourseNumber"
                          >
                            Course Number <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative is-invalid"
                            id="inputCourseNumber"
                            name="inputCourseNumber"
                            value={watch("inputCourseNumber")}
                            onChange={e => setValue("inputCourseNumber", e.target.value)}
                            type="text"
                          />
                          { errors.inputCourseNumber && <small className="text-danger">{errors.inputCourseNumber.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTerm"
                          >
                            Term <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputTerm"
                            name="inputTerm"
                            value={watch("inputTerm")}
                            onChange={e => setValue("inputTerm", e.target.value)}
                            type="select"
                          >
                            <Term/>
                          </Input> */}

                          <Select 
                            placeholder='Select Term'
                            options={terms} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputTerm", e.value)}
                            menuPortalTarget={document.body}
                            value={terms.find((option) => option.value === watch("inputTerm"))}
                          />

                          { errors.inputTerm && <small className="text-danger">{errors.inputTerm.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputGrade"
                          >
                            Grade <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputGrade"
                            name="inputGrade"
                            value={watch("inputGrade")}
                            onChange={e => setValue("inputGrade", e.target.value)}
                            type="select"
                          >
                            <Grade/>
                          </Input> */}

                          <Select 
                            placeholder='Select Grade'
                            options={grades} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputGrade", e.value)}
                            menuPortalTarget={document.body}
                            value={grades.find((option) => option.value === watch("inputGrade"))}
                          />

                          { errors.inputGrade && <small className="text-danger">{errors.inputGrade.message}</small> }
                        </FormGroup>
                      </Col>
                    
                     
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputCredits"
                          >
                            Credits <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputCredits"
                            name="inputCredits"
                            value={watch("inputCredits")}
                            onChange={e => setValue("inputCredits", e.target.value)}
                            type="number"
                            
                          />
                          { errors.inputCredits && <small className="text-danger">{errors.inputCredits.message}</small> }
                        </FormGroup>
                      </Col>
                      
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSchool"
                          >
                            School <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputSchool"
                            type="text"
                            name="inputSchool"
                            value={watch("inputSchool")}
                            onChange={e => setValue("inputSchool", e.target.value)}
                          />
                         { errors.inputSchool && <small className="text-danger">{errors.inputSchool.message}</small> } 
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputBCPM"
                          >
                            BCPM (Science GPA) <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputBCPM"
                            name="inputBCPM"
                            type="select"
                            value={watch("inputBCPM")}
                            onChange={e => setValue("inputBCPM", e.target.value)}
                          >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Input> */}

                          <Select 
                            placeholder='Select Quarter'
                            options={[{value : "Yes", label : "Yes"}, {value:"No", label :"No"}]} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputBCPM", e.value)}
                            menuPortalTarget={document.body}
                            value={[{value : "Yes", label : "Yes"}, {value:"No", label :"No"}].find((option) => option.value === watch("inputBCPM"))}
                          />

                         { errors.inputBCPM && <small className="text-danger">{errors.inputBCPM.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputQuarter"
                          >
                            Quarter <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputQuarter"
                            type="select"
                            name="inputQuarter"
                            value={watch("inputQuarter")}
                            onChange={e => setValue("inputQuarter", e.target.value)}
                          >
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Input> */}

                          <Select 
                            placeholder='Select BCMP'
                            options={[{value : "Yes", label : "Yes"}, {value:"No", label :"No"}]} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputQuarter", e.value)}
                            menuPortalTarget={document.body}
                            value={[{value : "Yes", label : "Yes"}, {value:"No", label :"No"}].find((option) => option.value === watch("inputQuarter"))}
                          />

                          { errors.inputQuarter && <small className="text-danger">{errors.inputQuarter.message}</small> }
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSection"
                          >
                            School Year <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            id="inputSection"
                            type="select"
                            name="inputSection"
                            value={watch("inputSection")}
                            onChange={e => setValue("inputSection", e.target.value)}
                          >
                            <option value=""></option>
                            <option value="freshman">Freshman</option>
                            <option value="sophomore">Sophomore</option>
                            <option value="junior">Junior</option>
                            <option value="senior">Senior</option>
                            <option value="postbac">Postbac</option>
                            <option value="graduate">Graduate</option>
                          </Input> */}

                          <Select 
                            placeholder='Select School Year'
                            options={schoolYears} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputSection", e.value)}
                            menuPortalTarget={document.body}
                            value={schoolYears.find((option) => option.value === watch("inputSection"))}
                          />

                          { errors.inputSection && <small className="text-danger">{errors.inputSection.message}</small> }
                        </FormGroup>
                      </Col>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputYear"
                          >
                           Year <span className='text-danger'>*</span>
                          </label>
                      
                          <Select 
                            placeholder='Select Year'
                            options={yearsListObject(yearStarted)} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputYear", e.value)}
                            menuPortalTarget={document.body}
                            value={yearsListObject(yearStarted).find((option) => option.value === watch("inputYear"))}
                          />

                          { errors.inputYear && <small className="text-danger">{errors.inputYear.message}</small> }
                        </FormGroup>
                            
                      </Col> */}
                      <Col lg="12">
                          <FormGroup>
                          <label
                              className="form-control-label"
                              htmlFor="inputContactPhone"
                          >Premed Requirement Fulfilled&nbsp;
                          <span className='text-danger'>*</span>
                          </label>
                          &nbsp;{ requirements.length == 0 && <small className="text-danger">Choose atleast 1</small> }
                          </FormGroup>
                      </Col>
                      
                      {   
                          
                          premedReqs.map((value, index) => {
                              return <Col lg="6" key={index}> 
                                  <FormGroup >
                                      <div className="custom-control custom-checkbox">
                                          <Input
                                              className="custom-control-input"
                                              id={"inputRequirements." + index}
                                              type="checkbox"
                                              name={"inputRequirements."+ index}
                                              reqs = {value}
                                              value = {value}
                                              defaultChecked = {(requirementsUpdate.includes(value)) ? true : false}
                                              {...register("inputRequirements."+ index)}
                                              onChange={e => handleRequirementsCheckbox(e, index)}
                                             
                                          />
                                          <label className="custom-control-label" htmlFor={"inputRequirements." + index}>
                                              {value}
                                          </label>
                                         
                                      </div>
                                  </FormGroup>
                              </Col>
                          })
                      }
                    </Row>
                  </div>
                
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            {props.adminUser.admin ? "" :<>
            <Button color="primary" type='submit'>
            {courseId ? "Update" : "Save" } 
            </Button>{' '}
            <Button color="secondary" onClick={toggleAddCourses}>
              Cancel
            </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
      </div>
  )
}

const mapStateToProps = state => {
  return {
    createCourses : state.createCourses,
    updateCourses : state.updateCourses,
    editCourse : state.editCourse,
    fetchCourses : state.fetchCourses,
    newCourse : state.newCourse,
    userDetails : state.loginAuth.userDetails,
    adminUser : state.adminUser,
    updateUserProfile : state.updateUserProfile,
  }
}

export default connect(mapStateToProps)(Courses)