import React, {useState, useEffect} from 'react'
import Header from 'components/Headers/Header'
import McatScoresWidget from 'components/Widgets/McatScoresWidget';
import {Container, Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form, Progress, Table  } from 'reactstrap';
import McatScoresTable from 'components/Table/McatScoresTable';
import { updateMcatScoreModal,newMcatScore , updateMcatScore, createMcatScore } from 'redux/McatScores/McatScoresActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import McatScoresModal from 'components/Modal/McatScoresModal';
import {useForm} from 'react-hook-form';
import { aamcCarsScore,aamcChemScore, aamcBioChemScore, aamcTotalScore,aamcPsychSocScore} from 'util/AamcScoreConversion';
const McatScores = (props) => {

    const dispatch = useDispatch();

    const [modalMcatScoresCalculator, setModalMcatScoresCalculator] = useState(false);
    const [chemPhysScore, setChemPhysScore] = useState(0);
    const [carsScore, setCarsScore] = useState(0);
    const [bioBiochemScore, setbioBiochemScore] = useState(0);
    const [psychSocScore, setPsychSocScore] = useState(0);
    const [mcatScoreId, setMcatScoreId] = useState('');
    
    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

    const modalAddMcatScores = props.updateMcatScoreModalStatus.status;

    const messageRequired = "This field is required.";

    const toggleAddMcatScores = () =>{
      dispatch(updateMcatScoreModal(!props.updateMcatScoreModalStatus.status));
      dispatch(newMcatScore({status: true}))
    } 


    useEffect(() => {
      register("inputDate", { required: messageRequired });
      register("inputChemPhysCalc", { required: messageRequired, min:{value:1, message:"Must be greater than or equal to 1"}, max:{value:132, message:"Must be less than or equal to 59"} });
      register("inputCarsCalc", { required: messageRequired, min:{value:1, message:"Must be greater than or equal to 1"}, max:{value:132, message:"Must be less than or equal to 53"} });
      register("inputBioBiochemCalc", { required: messageRequired, min:{value:1, message:"Must be greater than or equal to 1"}, max:{value:132, message:"Must be less than or equal to 59"}});
      register("inputPsychSocCalc", { required: messageRequired, min:{value:1, message:"Must be greater than or equal to 1"}, max:{value:132, message:"Must be less than or equal to 59"} });
    }, [])
    
    
    const toggleMcatScoresCalculator = () => {
      setModalMcatScoresCalculator(!modalMcatScoresCalculator);
    };

    // useEffect(() => {
      
  
      if(modalAddMcatScores && props.createMcatScores.mcatscore.length && typeof props.createMcatScores.error === "undefined"){
        dispatch(updateMcatScoreModal(false));
      }
  
      if(modalAddMcatScores && props.updateMcatScores.mcatscore.length && typeof props.updateMcatScores.error === "undefined"){
        dispatch(updateMcatScoreModal(false));
      }
  
      if(modalAddMcatScores && typeof props.createMcatScores.error !== "undefined"){
        dispatch(updateMcatScoreModal(false));
      }
  
      if(modalAddMcatScores && typeof props.updateMcatScores.error !== "undefined"){
        dispatch(updateMcatScoreModal(false));
      }
      // }, [])


    const handleInputChemPhysCalc = (e) =>{
      setValue("inputChemPhysCalc", e.target.value)
      if(e.target.value >= 1 && e.target.value  <= 59){
        setChemPhysScore(e.target.value);
      }
      else{
        setChemPhysScore(0);
      }
      
    }
  
    const handleInputCarsCalc = (e) =>{
      setValue("inputCarsCalc", e.target.value)
      if(e.target.value >= 1 && e.target.value  <= 53){
        setCarsScore(e.target.value)
      }
      else{
        setCarsScore(0);
      }
    }
  
    const handleInputBioBiochemCalc = (e) =>{
      setValue("inputBioBiochemCalc", e.target.value)
      if(e.target.value >= 1 && e.target.value  <= 59){
        setbioBiochemScore(e.target.value)
      }
      else{
        setbioBiochemScore(0);
      }
    }
  
    const handleInputPsychSocCalc = (e) =>{
      setValue("inputPsychSocCalc", e.target.value)
  
      if(e.target.value >= 1 && e.target.value  <= 59){
        setPsychSocScore(e.target.value)
      }
      else{
        setPsychSocScore(0);
      }
      
    }

    const onSubmit = (data) => {
      

      data.inputHandle = props.userDetails.handle;
      data.handle = props.userDetails.handle;
      data.userId = props.userDetails.userId;
      data.inputTest = "MCAT Official Prep Free Practice Exam (Unscored)";
      data.inputBioBiochem = aamcBioChemScore[data.inputBioBiochemCalc][0].toString();
      data.inputCars = aamcCarsScore[data.inputCarsCalc][0].toString();
      data.inputChemPhys = aamcChemScore[data.inputChemPhysCalc][0].toString();
      data.inputPsychSoc = aamcPsychSocScore[data.inputPsychSocCalc][0].toString();
  
      if(mcatScoreId){
        data['docId'] = mcatScoreId;
        dispatch(updateMcatScore(data));

        setValue("inputChemPhysCalc", "")
        setValue("inputBioBiochemCalc", "")
        setValue("inputCarsCalc", "")
        setValue("inputPsychSocCalc", "")
        setModalMcatScoresCalculator(false)
      }
      else{
        dispatch(createMcatScore(data));

        setValue("inputChemPhysCalc", "")
        setValue("inputBioBiochemCalc", "")
        setValue("inputCarsCalc", "")
        setValue("inputPsychSocCalc", "")
        setModalMcatScoresCalculator(false)
      }
      
    }
    
    return (
    <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              <McatScoresWidget></McatScoresWidget>
            </div>
          </Container>
        </div>
        
        <Container className="mt--7 mb-5" fluid>
            <Row>
            <div className="col">
                <Card className="shadow">
                <CardHeader className="border-0">
                    <div className='float-right'>
                        <Button color="primary" onClick={() => setModalMcatScoresCalculator(true)}>MCAT Official Prep Free Practice Exam (Unscored)</Button>
                        <Button color="primary" disabled = {props.adminUser.admin ? "true" : ""} onClick={() => toggleAddMcatScores()}>Add MCAT Scores</Button>
                    </div>
                </CardHeader>
                <McatScoresTable />
                
                </Card>
            </div>
            </Row>
        </Container>
        <McatScoresModal toggleAddMcatScores={toggleAddMcatScores} modalAddMcatScores={modalAddMcatScores} />
        {/* <McatScoresModal toggleAddMcatScores={toggleAddMcatScores} modalAddMcatScores={modalAddMcatScores} /> */}

        <Modal className="modal-dialog-centered" size="lg" isOpen={modalMcatScoresCalculator} toggle={toggleMcatScoresCalculator} >
        <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">MCAT Official Prep Free Practice Exam (Unscored)</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                          className="form-control-label"
                          htmlFor="inputDate"
                        >
                          Date <span className='text-danger'>*</span>
                        </label>
                        <Input
                          className="form-control-alternative is-invalid"
                          id="inputDate"
                          name="inputDate"
                          value={watch("inputDate")}
                          onChange={e => setValue("inputDate", e.target.value)}
                          type="date"
                        />
                        { errors.inputDate && <small className="text-danger">{errors.inputDate.message}</small> }
                    </FormGroup>
                  </Col>
                  <Col lg="6"></Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="inputChemPhysCalc"
                      >
                        CHEMISTRY/PHYSICS <span className='text-danger'>*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="inputChemPhysCalc"
                        name="inputChemPhysCalc"
                        value={watch("inputChemPhysCalc")}
                        onChange={e => handleInputChemPhysCalc(e)}
                        type="number"
                        placeholder='Input # of correct questions (1 to 59)'
                      >
                      </Input>
                      { errors.inputChemPhysCalc && <small className="text-danger">{errors.inputChemPhysCalc.message}</small> }
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="inputCarsCalc"
                      >
                        CARS <span className='text-danger'>*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="inputCarsCalc"
                        name="inputCarsCalc"
                        value={watch("inputCarsCalc")}
                        onChange={e => handleInputCarsCalc(e)}
                        type="number"
                        placeholder='Input # of correct questions (1 to 53)'
                      />
                      
                      { errors.inputCarsCalc && <small className="text-danger">{errors.inputCarsCalc.message}</small> }
                    </FormGroup>
                  </Col>
                
                  
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="inputBioBiochemCalc"
                      >
                        BIOLOGY/BIOCHEM <span className='text-danger'>*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="inputBioBiochemCalc"
                        name="inputBioBiochemCalc"
                        value={watch("inputBioBiochemCalc")}
                        onChange={e => handleInputBioBiochemCalc(e)}
                        type="number"
                        placeholder='Input # of correct questions (1 to 59)'
                        
                      />
                      { errors.inputBioBiochemCalc && <small className="text-danger">{errors.inputBioBiochemCalc.message}</small> }
                    </FormGroup>
                  </Col>
                  
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="inputPsychSocCalc"
                      >
                        PSYCHOLOGY/SOCIOLOGY <span className='text-danger'>*</span>
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="inputPsychSocCalc"
                        type="text"
                        name="inputPsychSocCalc"
                        value={watch("inputPsychSocCalc")}
                        onChange={e => handleInputPsychSocCalc(e)}
                        placeholder='Input # of correct questions (1 to 59)'
                      />
                      { errors.inputPsychSocCalc && <small className="text-danger">{errors.inputPsychSocCalc.message}</small> } 
                    </FormGroup>
                  </Col>
                </Row>
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col">Number Correct</th>
                      <th scope="col">Estimated Score</th>
                      <th scope="col">Estimated Percentile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                      
                        <span className="mb-0 text-sm">
                          CHEM/PHYS
                        </span>
                        
                      </th>
                      <td>{chemPhysScore}</td>
                      <td>{aamcChemScore[chemPhysScore][0]}</td>
                    
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{aamcChemScore[chemPhysScore][1]}%</span>
                           <div>
                            <Progress
                              max="100"
                              value={aamcChemScore[chemPhysScore][1]}
                              barClassName="bg-info"
                            />
                          </div> 
                        </div>
                      </td>
                      
                    </tr>
                    <tr>
                      <th scope="row">
                      
                        <span className="mb-0 text-sm">
                          CARS
                        </span>
                          
                      </th>
                      <td>{carsScore}</td>
                      <td>{aamcCarsScore[carsScore][0]}</td>
                      
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{aamcCarsScore[carsScore][1]}%</span>
                           <div>
                            <Progress
                              max="100"
                              value={aamcCarsScore[carsScore][1]}
                              barClassName="bg-info"
                            />
                          </div> 
                        </div>
                      </td>
                      
                    </tr>
                    <tr>
                      <th scope="row">
                          <span className="mb-0 text-sm">
                            BIO/BIOCHEM
                          </span>
                          
                      </th>
                      <td>{bioBiochemScore}</td>
                      <td>{aamcBioChemScore[bioBiochemScore][0]}</td>
                      
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{aamcBioChemScore[bioBiochemScore][1]}%</span>
                           <div>
                            <Progress
                              max="100"
                              value={aamcBioChemScore[bioBiochemScore][1]}
                              barClassName="bg-info"
                            />
                          </div> 
                        </div>
                      </td>
                      
                    </tr>
                    <tr>
                      <th scope="row">
                    
                        <span className="mb-0 text-sm">
                          PSYCH/SOC
                        </span>
                      
                      </th>
                      <td>{psychSocScore}</td>
                      <td>{aamcPsychSocScore[psychSocScore][0]}</td>
                      
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{aamcPsychSocScore[psychSocScore][1]}%</span>
                           <div>
                            <Progress
                              max="100"
                              value={aamcPsychSocScore[psychSocScore][1]}
                              barClassName="bg-info"
                            />
                          </div> 
                        </div>
                      </td>
                      
                    </tr>
                    <tr>
                      <th scope="row">
                    
                        <span className="mb-0 text-sm">
                          TOTAL
                        </span>
                          
                      </th>
                      <td>{parseInt(carsScore) + parseInt(psychSocScore) + parseInt(chemPhysScore) + parseInt(bioBiochemScore)}</td>
                      <td>{parseInt(aamcCarsScore[carsScore][0]) + parseInt(aamcPsychSocScore[psychSocScore][0])+ parseInt(aamcChemScore[chemPhysScore][0]) + parseInt(aamcBioChemScore[bioBiochemScore][0])}</td>
                      
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{aamcTotalScore[parseInt(aamcCarsScore[carsScore][0]) + parseInt(aamcPsychSocScore[psychSocScore][0]) + parseInt(aamcChemScore[chemPhysScore][0]) + parseInt(aamcBioChemScore[bioBiochemScore][0])]}%</span>
                           <div>
                            <Progress
                              max="100"
                              value={aamcTotalScore[parseInt(aamcCarsScore[carsScore][0]) + parseInt(aamcPsychSocScore[psychSocScore][0]) + parseInt(aamcChemScore[chemPhysScore][0]) + parseInt(aamcBioChemScore[bioBiochemScore][0])]}
                              barClassName="bg-success"
                            />
                          </div> 
                        </div>
                      </td>
                      
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            {props.adminUser.admin ? "" :
            <>
            <Button color="primary" type='submit'>
              {mcatScoreId ? "Update" : "Save" } 
            </Button>{' '}
            <Button color="secondary" onClick={toggleMcatScoresCalculator}>
              Close
            </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
    </>
    
  )
}

const mapStateToProps = state => {
    return {
      updateMcatScoreModalStatus : state.updateMcatScoreModalStatus,
      createMcatScores : state.createMcatScores,
      updateMcatScores : state.updateMcatScores,
      editMcatScore : state.editMcatScore,
      userDetails : state.loginAuth.userDetails,
      adminUser : state.adminUser
    }
  }

export default connect(mapStateToProps)(McatScores)