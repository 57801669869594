import {
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_LOGIN_AUTHENTICATED_ERRORS,
    CLEAR_LOGIN_AUTHENTICATED_ERRORS,
    AUTHENTICATED_REQUEST,
    SET_ADMIN,
    RENEW_TOKEN_FAILURE,
    RENEW_TOKEN_REQUEST,
    RENEW_TOKEN_SUCCESS
} from "./LoginTypes";

const initialLoginState = {
    authenticated: false,
    loading:false,
    errors:null,
    admin:false,
    userDetails:[]
};

export const loginAuthReducer  = (state = initialLoginState, action) => {
    switch (action.type) {
        case AUTHENTICATED_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated : true,
                admin : action.payload.admin,
                userDetails : action.payload
            };
        case SET_UNAUTHENTICATED:
            return initialLoginState;
        case CLEAR_LOGIN_AUTHENTICATED_ERRORS:
            return {
                ...state,
                loading: false,
                errors: null
            }
        case SET_LOGIN_AUTHENTICATED_ERRORS:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        default:
            return state;
    }
}

const initialAdminUserState = {
    admin:false,
};

export const adminUserReducer  = (state = initialAdminUserState, action) => {
    switch (action.type) {
        case SET_ADMIN:
            return {
                admin : true
            }
        default:
            return state;
    }
}

const initialRenewTokenState = {
    token : [],
    loading: false,

}

export const renewTokenReducer = (state = initialRenewTokenState, action) => {
    switch (action.type) {
        case RENEW_TOKEN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case RENEW_TOKEN_SUCCESS:
            return {
                loading: false,
                token: action.payload
            }
        case RENEW_TOKEN_FAILURE:
            return {
                loading: false,
                token: [],
                error: action.payload
            }
        default: return state;
    }
}
