import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
  CardText,
  Button,
  Row,
  Col,
  Input,
  Image,
  Form,
  Badge,
} from "reactstrap";

import { ImageGrid } from "react-fb-image-video-grid";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { app, db } from "../../firebase";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import DropDownButtonReply from "components/Dropdown/DropDownButtonReply";

const Reply = (props) => {
  const dispatch = useDispatch();
  const [userArr, setUser] = useState({});
  const [imgs, setImgs] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // useEffect( () => {

  //     if (props.item) {

  //         const fetchUser = async () => {
  //             const userCollection = collection(db, "users");
  //             const getUser = query(userCollection, where("handle", "==", props.item.userHandle));
  //             const querySnapshot = await getDocs(getUser);
  //             querySnapshot.forEach((doc) => {
  //                 let user = doc.data()
  //                 setUser(user)
  //             });
  //         }
  //     }

  // }, [props.item.userHandle])

  useEffect(() => {
    fetchUser();
    return () => {
      setUser({});
    };
  }, []);

  const fetchUser = async () => {
    const userCollection = collection(db, "users");
    const getUser = query(
      userCollection,
      where("handle", "==", props.item.userHandle)
    );
    const querySnapshot = await getDocs(getUser);
    querySnapshot.forEach((doc) => {
      let user = doc.data();
      setUser(user);
    });
  };

  useEffect(() => {
    setImgs(props.item.fileContent);
  }, [props.item.fileContent]);

  useEffect(() => {
    let isLoading = true;
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Time is up!");
      }, 1000);
    }).then((value) => {
      if (isLoading) {
        setLoading(false);
      }
    });
    // Clean-up:
    return () => {
      isLoading = false;
    };
  }, []);

  return (
    <>
      {!isLoading ? (
        props.item ? (
          <div className="d-flex flex-row mb-1 ml-5 ">
            <div className="replies d-flex  pr-2 ">
              <div className="d-flex position-relative m-2">
                <div className="d-inline-flex flex-row  flex-nowrap mb-1 mr-2"></div>
                {props.postDetails.anonymous == 1 &&
                userArr.handle == props.postDetails.userHandle ? (
                  <div
                    className="rounded-circle profile-letter-all-users mr-1"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    {typeof userArr.anonymousName != "undefined"
                      ? userArr.anonymousName.trim()[0]
                      : "A"}
                  </div>
                ) : userArr && typeof userArr.profilePicture != "undefined" ? (
                  <img
                    src={
                      "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                      userArr.profilePicture
                    }
                    className="mr-1 rounded-circle"
                    alt="..."
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                ) : (
                  <div
                    className="rounded-circle profile-letter-all-users mr-1"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    {userArr && typeof userArr.name != "undefined"
                      ? userArr.name.trim()[0]
                      : ""}
                  </div>
                )}
                {/* <img  src={userArr.profilePicture ? 'https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/'+userArr.profilePicture : 'https://picsum.photos/300/200' } className="mr-1 rounded-circle" alt="..."  style={{
                                                width: '40px',
                                                height: '40px'
                                            }} /> */}

                <div
                  className="media-body px-3 py-2"
                  style={{
                    backgroundColor: "#f4f4f5",
                    borderRadius: "17px",
                  }}
                >
                  <h5 className="m-0">
                    {props.postDetails.anonymous == 1 &&
                    userArr.handle == props.postDetails.userHandle
                      ? typeof userArr.anonymousName != "undefined"
                        ? userArr.anonymousName
                        : "Anonymous"
                      : userArr.name}{" "}
                    {props.postDetails.anonymous == 1 &&
                    userArr.handle == props.postDetails.userHandle ? (
                      ""
                    ) : userArr.coach || userArr.coach == "true" ? (
                      <Badge className="ml-2 text-dark" color="info" pill>
                        Coach
                      </Badge>
                    ) : (
                      ""
                    )}
                  </h5>
                  <small className=" text-muted mb-3">
                    {moment(props.item.createdAt).fromNow()}
                  </small>
                  <p className="m-0 text-break">
                    {<span dangerouslySetInnerHTML={{__html:props.item.reply}}/>}
                    {imgs.length >= 2 ? (
                      <ImageGrid>
                        {imgs
                          .filter((arg, i) =>
                            i + 1 <= imgs.length ? true : false
                          )
                          .map((a, i) =>
                            a.file.mimeType == "video/mp4" ? (
                              <video
                                controls
                                width="200"
                                height="100"
                                style={{ backgroundColor: "#000" }}
                              >
                                <source
                                  type={a.file.mimeType}
                                  src={
                                    "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                    a.name
                                  }
                                />
                              </video>
                            ) : (
                              <img
                                src={
                                  "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                  a.name
                                }
                                alt={i}
                                width="200"
                                height="150"
                                key={Math.random(i)}
                                style={{ border: "1px solid #ddd" }}
                              />
                            )
                          )}
                      </ImageGrid>
                    ) : imgs.length == 1 ? (
                      imgs[0].file.mimeType == "video/mp4" ? (
                        <ImageGrid>
                          <video controls key={0}>
                            <source
                              type={imgs[0].file.mimeType}
                              src={
                                "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                                imgs[0].name
                              }
                            />
                          </video>
                        </ImageGrid>
                      ) : (
                        <ImageGrid>
                          <img
                            className="img-fluid"
                            alt={imgs[0]}
                            src={
                              "https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/" +
                              imgs[0].name
                            }
                            key={0}
                          />
                        </ImageGrid>
                      )
                    ) : (
                      ""
                    )}
                  </p>
                </div>
                {props.item.userHandle == props.userDetails.handle ? (
                  <DropDownButtonReply
                    item={props.item}
                    replyid={props.item.replyDocId}
                  ></DropDownButtonReply>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <>
          <div className="d-flex flex-column mb-1 ml-5">
            <div className="replies d-flex  pr-2">
              <div className="d-flex position-relative m-2">
                <div
                  className="d-inline-flex flex-row  flex-nowrap mb-1 mr-1"
                  style={{}}
                >
                  <img
                    src="https://picsum.photos/id/135/318/180?grayscale&blur=10"
                    className="mr-1 rounded-circle"
                    alt="..."
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <div
                    className="media-body px-3 py-2"
                    style={{
                      backgroundColor: "#f4f4f5",
                      borderRadius: "17px",
                    }}
                  >
                    <h5 className="m-0 placeholder-glow">
                      <span className=" text-muted  placeholder">
                        {props.userDetails.name}{" "}
                      </span>
                      <span className="ml-2 text-muted  placeholder">
                        {moment(props.item.createdAt).fromNow()}
                      </span>
                    </h5>
                    <p className="m-0  placeholder-glow ">
                      <span className="col-lg-6 placeholder"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(Reply);
