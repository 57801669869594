import React, { useEffect, useState } from 'react'
import {

    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table
  } from "reactstrap";

import Swal from 'sweetalert2'
import '../../../assets/css/school-card-react.css'

import { connect, useDispatch } from 'react-redux';
import { createSelectSchoolForSecondaryEssays, createSelectSchoolForSecondaryEssaysReset } from 'redux/SecondaryEssays/SecondaryEssaysActions';
import SchoolDOModal from 'components/Modal/SchoolDOModal';
import SchoolMDModal from 'components/Modal/SchoolMDModal';
import SchoolPostbacModal from 'components/Modal/SchoolPostbacModal';

const SchoolCard = (props) => {

  let [applicationDeadlineDay, setApplicationDeadlineDay] = useState(null);
  let [applicationDeadlineMonth, setApplicationDeadlineMonth] = useState(null);
  let [applicationDeadlineYear, setApplicationDeadlineYear] = useState(null);

  let [classesBeginDay, setClassesBeginDay] = useState(null);
  let [classesBeginMonth, setClassesBeginMonth] = useState(null);
  let [classesBeginYear, setClassesBeginYear] = useState(null);

  let [selectedSecondaryEssaysClass, setSelectedSecondaryEssaysClass ] = useState("");
  let [selectedSecondaryEssaysText, setSelectedSecondaryEssaysText ] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {

    let applicationDeadlineDate = new Date(props.medicalSchoolDetails.overview.priApplLatestDtText);

    setApplicationDeadlineDay(applicationDeadlineDate.getDate());
    setApplicationDeadlineMonth(applicationDeadlineDate.toLocaleString('default', { month: 'long' }));
    setApplicationDeadlineYear(applicationDeadlineDate.getFullYear());
    
    let classesBeginDate = new Date(props.medicalSchoolDetails.medSchoolInformation.startMonthYear);

    setClassesBeginDay(classesBeginDate.getDate());
    setClassesBeginMonth(classesBeginDate.toLocaleString('default', { month: 'long' }));
    setClassesBeginYear(classesBeginDate.getFullYear());


    // setSelectedSecondaryEssaysClass(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "btn btn-danger disabled" : props.medicalSchoolDetails.secondaryEssays != "Y" ? "btn btn-default disabled" : props.adminUser.admin ? "btn btn-primary disabled" : "btn btn-primary" );

    setSelectedSecondaryEssaysClass(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "btn btn-danger disabled" : false ? "btn btn-default disabled" : props.adminUser.admin ? "btn btn-primary disabled" : "btn btn-primary" );

    // setSelectedSecondaryEssaysText(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "ALREADY ADDED TO MY APPLICATION LIST" : props.medicalSchoolDetails.secondaryEssays == "Y" ?  "ADD TO MY APPLICATION LIST" : "NO AVAILABLE SECONDARY ESSAYS");

    setSelectedSecondaryEssaysText(props.medicalSchoolDetails.selectedSecondaryEssays == "Y"? "ALREADY ADDED TO MY APPLICATION LIST" : true ?  "ADD TO MY APPLICATION LIST" : "NO AVAILABLE SECONDARY ESSAYS");


  }, [props.medicalSchoolDetails])


  const formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const schoolType = (data) => {
      if(data.countryCd == "CAN"){
        return "CANADIAN"
      }
      else if(data.schoolType == "DO"){
        return "DO"
      }
      else{
        return "MD"
      }
  }

  const onClickAddToMyApplication = (e, data) => {
    e.preventDefault();

    data['userId'] = props.userDetails.userId;
      
    dispatch(createSelectSchoolForSecondaryEssays(data));

    
    Swal.showLoading()

    setSelectedSecondaryEssaysClass("btn btn-danger disabled")
    setSelectedSecondaryEssaysText("ALREADY ADDED TO MY APPLICATION LIST")
    
  }

  

  useEffect(() => {
    
  
      if(props.createSelectSchoolForSecondaryEssays.selectschoolforsecondaryessays.length && typeof props.createSelectSchoolForSecondaryEssays.error === "undefined"){
        // dispatch(createSelectSchoolForSecondaryEssaysReset());
    

        Swal.fire({
            title: 'Success!',
            text: 'Successfully added to my application list!',
            icon : 'success',
            timer: 3000
            
          }
        ).then((result) => {
          dispatch(createSelectSchoolForSecondaryEssaysReset());
        })
        
        
        
      }
    
      else if(typeof props.createSelectSchoolForSecondaryEssays.error !== "undefined"){
        // dispatch(createSelectSchoolForSecondaryEssaysReset()); 
        Swal.fire({
            title:'Failed!',
            text:'Failed to add to my application list!',
            icon :'error',
            timer: 3000
            
          }
        ).then((result) => {
          dispatch(createSelectSchoolForSecondaryEssaysReset());
        })    
              
        
      }
    
  }, [props.createSelectSchoolForSecondaryEssays])
  
  const [modalViewSchoolOtherDetails, setModalViewSchoolOtherDetails] = useState(false);

  const toggleViewSchoolOtherDetails = () => {
    setModalViewSchoolOtherDetails(!modalViewSchoolOtherDetails);
  };

  
  return (
    <Row className="mb-5 ">
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
            <Card className="card-profile shadow">
              
            {props.medicalSchoolDetails.postBac == "Y" ? 

              <CardHeader className='card-school-cover-img bg-default' style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/school-search-images/`+props.medicalSchoolDetails.imageUrl+`)`}}>
                <Row className='pl-3 pr-3'>
                  {/* <Col className="order-lg-1" lg="3">
                    <div className="card-profile-div shadow">
                      <div className="card-profile-image">
                        <a  onClick={(e) => e.preventDefault()}>
                          <div className="school-card-img" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/school-search-images/`+props.medicalSchoolDetails.crestUrl+`)`}}></div>
                        </a>
                      </div>
                    </div>
                  </Col> */}
                  <Col className="order-lg-2 text-center" lg="12">
                      <div className="border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <Row>
                              <Col lg="12">
                              <h1 className="text-secondary">{props.medicalSchoolDetails.overview.schoolName}</h1>
                              <p className="text-secondary">{props.medicalSchoolDetails.overview.schoolLabel}</p>
                              </Col>
                              <Col lg="12">
                              <h2 className="text-light"><i className="fa fa-map-marker-alt"></i> {props.medicalSchoolDetails.overview.state} | <span className="badge badge-pill badge-secondary" style={{fontSize:"1rem"}}>POSTBAC </span> | <a  href={props.medicalSchoolDetails.admUrl} className='text-info' target='_blank'><i className="fa fa-globe"></i> Medical School Website</a></h2>
                              </Col>
                          </Row>                        
                          
                      </div>
                  </Col>
                </Row>
              </CardHeader>
              
              : 
              
              <CardHeader className='card-school-cover-img' style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/school-search-images/`+props.medicalSchoolDetails.imageUrl+`)`}}>
                <Row className='pl-3 pr-3'>
                  <Col className="order-lg-1" lg="3">
                    <div className="card-profile-div shadow">
                      <div className="card-profile-image">
                        <a  onClick={(e) => e.preventDefault()}>
                          <div className="school-card-img" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/school-search-images/`+props.medicalSchoolDetails.crestUrl+`)`}}></div>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col className="order-lg-2" lg="9">
                      <div className="border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <Row>
                              <Col lg="12">
                              <h1 className="text-secondary">{props.medicalSchoolDetails.overview.schoolName}</h1>
                              </Col>
                              <Col lg="12">
                              <h2 className="text-light"><i className="fa fa-map-marker-alt"></i> {props.medicalSchoolDetails.overview.city}, {props.medicalSchoolDetails.overview.state} | <span className="badge badge-pill badge-secondary" style={{fontSize:"1rem"}}>{schoolType(props.medicalSchoolDetails)} </span> | <a  href={props.medicalSchoolDetails.admUrl} className='text-info' target='_blank'><i className="fa fa-globe"></i> Medical School Website</a></h2>
                              </Col>
                          </Row>                        
                          
                      </div>
                  </Col>
                </Row>
              </CardHeader>
              
              }
              <CardBody className="pt-0 pt-md-4">
              
                  <Row className="pt-3">
                      {props.medicalSchoolDetails.postBac == "Y" ? 

                      <>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Institution : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.publicPrivate == "I" ? "Private" : props.medicalSchoolDetails.overview.publicPrivate == "P" ? "Public" : "Not Provided"}</span></h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Annual tuition: <span className="font-weight-normal">{formatterUSD.format(props.medicalSchoolDetails.overview.tuitionAndFeesRes)}</span></h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">College Setting : <span className="font-weight-normal">{props.medicalSchoolDetails.publicPrivate}</span></h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Structure: <span className="font-weight-normal">{props.medicalSchoolDetails.structure ? props.medicalSchoolDetails.structure : "Not Provided"}</span></h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder"><i className="fa fa-envelope"></i> {props.medicalSchoolDetails.campusList[0].email ? <a href={"mailto:"+props.medicalSchoolDetails.campusList[0].email} className='text-primary'> {props.medicalSchoolDetails.campusList[0].email}</a> : "Not Available"}</h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder"><i className="fa fa-phone"></i> {props.medicalSchoolDetails.campusList[0].phone ? <a href={"tel:+"+props.medicalSchoolDetails.campusList[0].phone} className='text-primary'> {formatPhoneNumber(props.medicalSchoolDetails.campusList[0].phone)}</a> : "Not Available"} </h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Address: <span className="font-weight-normal">{props.medicalSchoolDetails.overview.address ? props.medicalSchoolDetails.overview.address : "Not Provided"}</span></h3></Col>
                        <Col lg="5"></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="10 text-center"> 
                          <hr className='mb-4'></hr>
                          <h3 className="font-weight-bolder">Special Focus:<br></br>
                          {props.medicalSchoolDetails.programFocus.length ? 
                          
                            props.medicalSchoolDetails.programFocus.map((value, index) => 
                            
                            <><Badge pill="true" color="warning">{value}</Badge>&nbsp;</>
                            
                            )
                          
                            :
                            "Not Provided"
                          }
                          </h3>
                        </Col>
                        <Col lg="1"></Col>
                      </> 
                      
                      : 
                      
                      <>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Application Deadline : <span className="font-weight-normal">{applicationDeadlineMonth} {applicationDeadlineDay}, {applicationDeadlineYear}</span></h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">In-state annual tuition: <span className="font-weight-normal">{formatterUSD.format(props.medicalSchoolDetails.overview.tuitionAndFeesRes)}</span></h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Mean GPA : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg && props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg != "0.00" ? props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg : "Not Available"}</span></h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Mean MCAT : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg && props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg != "0.0" ? props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg : "Not Available"}</span></h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Institution : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.publicPrivate == "I" ? "Private" : props.medicalSchoolDetails.overview.publicPrivate == "P" ? "Public" : "Not Provided"}</span></h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Campus Type : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.campusType}</span></h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Year Founded : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolInformation.yearFounded ? props.medicalSchoolDetails.medSchoolInformation.yearFounded : "Not Provided"}</span></h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder">Require CASPer : <span className="font-weight-normal">{props.medicalSchoolDetails.requireCasper == "Y"? <label className='text-warning'>{"Yes"}</label> : "Not Provided"}</span></h3></Col>
                        <Col lg="1"></Col>
                        <Col lg="1"></Col>
                        <Col lg="5"><h3 className="font-weight-bolder"><i className="fa fa-envelope"></i> {props.medicalSchoolDetails.campusList[0].email ? <a href={"mailto:"+props.medicalSchoolDetails.campusList[0].email} className='text-primary'> {props.medicalSchoolDetails.campusList[0].email}</a> : "Not Available"}</h3></Col>
                        <Col lg="5"><h3 className="font-weight-bolder"><i className="fa fa-phone"></i> {props.medicalSchoolDetails.campusList[0].phone ? <a href={"tel:+"+props.medicalSchoolDetails.campusList[0].phone} className='text-primary'> {formatPhoneNumber(props.medicalSchoolDetails.campusList[0].phone)}</a> : "Not Available"} </h3></Col>
                        <Col lg="1"></Col>
                      </>
                      
                      }
                      
                      
                  </Row>
                  <div className='text-center'>
                    <hr className="my-4" />
                    <a onClick={toggleViewSchoolOtherDetails} className="btn btn-info"> <span>View Other Details</span></a>
                    <a onClick={(e) => {onClickAddToMyApplication(e, { "schoolSearchId" : props.medicalSchoolDetails.schoolSearchId})}} className={selectedSecondaryEssaysClass}> <span>{selectedSecondaryEssaysText}</span></a>
                  </div>
              </CardBody>
            </Card>
          </Col>

          <Modal
            className="modal-dialog-centered"
            isOpen={modalViewSchoolOtherDetails}
            size="xl"
            toggle={toggleViewSchoolOtherDetails}
          >
            <ModalHeader toggle={toggleViewSchoolOtherDetails}>
              {props.medicalSchoolDetails.overview.schoolName}
            </ModalHeader>
            <ModalBody>
              
              <Row>
                
              {props.medicalSchoolDetails.postBac == "Y" ? <SchoolPostbacModal medicalSchoolDetails={props.medicalSchoolDetails}></SchoolPostbacModal> : ""}

              {props.medicalSchoolDetails.schoolType == "MD" ? <SchoolMDModal medicalSchoolDetails={props.medicalSchoolDetails}></SchoolMDModal> : ""}

              {props.medicalSchoolDetails.schoolType == "DO" ? <SchoolDOModal medicalSchoolDetails={props.medicalSchoolDetails}></SchoolDOModal> : ""}
               



                
              </Row>
              
            </ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick={toggleViewSchoolOtherDetails}>
                Done
              </Button> */}
            </ModalFooter>
          </Modal>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    userDetails : state.loginAuth.userDetails,
    createSelectSchoolForSecondaryEssays : state.createSelectSchoolForSecondaryEssays,
    adminUser : state.adminUser

  }
}

export default connect(mapStateToProps)(SchoolCard)