import React, {useState, useEffect} from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    FormGroup,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
    Alert,
    Spinner,
    Media,
    FormFeedback, 
} from "reactstrap";
import { connect, useDispatch } from 'react-redux';
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {fetchAppLog} from "redux/Logs/LogsAction";
import moment from "moment-timezone";

const EditAppLog = (props) => {
    // console.log(props.item)
    const dispatch = useDispatch()
    const [logDetails, setLogDetails]  = useState([])

    const [log, setLog] = useState([])
    useEffect(() => {
        if (props.fetchAppLog.loading) {
        } else {
            if (props.fetchAppLog.log.length) {
                console.log(props.fetchAppLog.log)
                setLog(props.fetchAppLog.log)
            }else{
                setLog([]) 
            }	
        }
	}, [props.fetchAppLog]);

    //Modal Behaviour 
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const handleEditAppLog = () =>{
        if(typeof props.item !== "undefined"){
            setLogDetails(props.item)
            dispatch(fetchAppLog({docid: props.item.docid }))
        }
        setModal(true)

    }

    const [sessionDate, setSessionDate] = useState("")
    const [sessionFrom, setSessionFrom] = useState("")
    const [sessionTo, setSessionTo] = useState("")

    const [hideError, setHideError] = useState(true)
    
    const handleChangeDate = (e) =>{
		if(typeof e === "object"){
        	setSessionDate(new Date(e.toJSON()));
		}else{
			setSessionDate();
		}
    }
    const handleChangeFrom = (e) =>{
		if(typeof e === "object"){
        	setSessionFrom(new Date(e.toJSON()));
		}else{
			setSessionFrom()
		}
    }
    const handleChangeTo = (e) =>{
		if(typeof e === "object"){
			setSessionTo(new Date(e.toJSON()));
		}else{
			setSessionTo()
		}    
    }

    const [coachOption, setCoachOption] = useState([]);
    const [assignedcoach, setAssignedCoach] = useState("");
    const handleAssignedCoach = (selectedOption) => {
        setAssignedCoach(selectedOption.value);
    };

    const [comment, setComment] = useState("")
    const handleCommentChange = (e) =>{
        setComment(e.target.value)
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

    return (
        <>
            <Button color="primary" size="sm" className="m-1" onClick={handleEditAppLog}>Edit</Button>

            <Modal
                isOpen={modal}
                toggle={modalToggle}
                backdrop={backdrop}
                // size="xl"
                centered
            >
                <ModalHeader toggle={modalToggle}>
                {"Doc: "+props.item.docid}
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Row className="mt-3" >
                    
                        <Col lg="12" >
                            <FormGroup>
                                <Label >Date *</Label>
                                <ReactDatetime
                                    onChange={(e) => handleChangeDate(e)}
                                    timeFormat={false}
                                    value={sessionDate} 
                                    className="w-100"
                                    inputProps={{ placeholder: "Select Date" }}
                                    
                                />
                                <Input
                                    type="hidden"
                                    onChange={(e) => handleChangeDate(e)}
                                    value={sessionDate} 
                                    invalid={!hideError ? sessionDate ? "" : "true" : ""}
                                />
                                <FormFeedback>This field is required!</FormFeedback>
                            </FormGroup>
                          
                            <FormGroup >
                                <Label >From*</Label>
                                <ReactDatetime
                                    value={sessionFrom}
                                    onChange={(e) => handleChangeFrom(e)}
                                    timeConstraints={{
                                    minutes: { min: 30, max: 30, step: 31 },
                                    }}
                                    dateFormat={false}
                                    className="w-100"
                                    inputProps={{ placeholder: "Select time" }}
                                    
                                />
                                <Input
                                    type="hidden"
                                    value={sessionFrom}  
                                    onChange={(e) => handleChangeFrom(e)}
                                    invalid={!hideError ? sessionFrom ? "" : "true" : ""}
                                />
                                
                                <FormFeedback>This field is required!</FormFeedback>
                            </FormGroup>
                            <FormGroup >
                                <Label >To*</Label>
                                <ReactDatetime
                                    value={sessionTo}
                                    onChange={(e) => handleChangeTo(e)}
                                    timeConstraints={{
                                    minutes: { min: 30, max: 30, step: 31 },
                                    }}
                                    dateFormat={false}
                                    className="w-100"
                                    inputProps={{ placeholder: "Select time" }}
                                    invalid={!hideError ? sessionTo ? "" : "true" : ""}
                                />
                                <Input
                                    type="hidden"
                                    value={sessionTo} onChange={(e) => handleChangeTo(e)}
                                    invalid={!hideError ? sessionFrom ? "" : "true" : ""}
                                />
                                <FormFeedback>This field is required!</FormFeedback>
                            </FormGroup>

                              {
                                props.userDetails.admin == true ?   
                                <FormGroup>
                                    <Label >Select Coach</Label>
                                    <Select

                                        options={coachOption}
                                        classNamePrefix="select"
                                        placeholder="Select a Coach.."
                                        onChange={handleAssignedCoach}
                                        formatOptionLabel={(v) => (
                                            <div className="d-flex ">
                                                {v.value ? (
                                                v.image ? (
                                                    <img
                                                    className="rounded-circle  mr-1 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    src={
                                                        "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                                                        v.image
                                                    }
                                                    alt={v.label}
                                                    />
                                                ) : (
                                                    <div
                                                    className="rounded-circle profile-letter-all-users mr-1 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    >
                                                    {v.label ? v.label.trim()[0] : ""}
                                                    </div>
                                                )
                                                ) : (
                                                ""
                                                )}

                                                <span>{v.label}</span>
                                            </div>
                                        )}
                                    />
                                </FormGroup>
                                
                                : ""
                            }
                            <FormGroup>
                                <Input type="textarea" name="text" rows="5" placeholder="Write a comment here..."  value={comment} onChange={handleCommentChange} invalid={!hideError ? comment ? "" : "true" : ""}  />
                                <FormFeedback>This field is required!</FormFeedback>
                            </FormGroup>

                        </Col>
                    </Row>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={modalToggle}>
                        Update
                    </Button>
                </ModalFooter>
            </Modal>
        
        </>
    )
}
const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchAppLog: state.fetchAppLog
    }   
}
export default connect(mapStateToProps)(EditAppLog)