import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    Row,
  } from "reactstrap";
const TwitterWidget = () => {
  return (
    <>
        <Card className="shadow">
            <CardHeader className="border-0">
                <Row className="align-items-center">
                    <div className="col">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                            TWITTER
                        </h6>
                        <h2 className="mb-0">UPDATES</h2>
                    </div>
                </Row>
            </CardHeader>
            <CardBody className='p-2'>
            <iframe src='https://widgets.sociablekit.com/twitter-list/iframe/159695' frameBorder='0' width='100%' height='550'></iframe>
            </CardBody>
        </Card>
    </>
  )
}

export default TwitterWidget