import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter,
    Badge
  } from "reactstrap";

import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';

import { createTpcCourseLessons, updateTpcCourseLessons, createTpcCourseLessonsReset, updateTpcCourseLessonsReset, fetchTpcCourses } from 'redux/ManageTpcCourses/ManageTpcCoursesActions';

const AddTpcCourseCategoryLessonsModal = (props) => {

    const dispatch = useDispatch()

    let modalAddTpcCourseCategoryLessons = props.modalAddTpcCourseCategoryLessonsModal;
    let toggleAddTpcCourseCategoryLessons = props.toggleAddTpcCourseCategoryLessonsModal;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleChange = (state) => {
        setEditorState(state);
        const lessonDetail = lessonDetails;

        
        lessonDetail.body = JSON.stringify(convertToRaw(state.getCurrentContent()));
        setLessonDetails(lessonDetail);
        
        

    };
    
    useEffect(() => {
        console.log(props)
        setSelectedLessonThumbnail(null)
        setSelectedFiles(null)
        setSelectedLessonVideo("")
        setLessonDetails({...lessonDetails, ...{category : props.categoryId}});

        if(props.editCourseCategoryLessons){

            let body = '{"blocks":[{"key":"3hhs5","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

            if(props.lessonToUpdate.body){
                body = props.lessonToUpdate.body
            }

            const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(body)));
            setEditorState(editorState)

            const lessonDetail = lessonDetails;
            lessonDetail.title = props.lessonToUpdate.title;
            lessonDetail.visibility = props.lessonToUpdate.visibility;
            lessonDetail.availability = props.lessonToUpdate.availability;
            lessonDetail.body = body;
            lessonDetail.id = props.lessonToUpdate.id;
            lessonDetail.category = props.lessonToUpdate.categoryId
            lessonDetail.oldCategory = props.lessonToUpdate.categoryId

            lessonDetail.videoType = props.lessonToUpdate.videoType
            
            lessonDetail.video = props.lessonToUpdate.video
            lessonDetail.type = props.lessonToUpdate.type
            lessonDetail.spreadSheetId = props.lessonToUpdate.spreadSheetId

            
            setLessonType(typeof props.lessonToUpdate.type != "undefined"? props.lessonToUpdate.type : "lesson")
            setSelectedLessonVideo(props.lessonToUpdate.video)

            setLessonDetails(lessonDetail);

            
        }
   

        
    }, [modalAddTpcCourseCategoryLessons])
     

    // const onClickCreateLesson = (e) => {
    //     e.preventDefault()
    //     console.log(props.selectedCategory)
    // }

    const visibilityOptions = [{value: "draft", label : "Draft"},{value: "published", label : "Published"}];
    const availabilityOptions = [{value: "unlock", label : "Unlock"},{value: "lock", label : "Lock"},{value: "deactivate", label : "Deactivate"}];
    const lessonTypeOptions = [{value: "lesson", label : "Lesson"},{value: "live schedule", label : "Live Schedule"}];

    // const [categoryLessonsLists , setCategoryLessonsLists] = useState([])

    const handleCreateCategoryLessons = (e) => {

        e.preventDefault()

        // Swal.showLoading()
        
        Swal.showLoading()

        // if(lessonDetails.title == "" || lessonDetails.visibility == "" || lessonDetails.availability == "")
        if(lessonDetails.title == "" || lessonDetails.visibility == "")
        {
            Swal.fire({
                title: 'Failed!',
                text: 'Please fill all the required field!',
                icon : 'error',
                timer: 3000
                
              }
            ).then((result) => {
             
               
            })
        }
        else{
            const formData = new FormData();
            formData.append('title', lessonDetails.title);
            formData.append('category', lessonDetails.category);
            formData.append('thumbnail', lessonDetails.thumbnail);
            formData.append('body', lessonDetails.body);
            formData.append('visibility', lessonDetails.visibility);
            formData.append('availability', lessonDetails.availability);
            formData.append('video', lessonDetails.video);
            formData.append('videoType', lessonDetails.videoType);
            if (selectedFiles) {
                for (let i = 0; i < selectedFiles.length; i++) {
                  formData.append('files', selectedFiles[i]);
                }
            }
            formData.append('tpcCourseId',props.courseData.tpcCourseId)
            formData.append('handle', props.userDetails.handle);
            formData.append('type', lessonDetails.type);
            formData.append('spreadSheetId', lessonDetails.spreadSheetId)
            dispatch(createTpcCourseLessons(formData))
        }
        
        
    }

    const [selectCategoryLists, setSelectCategoryLists] = useState([])

    useEffect(() => {

        const categoryArr = [];
      
        props.items.map((item) => {
            categoryArr.push ({
                value : item.id,
                label : item.text
            })
        })

        setSelectCategoryLists(categoryArr)
        
        
    }, [modalAddTpcCourseCategoryLessons])

    useEffect(() => {

        // if(typeof props.selectedCategory.lessons != "undefined") {
            
        //     setCategoryLessonsLists(props.selectedCategory.lessons)
        // }

        const categoryArr = [];

        props.items.map((item) => {
            categoryArr.push ({
                value : item.id,
                label : item.text
            })
        })

        setSelectCategoryLists(categoryArr)

        
    }, [])

    const [lessonDetails, setLessonDetails] = useState({ 
        id : "",
        title : "",
        thumbnail : "",
        video: "",
        visibility : "",
        availability: "",
        body : '{"blocks":[{"key":"3hhs5","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
        category: "",
        oldCategory : "",
        videoType: "vimeo",
        spreadSheetId: "",
        type: "lesson"
        
    });

    const [selectedLessonThumbnail, setSelectedLessonThumbnail] = useState(null)
    const [selectedLessonVideo, setSelectedLessonVideo] = useState("")
    
    const handleImageChange = (event) => {
        const lessonDetail = lessonDetails;

        let imageDetails = ""

        lessonDetail.thumbnail = event.target.files[0];
        imageDetails = lessonDetail.thumbnail
    
        const fileSizeInMB = imageDetails / (1024 * 1024);
        const maxSizeInMB = 5;
        if (fileSizeInMB > maxSizeInMB) {
            Swal.fire({
                title: 'Failed!',
                text: 'File size exceeds the maximum limit of 5MB.',
                icon: 'error',
                timer: 3000
            });
            return;
        }
        
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedTypes.includes(imageDetails.type)) {
            Swal.fire({
                title: 'Failed!',
                text: 'Only image files (JPEG, PNG, JPG) are allowed.',
                icon: 'error',
                timer: 3000
            });
            return;
        }

        if (imageDetails) {
            const reader = new FileReader();

            reader.onload = function (e) {

                setSelectedLessonThumbnail(e.target.result);
                
            };

            reader.readAsDataURL(imageDetails);
        } else {

            setSelectedLessonThumbnail(null);
            
        }
        
        
        setLessonDetails(lessonDetail);
    };

    const handleVideoChange = (event) => {
        const lessonDetail = lessonDetails;

        let videoDetails = ""

        lessonDetail.video = event.target.files[0];
        lessonDetail.videoType = "upload"
        videoDetails = lessonDetail.video
    
        // const fileSizeInMB = videoDetails / (1024 * 1024);
        // const maxSizeInMB = 5;
        // if (fileSizeInMB > maxSizeInMB) {
        //     Swal.fire({
        //         title: 'Failed!',
        //         text: 'File size exceeds the maximum limit of 5MB.',
        //         icon: 'error',
        //         timer: 3000
        //     });
        //     return;
        // }
        
        const allowedTypes = ['video/mp4', 'video/webm', 'video/ogg'];
        if (!allowedTypes.includes(videoDetails.type)) {
            Swal.fire({
                title: 'Failed!',
                text: 'Only video files (MP4, WEBM, OGG) are allowed.',
                icon: 'error',
                timer: 3000
            });
            return;
        }

        if (videoDetails) {
            const reader = new FileReader();

            reader.onload = function (e) {

                setSelectedLessonVideo(e.target.result);
                
            };

            reader.readAsDataURL(videoDetails);
        } else {

            setSelectedLessonVideo("");
            
        }
        
        setLessonDetails(lessonDetail);
    };

    const handleLessonVimeoChange = (e) => {
        const lessonDetail = lessonDetails;
        lessonDetail.video = e.target.value;
        lessonDetail.videoType = 'vimeo';
        console.log(lessonDetail)
        setLessonDetails(lessonDetail);
        setSelectedLessonVideo(e.target.value)
    }

    const handleLessonSpreadSheetIdChange = (e) => {
        const lessonDetail = lessonDetails;
        lessonDetail.spreadSheetId = e.target.value;
        setLessonDetails(lessonDetail);
        setSelectedLessonVideo(e.target.value)
    }

    const handleLessonTitleChange = (event) => {
        const lessonDetail = lessonDetails;
        lessonDetail.title = event.target.value;
        setLessonDetails(lessonDetail);
    };

    const handleVisibilityChange = (event) => {
        const lessonDetail = lessonDetails;
        lessonDetail.visibility = event.value;
        setLessonDetails(lessonDetail);
    };

    const handleAvailabilityChange = (event) => {
        const lessonDetail = lessonDetails;
        lessonDetail.availability = event.value;
        setLessonDetails(lessonDetail);
    };

    const [selectedFiles, setSelectedFiles] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleLessonCategoryChange = (event) => {
        const lessonDetail = lessonDetails;
        lessonDetail.category = event.value;
        setLessonDetails(lessonDetail);
    };


    const [lessonType, setLessonType] = useState("lesson")

    const handleLessonTypeChange = (event) => {
        const lessonDetail = lessonDetails;
        lessonDetail.type = event.value;
        setLessonType(event.value);
        setLessonDetails(lessonDetail);
    };

    const [videoType, setVideoType] = useState("vimeo")

    const handleLessonVideoTypeChange = (event) => {
        const lessonDetail = lessonDetails;
        lessonDetail.videoType = event.value;
        setVideoType(event.value)
        
        setSelectedLessonVideo("")

        if(props.lessonToUpdate.videoType == lessonDetail.videoType){
            lessonDetail.video = props.lessonToUpdate.video
            setSelectedLessonVideo(props.lessonToUpdate.video)
        }

        setLessonDetails(lessonDetail);
        
    };

    

    const handleUpdateCategoryLessons = (e) => {
        e.preventDefault()
        Swal.showLoading()

        console.log(lessonDetails)

        if(lessonDetails.title == "" || lessonDetails.visibility == "" || lessonDetails.availability == "" )
        {
            Swal.fire({
                title: 'Failed!',
                text: 'Please fill all the required field!',
                icon : 'error',
                timer: 3000
                
              }
            ).then((result) => {
             
               
            })
        }
        else{
            const formData = new FormData();
            formData.append('id', lessonDetails.id);
            formData.append('title', lessonDetails.title);
            formData.append('category', lessonDetails.category);
            formData.append('thumbnail', lessonDetails.thumbnail);
            formData.append('body', lessonDetails.body);
            formData.append('visibility', lessonDetails.visibility);
             formData.append('availability', lessonDetails.availability);
            formData.append('video', lessonDetails.video);
            formData.append('videoType', lessonDetails.videoType);
            formData.append('oldCategory', lessonDetails.oldCategory);
            if (selectedFiles) {
                for (let i = 0; i < selectedFiles.length; i++) {
                  formData.append('files', selectedFiles[i]);
                }
            }
            formData.append('tpcCourseId',props.courseData.tpcCourseId)
            formData.append('handle', props.userDetails.handle);
            formData.append('type', lessonDetails.type);
            formData.append('spreadSheetId', lessonDetails.spreadSheetId)
            dispatch(updateTpcCourseLessons(formData))
        }
        
        
    }


    useEffect(() => {
        if(props.fetchTpcCourses.tpccourses.length){
            
            if(typeof props.courseData.category != "undefined"){
                props.handleCourseResetCallBack(props.fetchTpcCourses.tpccourses.find(course => course.tpcCourseId == props.courseData.tpcCourseId).category)
                
            }
           
            
        }
    },[props.fetchTpcCourses])
    
    const reloadCourse = () =>{
        props.handleCourseResetCallBack()
    }

    useEffect(() => {
    
  
        if(props.createTpcCourseLessons.tpccourse.length && typeof props.createTpcCourseLessons.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully added TPC course lesson!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            setLessonDetails({ 
                id : "",
                title : "",
                thumbnail : "",
                visibility : "",
                availabilty : "",
                body : '{"blocks":[{"key":"3hhs5","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                category: "",
                oldCategory : "",
                video: "",
                videoType: "vimeo",
                type : "lesson"
            })
            setEditorState(EditorState.createEmpty())
            dispatch(createTpcCourseLessonsReset());
            toggleAddTpcCourseCategoryLessons()
            dispatch(fetchTpcCourses({}))
          })
          
        }
      
        else if(typeof props.createTpcCourseLessons.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to add TPC course lesson!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            setLessonDetails({ 
                id : "",
                title : "",
                thumbnail : "",
                visibility : "",
                availabilty : "",
                body : '{"blocks":[{"key":"3hhs5","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                category: "",
                oldCategory : "",
                video: "",
                videoType: "vimeo",
                type : "lesson",
            })
            setEditorState(EditorState.createEmpty())
           
            dispatch(createTpcCourseLessonsReset());
            toggleAddTpcCourseCategoryLessons()
             dispatch(fetchTpcCourses({}))
          })    
                
        }
      
    }, [props.createTpcCourseLessons])

    useEffect(() => {
    
  
        if(props.updateTpcCourseLessons.tpccourse.length && typeof props.updateTpcCourseLessons.error === "undefined"){
          
          Swal.fire({
              title: 'Success!',
              text: 'Successfully updated TPC course lesson!',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            setLessonDetails({ 
                id : "",
                title : "",
                thumbnail : "",
                visibility : "",
                availabilty : "",
                body : '{"blocks":[{"key":"3hhs5","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                category: "",
                oldCategory : "",
                video: "",
                videoType: "vimeo",
                type: "lesson"
            })
            setEditorState(EditorState.createEmpty())
            dispatch(fetchTpcCourses({}))
            dispatch(updateTpcCourseLessonsReset());
            toggleAddTpcCourseCategoryLessons()
          })
          
        }
      
        else if(typeof props.updateTpcCourseLessons.error !== "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to update TPC course lesson!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            setLessonDetails({ 
                id : "",
                title : "",
                thumbnail : "",
                visibility : "",
                availabilty : "",
                body : '{"blocks":[{"key":"3hhs5","text":" ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                category: "",
                oldCategory : "",
                video: "",
                videoType: "vimeo",
                type: "lesson"
            })
            setEditorState(EditorState.createEmpty())
            dispatch(fetchTpcCourses({}))
            dispatch(updateTpcCourseLessonsReset());
            toggleAddTpcCourseCategoryLessons()
          })    
                
        }
      
    }, [props.updateTpcCourseLessons])




    return (
        <div>
            <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddTpcCourseCategoryLessons}  toggle={toggleAddTpcCourseCategoryLessons}>
                    
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">{props.editCourseCategoryLessons ? "Edit" : "Add"} Lesson</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            <h6 className="heading-small text-muted mb-4">
                                Lesson Information
                            </h6>
                            <div className="pl-lg-4">
                                <Row>

                                <Col lg="12">
                                        <FormGroup>
                                            
                                        <label
                                                className="form-control-label"
                                                
                                            >
                                                Type
                                            </label>
                                            <Select 
                                                placeholder='Select Type'
                                                options={lessonTypeOptions} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleLessonTypeChange(e)}
                                                menuPortalTarget={document.body}
                                                

                                                defaultValue={lessonTypeOptions.find((option) => option.value == lessonType)}
                                                
                                            />
                                            
                                        </FormGroup>
                                    </Col>

                                    

                                    <Col lg="12" >
                                        {lessonType == "lesson" ?
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Video
                                            </label>

                                            { selectedLessonVideo != null && selectedLessonVideo != "" ? 
                                                <>
                                                    {selectedLessonVideo.includes("vimeo")?
                                                        <div className='text-center embed-responsive embed-responsive-16by9 shadow img-thumbnail'>
                                                            
                                                            <iframe src={"//player.vimeo.com/video/"+selectedLessonVideo.split('/').slice(-2, -1).pop()+"?h="+selectedLessonVideo.split('/').pop().split('?').shift()+"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;"}   width="100%" height="360" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen className="embed-responsive-item" picture-in-picture></iframe>

                                                        </div>
                                                        : 
                                                        // <div className='text-center'>
                                                        //     <video
                                                        //         className='shadow img-thumbnail'
                                                        //         alt="Selected Video"
                                                        //         controls
                                                        //         style={{ maxWidth: '100%'}}>
                                                        //             <source src={selectedLessonVideo} type="video/mp4"/>
                                                        //     </video> 
                                                        // </div>
                                                        ""
                                                    }
                                                    
                                                    <br></br>
                                                    
                                                    </> 
                                                // : props.editCourseCategoryLessons != false && !props.lessonToUpdate.video.includes("vimeo") ? 
                                                // <>
                                                //     <div className='text-center'>
                                                //         <video
                                                //             className='shadow img-thumbnail'
                                                //             src={"https://storage.googleapis.com/tpcdashboard.appspot.com/video-tpc-courses-lessons/"+props.lessonToUpdate.video}
                                                //             alt="Selected Video"
                                                //             controls
                                                //             style={{ maxWidth: '100%'}}
                                                //         />
                                                //     </div>
                                                //     <br></br>
                                                //     </> 
                                                : ""
                                            }
                                            {/* <Select 
                                                placeholder='Select Video Type'
                                                options={[{label : "VIMEO", value : "vimeo"},{label : "UPLOAD VIDEO", value : "upload"}]} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleLessonVideoTypeChange(e)}
                                                menuPortalTarget={document.body}
                                                
                                                className='mb-3'
                                                defaultValue={[{label : "VIMEO", value : "vimeo"},{label : "UPLOAD VIDEO", value : "upload"}].find((option) => option.value == lessonDetails.videoType)}
                                                
                                            /> */}
                                            {/* {lessonDetails.videoType == "vimeo" ?  */}
                                            
                                                <Input
                                                    className="form-control-alternative"
                                                    defaultValue={props.editCourseCategoryLessons ?  lessonDetails.video  : ""}
                                                    placeholder='Paste Vimeo link ex. https://vimeo.com/xxxxxxx/xxxxxxx'
                                                    onChange={e => handleLessonVimeoChange(e)}
                                                    type="text"
                                                /> 
                                                
                                                {selectedLessonVideo ? !selectedLessonVideo.includes("vimeo") ? <small className="text-danger">Invalid vimeo video link.</small> : "" : ""}
                                                {/* :  */}
                                                {/* <div className="custom-file text-center ">
                                                    
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="video/*"
                                                        onChange={(event) => handleVideoChange(event)}
                                                    />
                                                    <label className="custom-file-label" >{item.video ? <>Selected video file: <span className='text-primary'>{item.video.name}</span></> : "Select video file (max 10mb)"}</label>
                                                    <label className="custom-file-label" >Select video file</label>
                                                </div>
                                            } */}
                                        </FormGroup>

                                        :
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                            >
                                                Google Spreadsheet ID
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                defaultValue={props.editCourseCategoryLessons ?  lessonDetails.spreadSheetId  : ""}
                                                placeholder='Paste Spreadsheet ID ex. 1GtDUYDU9quH8GyayXXXXXXXXXXXXXX'
                                                onChange={e => handleLessonSpreadSheetIdChange(e)}
                                                type="text"
                                            /> 
                                        </FormGroup>}


                                    </Col>

                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Title <span className='text-danger'>*</span>
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                defaultValue={props.editCourseCategoryLessons ? lessonDetails.title : ""}
                                                
                                                onChange={e => handleLessonTitleChange(e)}
                                                type="text"
                                            />
                                            
                                        </FormGroup>
                                    </Col>

                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Category
                                            </label>
                                            <Select 
                                                placeholder='Select Category'
                                                options={selectCategoryLists} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleLessonCategoryChange(e)}
                                                menuPortalTarget={document.body}
                                                

                                                defaultValue={selectCategoryLists.find((option) => option.value == props.categoryId)}
                                                
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Thumbnail
                                            </label>

                                            { selectedLessonThumbnail != null && selectedLessonThumbnail != "" ? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail'
                                                            src={selectedLessonThumbnail}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%'}}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : props.editCourseCategoryLessons != false && props.lessonToUpdate.thumbnail ? 
                                                <>
                                                    <div className='text-center'>
                                                        <img
                                                            className='shadow img-thumbnail'
                                                            src={"https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses-lessons/"+props.lessonToUpdate.thumbnail}
                                                            alt="Selected Image"
                                                            style={{ maxWidth: '100%'}}
                                                        />
                                                    </div>
                                                    <br></br>
                                                    </> 
                                                : ""
                                            }

                                            <div className="custom-file text-center ">
                                                
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    accept="image/*"
                                                    onChange={(event) => handleImageChange(event)}
                                                />
                                                {/* <label className="custom-file-label" >{item.video ? <>Selected video file: <span className='text-primary'>{item.video.name}</span></> : "Select video file (max 10mb)"}</label> */}
                                                <label className="custom-file-label" >Select image file (max 5mb)</label>
                                            </div>
                                            
                                        </FormGroup>
                                    </Col>
                                    
                                    {lessonType == "lesson" ?   
                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Body
                                            </label>
                                            <Card className="bg-secondary shadow border-0">
                                                <CardHeader >
                                                        <Editor
                                                            className="form-control-alternative"
                                                            editorState={editorState}
                                                            onEditorStateChange={handleChange}
                                                            toolbar={{
                                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                            
                                                        }}
                                                    />
                                                </CardHeader>
                                            </Card>
                                            
                                        </FormGroup>
                                    </Col>
                                    :""}
                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Visibility <span className='text-danger'>*</span>
                                            </label>
                                            <Select 
                                                placeholder='Select Visibility'
                                                options={visibilityOptions} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleVisibilityChange(e)}
                                                menuPortalTarget={document.body}
                                                // value={visibilityOptions.find((option) => option.value === watch("inputTerm"))}
                                                defaultValue={props.editCourseCategoryLessons ? visibilityOptions.find((option) => option.value === lessonDetails.visibility) : ""}
                                            />
                                            
                                        </FormGroup>
                                    </Col>

                                     {/* <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Availability <span className='text-danger'>*</span>
                                            </label>
                                            <Select 
                                                placeholder='Select Availability'
                                                options={availabilityOptions} 
                                                styles={customDropdown }  
                                                onChange={(e) => handleAvailabilityChange(e)}
                                                menuPortalTarget={document.body}
                                                // value={visibilityOptions.find((option) => option.value === watch("inputTerm"))}
                                                defaultValue={props.editCourseCategoryLessons ? availabilityOptions.find((option) => option.value === lessonDetails.availability) : ""}
                                            />
                                            
                                        </FormGroup>
                                    </Col> */}

                                    {lessonType == "lesson" ? 

                                    <Col lg="12">
                                        <FormGroup>
    
                                            <label
                                                className="form-control-label"
                                                
                                            >
                                                Files
                                                <ul>
                                                    {selectedFiles ? Array.from(selectedFiles).map((file, index) => (
                                                        <li key={index}>{file.name}</li>
                                                    )) : props.editCourseCategoryLessons ? <>
                                                    {props.lessonToUpdate.files.map((value) => { return <li key={value.rawFileName}>
                                                        <a href={"https://storage.googleapis.com/tpcdashboard.appspot.com/files-tpc-courses-lessons/" + value.filenameWithUuid} download={value.rawFileName}>{value.rawFileName}</a></li>
                                                    })}

                                                </> : ""}
                                                    
                                                </ul>
                                            </label>
                                           
                                            
                                            <div className="custom-file text-center ">
                                                
                                                <input
                                                    type="file"
                                                    multiple
                                                    className="custom-file-input"
                                                    
                                                    onChange={(event) => handleFileChange(event)}
                                                />
                                                {/* <label className="custom-file-label" >{item.video ? <>Selected video file: <span className='text-primary'>{item.video.name}</span></> : "Select video file (max 10mb)"}</label> */}
                                                <label className="custom-file-label" >Select files</label>
                                            </div>
                                            
                                        </FormGroup>
                                    </Col>
                                    :""}
                                </Row>
                            </div>
    
                        
                            
                            
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"  onClick={toggleAddTpcCourseCategoryLessons}>
                        Cancel
                    </Button>
    
                    {props.editCourseCategoryLessons === false ? 
                        <Button color="primary" onClick={e => handleCreateCategoryLessons(e)}>
                            Save
                        </Button>
                        :
                        <Button color="primary" onClick={e => handleUpdateCategoryLessons(e)}>
                            Update
                        </Button>
                    }
                </ModalFooter>
                
            </Modal>
        </div>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createTpcCourseCategory : state.createTpcCourseCategory,
        createTpcCourseLessons : state.createTpcCourseLessons,
        updateTpcCourseLessons : state.updateTpcCourseLessons,
         fetchTpcCourses : state.fetchTpcCourses,
    }
      
}

export default connect(mapStateToProps)(AddTpcCourseCategoryLessonsModal)
