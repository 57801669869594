/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

var ps;

import { connect } from "react-redux";
import { studentsLifetimePlan } from "util/DataList";
import store from "redux/store";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const Sidebar = (props) => {
  	const [collapseOpen, setCollapseOpen] = useState();
  	const [profilePictureFilename, setProfilePictureFilename] = useState('')
  	// verifies if routeName is the one active (in browser input)
  	const activeRoute = (routeName) => {
		return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};
  	// toggles collapse between opened and closed (true/false)
  	const toggleCollapse = () => {
    	setCollapseOpen((data) => !data);
  	};
	// closes the collapse
	const closeCollapse = (e, n) => {
		setCollapseOpen(false);
		if (n === undefined) {
			setIsOpen(false)
		}
	};
	// creates the links that appear in the left menu / Sidebar
	const [subscribe, setSubscribe] = useState('lock');
	const [loadMenu, setLoadMenu] = useState(true)
	const fetchSubscriptionDetails = () => {
		if(props.userDetails.ultimateAdvisingStudent == "true" || props.userDetails.ultimateAdvisingStudent === true || studentsLifetimePlan.includes(props.userDetails.email)){
			setSubscribe('unlock')
		}
		else{
			if(props.fetchDetailsSubscription.fetchdetailssubscription.length){
				const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]
				if(subsDetails.status == "active" || subsDetails.status == "trialing"){
					setSubscribe('unlock')
				}else{
					const date = new Date();
					const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
					if(subsDetails.cancel_at_period_end){
						if(secondsSinceEpoch > subsDetails.canceled_at){
							setSubscribe('lock')
						}else{
							setSubscribe('unlock')
						}
					}else{
						setSubscribe('lock')
					}	
				}
			}else{
				setSubscribe('lock')
			}
		}
	}

	useEffect(() => {
		fetchSubscriptionDetails()
	}, [props.fetchDetailsSubscription, studentsLifetimePlan, props.userDetails ])

	const [isUas, setIsUas] = useState(false);
	const [isProMember, setIsProMember] = useState(false);

	const [redirectUrl, setRedirectUrl]  = useState("/client/community-free-members");

	useEffect(() => {
		setIsUas(props.userDetails.ultimateAdvisingStudent);
		if("pro member" === props.userDetails.plan){
			setIsProMember(true);
		}
		console.log("This is plan "+props.userDetails.plan);
		console.log("UAS? " + isUas);
		console.log("Pro? " + isProMember);
		if(isProMember) {
			setRedirectUrl("/client/community-pro-members");
		} else if(isUas) {
			setRedirectUrl("/client/community-uas");
		}
	}) 


  	const createLinks = (routes) => {
		return routes.map((prop, key) => {
			//For Admin Menu
			const adminPath = ["Manage Community", "Manage TPC Courses", "Log Student Hours"];
			if(typeof props.userDetails.admin != "undefined"){ //If admin field exist
				if(props.userDetails.admin == "true" || props.userDetails.admin === true){//If user is admin
					if(adminPath.includes(prop.name) ){//If menu is in admin path
						if (prop.name == "Manage Community" ) { 
							return (
								<NavItem key={key}>
									<NavLink
										style={{ cursor: 'pointer' }}
										onClick={toggleMenu}
										activeClassName="active"
									>
										<i className={loadMenu ? "" : prop.icon} />
										{ loadMenu ? <div className="placeholder-glow-side"><span className="placeholder-side"></span></div>  :  prop.name}
									</NavLink>
									<Collapse isOpen={isOpen} className="ml-5 p-0">
										{
											prop.items.map((v, i) => {
												return (
												<Nav key={i}>
													<NavItem  >
														<NavLink
															className="p-2"
															to={v.layout + v.path}
															tag={NavLinkRRD}
															onClick={(e) => closeCollapse(e, v.name)}
															activeClassName="active"
														>
															<i className={loadMenu ? "": v.icon} />
															{ loadMenu ? "" :  v.name}
										
														</NavLink>
													</NavItem>
												</Nav>
												)
											})
										}
									</Collapse>
								</NavItem>
							)
						}else{
							return (
								<NavItem key={key}>
									<NavLink
										to={prop.layout + prop.path}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className={loadMenu ? "":  prop.icon} />
										{ loadMenu ? <div className="placeholder-glow-side"><span className="placeholder-side"></span></div>  :  prop.name}
									</NavLink>
								</NavItem>
							)
						}			
					}	
				}
			}

			//For Coach Menu
			const coachPath = ["/manage-coach-tutor-availability","/manage-tutor-requests","/manage-users","/log-student-hours"];
			const othersPath = ["TPC Events","Ideas","Report Bugs", "My Profile"];
			let lockPages = ["Schedule Tutor", 'MCAT Powerbook', 'Chatbot', "Experiences", "Achievements", "Personal Statement", "Timeline", "My Application"];
			const hidePath = ["Plans", "Thank You Monthly", "Thank You Yearly", "Thank You Lifetime",  "Community", "Open Community", "Pro Members","Ultimate Advising Students","Community Page", "Community Settings"] 


			//for Students
			const launchDate = "2023-08-19T15:00:00.000Z";
			const launchDateEnd = "2023-08-19T18:00:00.000Z";
			const userCreatedAt = new Date(createdAt)
			const userCreatedAtSubtractedDate = new Date(userCreatedAt - 4 * 60 * 60 * 1000);

			// if(userCreatedAtSubtractedDate.toISOString() >= launchDate && userCreatedAtSubtractedDate.toISOString() <= launchDateEnd){
			// 	// lockPages.pop()
			// 	console.log(userCreatedAtSubtractedDate.toISOString() >= launchDate && userCreatedAtSubtractedDate.toISOString() <= launchDateEnd)
			// }

			if(prop.name == "Dashboard"){
				return (
					<NavItem key={key}>
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							onClick={closeCollapse}
							activeClassName="active"
						>
						<i className={loadMenu ? "":  prop.icon} />
						{ loadMenu ? <div className="placeholder-glow-side"><span className="placeholder-side"></span></div>  :  prop.name}
						</NavLink>
					</NavItem>
				)
			}

			//Setup Coach Menu
			if (props.userDetails.coach == "true" || props.userDetails.coach === true ) {
				// console.log(coachPath.path)
				if(coachPath.includes(prop.path) ){//If menu is in coach path
					
					return (
						<NavItem key={key}>
							<NavLink
								to={prop.layout + prop.path}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
							>
								<i className={loadMenu ? "":  prop.icon} />
								{ loadMenu ? <div className="placeholder-glow-side"><span className="placeholder-side"></span></div>  :  prop.name}
							</NavLink>
						</NavItem>
					)
				}
			}else{
				if (!othersPath.includes(prop.name) && !hidePath.includes(prop.name) && !coachPath.includes(prop.path)) {
					if (prop.path != '/upgrade-uas') {
						return (
							<NavItem key={key}>
							<NavLink
								to={prop.layout + prop.path}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								> 
								{console.log(lockPages)}
								<i className={loadMenu ? "": subscribe == "lock" && lockPages.includes(prop.name) ?  "fas fa-lock text-yellow" : prop.icon } />
								{ loadMenu ? <div className="placeholder-glow-side"><span className="placeholder-side"></span></div> : prop.name}
							</NavLink>
							</NavItem>
						)
					}
				}
				// if (!othersPath.includes(prop.name) && !hidePath.includes(prop.name)) {
				// 	if (prop.path != '/upgrade-uas') {
				// 		if (prop.path == "/manage-coach-tutor-availability" || prop.path == "/manage-tutor-requests") {
				// 			if (props.userDetails.coach == "true" || props.userDetails.coach === true) {
				// 				if(userCreatedAtSubtractedDate.toISOString() >= launchDate && userCreatedAtSubtractedDate.toISOString() <= launchDateEnd){
				// 					lockPages.pop()
				// 				}
				// 				return (
				// 					<NavItem key={key}>
				// 						<NavLink
				// 						to={prop.layout + prop.path}
				// 						tag={NavLinkRRD}
				// 						onClick={closeCollapse}
				// 						activeClassName="active"
				// 						>
				// 						<i className={subscribe == "lock" && lockPages.includes(prop.name) ? "fas fa-lock text-yellow" : prop.icon} />
				// 						{prop.name}
				// 						</NavLink>
				// 					</NavItem>
				// 				)
				// 			}	
				// 		} else {
				// 			if(userCreatedAtSubtractedDate.toISOString() >= launchDate && userCreatedAtSubtractedDate.toISOString() <= launchDateEnd){
				// 				lockPages.pop()
				// 			}
				// 			return (
				// 				<NavItem key={key}>
				// 					<NavLink
				// 					to={prop.layout + prop.path}
				// 					tag={NavLinkRRD}
				// 					onClick={closeCollapse}
				// 					activeClassName="active"
				// 					>
				// 					  	<i className={loadMenu ? "": subscribe == "lock" && lockPages.includes(prop.name) ? "fas fa-lock text-yellow" : prop.icon } />
                //           				{ loadMenu ? "" :  prop.name}
				// 					</NavLink>
				// 				</NavItem>
				// 			)
				// 		}		
				// 	}
				// }
			}
			
			if (prop.name == "Community" ) {
				return (
					<NavItem key={key}>
						<Redirect to={redirectUrl} />
						<NavLink 
							style={{ cursor: 'pointer' }}
							to={prop.layout+ '/community-free-members'}
							tag={NavLinkRRD}
							onClick={toggleMenu}
							activeClassName="active"
							>
							<i className={loadMenu ? "": subscribe == "lock" && lockPages.includes(prop.name) ? "fas fa-lock text-yellow" : prop.icon } />
              				{ loadMenu ? <div className="placeholder-glow-side"><span className="placeholder-side"></span></div>  :  prop.name}
						</NavLink>
						
						<Collapse isOpen={isOpen} className="ml-5 p-0">
							{
								prop.items.map((v, i) => {
									return (
										<Nav key={i}>
											<NavItem >
												<NavLink
													className="p-2"
													to={v.layout + v.path}
													tag={NavLinkRRD}
													onClick={(e) => closeCollapse(e, v.name)}
													activeClassName="active"
												>
													<i className={loadMenu ? "": subscribe == "lock" && lockPages.includes(v.name) ? "fas fa-lock text-yellow" : v.icon } />
                          							{ loadMenu ? "" :  v.name}
												</NavLink>
											</NavItem>
										</Nav>

									)
								})
							}
						</Collapse>
					</NavItem>
				)
			}
				
			// if(ultimateAdvisingStudent == "true" || ultimateAdvisingStudent === true){
			// 	if(prop.name == "Schedule Tutor"){
			// 		return (
			// 			<NavItem key={key}>
			// 				<NavLink
			// 					to={prop.layout +  prop.path}
			// 					tag={NavLinkRRD}
			// 					onClick={closeCollapse}
			// 					activeClassName="active"
			// 				>
			// 					<i className={
			// 						loadMenu ?  
			// 						""
			// 						: 
			// 						subscribe == "lock" && lockPages.includes(prop.name) 
			// 						? 
			// 							"fas fa-lock text-yellow" 
			// 						: 
			// 							prop.icon
			// 						} />
			// 						{ loadMenu ? <div className="placeholder-glow-side mr-5"><span className="placeholder-side"></span></div>  :  prop.name}
			// 				</NavLink>
			// 			</NavItem>
			// 		);
			// 	}
			// }
		});
  	};

	const createOthersLinks = (routes) => {
		return routes.map((prop, key) => {
			const othersPath = ['TPC Events',"Ideas","Report Bugs", "My Profile"]
			const lockPages = ['TPC Events','Ideas']

			if(othersPath.includes(prop.name)){
				return (
					<NavItem key={key}>
						<NavLink
							to={prop.layout + prop.path}
							tag={NavLinkRRD}
							onClick={closeCollapse}
							activeClassName="active"
						>
							<i className={loadMenu ? "": subscribe == "lock" && lockPages.includes(prop.name) ?  "fas fa-lock text-yellow" : prop.icon} />
                    		{ loadMenu ? ""  :  prop.name}
						</NavLink>
					</NavItem>
				);
			}
		});
	};

	const { bgColor, routes, logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
		to: logo.innerLink,
		tag: Link
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
		href: logo.outterLink,
		target: "_blank"
		};
	}

  	const [isOpen, setIsOpen] = useState(true);
  	const toggleMenu = () => setIsOpen(!isOpen);

	useEffect(() => {
		if(typeof props.userDetails.profilePicture != 'undefined'){
			setProfilePictureFilename(props.userDetails.profilePicture)
		}
	}, [])

	useEffect(() => {
		if(props.createProfilePicture.profilepicture.length){
			setProfilePictureFilename(props.createProfilePicture.profilepicture[0].profilePicture);
		}
	}, [props.createProfilePicture])
  
  
	const handleBackToAdmin = () => {
		store.dispatch(getUserData({onLogin:true}))
	}

	const [name, setName] = useState('');
	const [createdAt, setCreatedAt] = useState(new Date());
	const [ultimateAdvisingStudent, setUltimateAdvisingStudent] = useState(new Date());

	useEffect(() => {
		if(typeof props.userDetails.name != "undefined"){
			setName(props.userDetails.name);
			setCreatedAt(props.userDetails.createdAt);
			setUltimateAdvisingStudent(props.userDetails.ultimateAdvisingStudent);
			setLoadMenu(false)
			// setProfilePictureFilename(props.userDetails.profilePicture)
		}
	}, [props.userDetails])

	return (
		<Navbar
			className="navbar-vertical fixed-left navbar-dark bg-default"
			expand="md"
			id="sidenav-main"
		>
			<Container fluid>
				{/* Toggler */}
				<button
				className="navbar-toggler"
				type="button"
				onClick={toggleCollapse}
				>
				<span className="navbar-toggler-icon" />
				</button>
				{/* Brand */}
				{logo ? (
				<NavbarBrand className="pt-0" {...navbarBrandProps}>
					<img
					alt={logo.imgAlt}
					className="navbar-brand-img"
					src={logo.imgSrc}
					/>
				</NavbarBrand>
				) : null}
				{/* User */}
				<Nav className="align-items-center d-md-none">
				{/* <UncontrolledDropdown nav>
					<DropdownToggle nav className="nav-link-icon">
					<i className="ni ni-bell-55" />
					</DropdownToggle>
					<DropdownMenu
					aria-labelledby="navbar-default_dropdown_1"
					className="dropdown-menu-arrow"
					right
					>
					<DropdownItem>Action</DropdownItem>
					<DropdownItem>Another action</DropdownItem>
					<DropdownItem divider />
					<DropdownItem>Something else here</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown> */}
				<UncontrolledDropdown nav>
					<DropdownToggle nav>
					<Media className="align-items-center">
						{profilePictureFilename ? 
						
						<div className="card-profile-div-sm">
							<div className="card-profile-image">
							
								<div className="profile-picture-sm" style={{backgroundImage: `url(https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/` + profilePictureFilename+`)`}}></div>
							
							</div>
						</div>
						
						:

						<div className="card-profile-image ">
						{/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
							
							<div className="rounded-circle profile-letter-sm-primary ">{name.trim()[0]}</div>
						{/* </a> */}
						</div>}
					</Media>
					</DropdownToggle>
					<DropdownMenu className="dropdown-menu-arrow" right>
					<DropdownItem className="noti-title" header tag="div">
						<h6 className="text-overflow m-0">Welcome!</h6>
					</DropdownItem>

					{props.userDetails.admin == "true" ?
						<DropdownItem to="/admin/my-profile" tag={Link}>
							<i className="ni ni-single-02" />
							<span>My profile</span>
						</DropdownItem>

						:

						<DropdownItem to="/client/my-profile" tag={Link}>
						<i className="ni ni-single-02" />
						<span>My profile</span>
						</DropdownItem>
                	}
					
					{/* <DropdownItem to="/admin/user-profile" tag={Link}>
						<i className="ni ni-settings-gear-65" />
						<span>Settings</span>
					</DropdownItem>
					<DropdownItem to="/admin/user-profile" tag={Link}>
						<i className="ni ni-calendar-grid-58" />
						<span>Activity</span>
					</DropdownItem>
					<DropdownItem to="/admin/user-profile" tag={Link}>
						<i className="ni ni-support-16" />
						<span>Support</span>
					</DropdownItem> */}
					<DropdownItem divider />
					<DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
						<i className="ni ni-user-run" />
						<span>Logout</span>
					</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
				</Nav>
				{/* Collapse */}
				<Collapse navbar  isOpen={collapseOpen} className="d-lg-flex ">
				{/* Collapse header */}
				<div className="navbar-collapse-header d-md-none ">
					<Row>
					{logo ? (
						<Col className="collapse-brand" xs="6">
						{logo.innerLink ? (
							<Link to={logo.innerLink}>
							<img alt={logo.imgAlt} src={logo.imgSrcWhite} />
							</Link>
						) : (
							<a href={logo.outterLink}>
							<img alt={logo.imgAlt} src={logo.imgSrcWhite} />
							</a>
						)}
						</Col>
					) : null}
					<Col className="collapse-close" xs="6">
						<button
						className="navbar-toggler"
						type="button"
						onClick={toggleCollapse}
						>
						<span />
						<span />
						</button>
					</Col>
					</Row>
				</div>
				{/* Form */}
				{/* <Form className="mt-4 mb-3 d-md-none">
					<InputGroup className="input-group-rounded input-group-merge">
					<Input
						aria-label="Search"
						className="form-control-rounded form-control-prepended"
						placeholder="Search"
						type="search"
					/>
					<InputGroupAddon addonType="prepend">
						<InputGroupText>
						<span className="fa fa-search" />
						</InputGroupText>
					</InputGroupAddon>
					</InputGroup>
				</Form> */}
				{/* Navigation */}
				
				{props.adminUser.admin ?<>
				{
					props.userDetails.admin ? "" :
					<>
					<Nav navbar className="">
						<NavItem>
						<NavLink
							to={""}
							onClick={e => handleBackToAdmin(e)}
							style={{cursor:"pointer"}}>
							<i className="fas fa-user-cog text-info"></i>
							Back to Admin
						</NavLink>
						</NavItem>
					</Nav>

					<hr className="my-3 border-light" />
					</>
				}
				</> :  ""}

				<Nav navbar className="">{createLinks(routes)}</Nav>
				{/* Divider */}
				<hr className="my-3" />
				{/* Heading */}
				{/* <h6 className="navbar-heading text-muted">Others</h6> */}
				{/* Navigation */}
				<Nav className="mb-md-3 mt-auto" navbar>
					{/* <NavItem>
					<NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
						<i className="ni ni-spaceship" />
						Manage Users
					</NavLink>
					</NavItem> */}

					{createOthersLinks(routes)}
				</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};


const mapStateToProps = state  => {
  return {
      userDetails : state.loginAuth.userDetails,
      createProfilePicture : state.createProfilePicture,
      fetchDetailsSubscription : state.fetchDetailsSubscription,
      adminUser : state.adminUser
  }
    
}


export default connect(mapStateToProps)(Sidebar);
