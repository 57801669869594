import React from 'react'

import {loadStripe} from '@stripe/stripe-js';
import {
    Elements, PaymentElement,
} from '@stripe/react-stripe-js';

import {
    Card,
    Container,
    Row,
    Col,
    CardBody,
    
} from "reactstrap";
import Header from 'components/Headers/Header'
import SubscriptionForm from 'components/Payments/SubscriptionForm';

// const stripePromise = loadStripe('pk_test_51Jc7T3LrYzlL9Yv87MMJ6RYPDrp3btJth6fbaZJrykBY0ePHxiMIs9jdohnTcovMW75tepSVyTx1qqdHfW5C6G2n00Qa2b7ZuR');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const Payment = (props) => {
    
    const options = {
        mode: 'subscription',
        amount: 1099,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {/*...*/}
        
    };
  
    
    return (
        <>

            <Container className="mb-7" fluid>
                
                <Row>
                    <div className="col">
                        <Row>
                            {/* <Col lg="3"></Col> */}
                            <Col lg="12" className='mt-3'>

                                <Elements stripe={stripePromise} options={options}>
                                    
                                    <SubscriptionForm selectedPlan = {props.selectedPlan}/>
                                </Elements>
 
                            </Col>
                            {/* <Col lg="3"></Col> */}
                        </Row>
                    </div>
                </Row>
                
            </Container>
        </>
    )
}

export default Payment