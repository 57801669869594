import {
    CREATE_TPC_COURSE_REQUEST,
    CREATE_TPC_COURSE_FAILURE,
    CREATE_TPC_COURSE_RESET,
    CREATE_TPC_COURSE_SUCCESS,
    FETCH_TPC_COURSES_FAILURE,
    FETCH_TPC_COURSES_SUCCESS,
    FETCH_TPC_COURSES_REQUEST,
    UPDATE_TPC_COURSE_FAILURE,
    UPDATE_TPC_COURSE_REQUEST,
    UPDATE_TPC_COURSE_RESET,
    UPDATE_TPC_COURSE_SUCCESS,
    DELETE_TPC_COURSE_FAILURE,
    DELETE_TPC_COURSE_REQUEST,
    DELETE_TPC_COURSE_RESET,
    DELETE_TPC_COURSE_SUCCESS,
    CREATE_TPC_COURSE_CATEGORY_REQUEST,
    CREATE_TPC_COURSE_CATEGORY_FAILURE,
    CREATE_TPC_COURSE_CATEGORY_RESET,
    CREATE_TPC_COURSE_CATEGORY_SUCCESS,
    CREATE_TPC_COURSE_LESSONS_REQUEST,
    CREATE_TPC_COURSE_LESSONS_FAILURE,
    CREATE_TPC_COURSE_LESSONS_RESET,
    CREATE_TPC_COURSE_LESSONS_SUCCESS,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_REQUEST,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_FAILURE,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_RESET,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_SUCCESS,

    UPDATE_TPC_COURSE_CATEGORY_REQUEST,
    UPDATE_TPC_COURSE_CATEGORY_FAILURE,
    UPDATE_TPC_COURSE_CATEGORY_RESET,
    UPDATE_TPC_COURSE_CATEGORY_SUCCESS,

    UPDATE_TPC_COURSE_LESSONS_REQUEST,
    UPDATE_TPC_COURSE_LESSONS_FAILURE,
    UPDATE_TPC_COURSE_LESSONS_RESET,
    UPDATE_TPC_COURSE_LESSONS_SUCCESS,

    DELETE_TPC_COURSE_LESSONS_REQUEST,
    DELETE_TPC_COURSE_LESSONS_FAILURE,
    DELETE_TPC_COURSE_LESSONS_RESET,
    DELETE_TPC_COURSE_LESSONS_SUCCESS,

    DELETE_TPC_COURSE_CATEGORY_REQUEST,
    DELETE_TPC_COURSE_CATEGORY_FAILURE,
    DELETE_TPC_COURSE_CATEGORY_RESET,
    DELETE_TPC_COURSE_CATEGORY_SUCCESS,

    UPDATE_MARKED_COMPLETE_LESSON_REQUEST,
    UPDATE_MARKED_COMPLETE_LESSON_FAILURE,
    UPDATE_MARKED_COMPLETE_LESSON_RESET,
    UPDATE_MARKED_COMPLETE_LESSON_SUCCESS,

    FETCH_MARKED_COMPLETE_LESSON_REQUEST,
    FETCH_MARKED_COMPLETE_LESSON_FAILURE,
    FETCH_MARKED_COMPLETE_LESSON_RESET,
    FETCH_MARKED_COMPLETE_LESSON_SUCCESS,

    CREATE_BUNDLECOURSE_REQUEST, 
    CREATE_BUNDLECOURSE_SUCCESS, 
    CREATE_BUNDLECOURSE_FAILURE,
    CREATE_BUNDLECOURSE_RESET, 

    FETCH_BUNDLECOURSE_REQUEST, 
    FETCH_BUNDLECOURSE_SUCCESS, 
    FETCH_BUNDLECOURSE_FAILURE,
    FETCH_BUNDLECOURSE_RESET, 

} from "./ManageTpcCoursesTypes"

const initialCreateTpcCourseState = {
    tpccourses: [],
    loading : false
}

export const createTpcCourseReducer = (state = initialCreateTpcCourseState, action) => {
    switch(action.type){
        case CREATE_TPC_COURSE_RESET:
            return {
                loading: false,
                tpccourses: []
            }
        case CREATE_TPC_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_TPC_COURSE_SUCCESS:
            return {
                loading: false,
                tpccourses: [action.payload]
            }
        case CREATE_TPC_COURSE_FAILURE:
            return {
                loading : false,
                tpccourses : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchTpcCoursesState = {
    tpccourses : [],
    loading: false,

}

export const fetchTpcCoursesReducer = (state = initialFetchTpcCoursesState, action) => {
    switch (action.type) {
        case FETCH_TPC_COURSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_TPC_COURSES_SUCCESS:
            return {
                loading: false,
                tpccourses: action.payload
            }
        case FETCH_TPC_COURSES_FAILURE:
            return {
                loading: false,
                tpccourses: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateTpcCourseState = {
    tpccourses: [],
    loading : false
}

export const updateTpcCourseReducer = (state = initialUpdateTpcCourseState, action) => {
    switch(action.type){
        case UPDATE_TPC_COURSE_RESET:
            return {
                loading: false,
                tpccourses: []
            }
        case UPDATE_TPC_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TPC_COURSE_SUCCESS:
            return {
                loading: false,
                tpccourses: [action.payload]
            }
        case UPDATE_TPC_COURSE_FAILURE:
            return {
                loading : false,
                tpccourses: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialDeleteTpcCourseState = {
    tpccourses: [],
    loading : false
}

export const deleteTpcCourseReducer = (state = initialDeleteTpcCourseState, action) => {
    switch(action.type){
        case DELETE_TPC_COURSE_RESET:
            return {
                loading: false,
                tpccourses: []
            }
        case DELETE_TPC_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_TPC_COURSE_SUCCESS:
            return {
                loading: false,
                tpccourses: [action.payload]
            }
        case DELETE_TPC_COURSE_FAILURE:
            return {
                loading : false,
                tpccourses: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialCreateTpcCourseCategoryState = {
    tpccourse: [],
    loading : false
}

export const createTpcCourseCategoryReducer = (state = initialCreateTpcCourseCategoryState, action) => {
    switch(action.type){
        case CREATE_TPC_COURSE_CATEGORY_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case CREATE_TPC_COURSE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_TPC_COURSE_CATEGORY_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case CREATE_TPC_COURSE_CATEGORY_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialCreateTpcCourseLessonsState = {
    tpccourse: [],
    loading : false
}

export const createTpcCourseLessonsReducer = (state = initialCreateTpcCourseLessonsState, action) => {
    switch(action.type){
        case CREATE_TPC_COURSE_LESSONS_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case CREATE_TPC_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_TPC_COURSE_LESSONS_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case CREATE_TPC_COURSE_LESSONS_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateTpcCourseCategoryOrderState = {
    tpccourse: [],
    loading : false
}

export const updateTpcCourseCategoryOrderReducer = (state = initialUpdateTpcCourseCategoryOrderState, action) => {
    switch(action.type){
        case UPDATE_TPC_COURSE_CATEGORY_ORDER_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case UPDATE_TPC_COURSE_CATEGORY_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TPC_COURSE_CATEGORY_ORDER_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case UPDATE_TPC_COURSE_CATEGORY_ORDER_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateTpcCourseCategoryState = {
    tpccourse: [],
    loading : false
}

export const updateTpcCourseCategoryReducer = (state = initialUpdateTpcCourseCategoryState, action) => {
    switch(action.type){
        case UPDATE_TPC_COURSE_CATEGORY_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case UPDATE_TPC_COURSE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TPC_COURSE_CATEGORY_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case UPDATE_TPC_COURSE_CATEGORY_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUpdateTpcCourseLessonsState = {
    tpccourse: [],
    loading : false
}

export const updateTpcCourseLessonsReducer = (state = initialUpdateTpcCourseLessonsState, action) => {
    switch(action.type){
        case UPDATE_TPC_COURSE_LESSONS_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case UPDATE_TPC_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TPC_COURSE_LESSONS_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case UPDATE_TPC_COURSE_LESSONS_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDeleteTpcCourseLessonsState = {
    tpccourse: [],
    loading : false
}

export const deleteTpcCourseLessonsReducer = (state = initialDeleteTpcCourseLessonsState, action) => {
    switch(action.type){
        case DELETE_TPC_COURSE_LESSONS_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case DELETE_TPC_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_TPC_COURSE_LESSONS_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case DELETE_TPC_COURSE_LESSONS_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDeleteTpcCourseCategoryState = {
    tpccourse: [],
    loading : false
}

export const deleteTpcCourseCategoryReducer = (state = initialDeleteTpcCourseCategoryState, action) => {
    switch(action.type){
        case DELETE_TPC_COURSE_CATEGORY_RESET:
            return {
                loading: false,
                tpccourse: []
            }
        case DELETE_TPC_COURSE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_TPC_COURSE_CATEGORY_SUCCESS:
            return {
                loading: false,
                tpccourse: [action.payload]
            }
        case DELETE_TPC_COURSE_CATEGORY_FAILURE:
            return {
                loading : false,
                tpccourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUpdateMarkedCompleteLessonState = {
    markedcompletelesson: [],
    loading : false
}

export const updateMarkedCompleteLessonReducer = (state = initialUpdateMarkedCompleteLessonState, action) => {
    switch(action.type){
        case UPDATE_MARKED_COMPLETE_LESSON_RESET:
            return {
                loading: false,
                markedcompletelesson: []
            }
        case UPDATE_MARKED_COMPLETE_LESSON_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_MARKED_COMPLETE_LESSON_SUCCESS:
            return {
                loading: false,
                markedcompletelesson: [action.payload]
            }
        case UPDATE_MARKED_COMPLETE_LESSON_FAILURE:
            return {
                loading : false,
                markedcompletelesson : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchMarkedCompleteLessonState = {
    markedcompletelesson: [],
    loading : false
}

export const fetchMarkedCompleteLessonReducer = (state = initialFetchMarkedCompleteLessonState, action) => {
    switch(action.type){
        case FETCH_MARKED_COMPLETE_LESSON_RESET:
            return {
                loading: false,
                markedcompletelesson: []
            }
        case FETCH_MARKED_COMPLETE_LESSON_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MARKED_COMPLETE_LESSON_SUCCESS:
            return {
                loading: false,
                markedcompletelesson: [action.payload]
            }
        case FETCH_MARKED_COMPLETE_LESSON_FAILURE:
            return {
                loading : false,
                markedcompletelesson : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialCreateBundleCourseState = {
    loading: false,
    bundlecourse: [],
}
export const createBundleCoursesReducer = (state = initialCreateBundleCourseState, action) => {
    switch (action.type) {
        case CREATE_BUNDLECOURSE_RESET:
            return {
                loading: false,
                bundlecourse: [],
            }
        case CREATE_BUNDLECOURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_BUNDLECOURSE_SUCCESS:
            return {
                loading: false,
                bundlecourse: [action.payload]
            }
        case CREATE_BUNDLECOURSE_FAILURE:
            return {
                loading: false,
                bundlecourse: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialFetchBundleCoursesState = {
    bundlecourses : [],
    loading: false,

}

export const fetchBundleCoursesReducer = (state = initialFetchBundleCoursesState, action) => {
    switch (action.type) {
        case FETCH_BUNDLECOURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_BUNDLECOURSE_SUCCESS:
            return {
                loading: false,
                bundlecourses: action.payload
            }
        case FETCH_BUNDLECOURSE_FAILURE:
            return {
                loading: false,
                bundlecourses: [],
                error: action.payload
            }
        default: return state;
    }
}