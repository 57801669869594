import React, { useRef, useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';

import {
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    Input,
    Image,
    Form,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";

import Header from 'components/Headers/Header';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { getUserData } from 'redux/UserAuthentication/LoginActions';
import { app, db } from "../../firebase";
import { getDocs, getDoc, setDoc, addDoc, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
import { deleteCommunityReply } from 'redux/Community/CommunityAction';
import Reply from 'components/Community/Reply';
const ManageReplies = (props) => {
    const dispatch = useDispatch();
    const [data, setData]= useState([]);
    const [search, setSearch]= useState('');
    const [filter, setFilter] = useState([]);
    const [replyId, setReplyId] = useState('');
    const [replyItem, setReplyITem] = useState([]);

    //Modal Behaviour 
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);


    //Show posts in realtime
    useEffect(() => {
        const unsub = onSnapshot(query(collection(db, "communityReplies"), orderBy("updatedAt", "desc")), (snapshot) => {
            setData(
                snapshot.docs.map(
                    (doc) => (
                        { ...doc.data(), replyDocId: doc.id }
                    )
                )
            )
            setFilter(
                snapshot.docs.map(
                    (doc) => (
                        { ...doc.data(), replyDocId: doc.id }
                    )
                )
            )
        },(error) => {
            
        }
        );
        return unsub
    }, []);

    const truncate = (str,n) => {
        return (str.length > n) ? str.slice(0, n-1) + ' ...' : str;
    }

    const columns= [
        {
            name:"#",
            selector: (row) => row.replyDocId,
            wrap: true,
        },
        {
            name:"Content",
            selector: (row) => truncate(row.reply,40),
            wrap: true,
        },
        {
            name:"Files",
            selector: (row) => (
                row.fileContent.length ? 
                     row.fileContent.map((f, i) => {
                         return (
                             f.file.mimeType == "video/mp4"
                            ?
                                <video controls width="50" height="50" style={{ backgroundColor: '#000' }} >
                                    <source
                                        type={f.file.mimeType}
                                        src={'https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/' + f.name}
                                    />
                                </video>
                            :
                            <img width="50" height="50" src={'https://storage.googleapis.com/tpcdashboard.appspot.com/community-upload/' + f.name} /> 
                        )
                    })
                : 'No Files... '
               
            ),
            wrap: true,
        },
        {
            name:"Date",
            selector:(row)=>moment(row.updatedAt).format("MMM Do YY"),
        },
        {
            name:"Action",
            cell: (row) => (
                <>
                    <a className="btn btn-sm btn-primary" onClick={e => onClickViewReply(e, row.replyDocId, row)} ><i className="fas fa-eye"></i> View</a> 
                    
                    {
                        props.userDetails.admin === true || props.userDetails.admin == 'true'
                            ? 
                            <a className="btn btn-sm btn-warning" onClick={()=>handleDelete(row.replyDocId)}><i className="fas fa-trash"></i> Delete</a>
                        : ''      
                    }
                   
                </>
               
            )
        }

    ];

    useEffect(()=>{
        const result= data.filter((item)=>{
            return item.reply.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);
    
      //On Click Edit Post
    const onClickViewReply = async (e, replyid, item) => {
        const docRef = doc(db, "communityReplies", replyid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            let citem = docSnap.data();
            citem['replyDocId'] = replyid
        } else {
            console.log("No such document!");
        }
        e.preventDefault();
        setReplyId(replyid)
        setReplyITem(item)
        setBackdrop(true);
        setModal(true)
        
    
    }

    const handleDelete = (val) => {
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33'
        }).then((result) => {
           
            if (result.isConfirmed) {
                dispatch(deleteCommunityReply({ replyid: val }))
                if (modal) {
                    setModal(false)
                }
            }
        }) 
       
    }

    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        }
    }

    useEffect(() => {

        if(props.deleteCommunityReply.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{
            if (props.deleteCommunityReply.deletecommunityreply.length) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully deleted!',
                    icon : 'success',
                    timer: 3000
                })
          }
          else if (typeof props.deleteCommunityReply.error !== "undefined") {
            Swal.fire({
              title: 'Failed!',
              text: 'Something went wrong!',
              icon : 'error',
              timer: 3000
            })
          }
        }
    
    }, [props.deleteCommunityReply])

    return (  
        <>
  
            <Card className="shadow">
                <CardHeader className="border-0">
                    Manage Community Replies              
                </CardHeader>
                <CardBody>
                    {
                        data.length ?
                 
                        <DataTable 
                            // customStyles={ tableHeaderstyle}
                            columns={columns}
                            data={filter}
                            pagination
                            wrap
                            // selectableRows
                            // fixedHeader
                            selectableRowsHighlight
                            highlightOnHover
                        
                            subHeader
                            subHeaderComponent={
                                <input type="text"
                                className="w-25 form-control"
                                placeholder="Search a keyword content..."
                                value={ search}
                                onChange={(e)=>setSearch(e.target.value)}
                                />
                            }
                            subHeaderAlign="right"  
                            />
                         : <Alert color="primary"> No data available .</Alert>
                       }
                    
                    
                </CardBody>
            </Card>         
            <Modal isOpen={modal} toggle={modalToggle}  backdrop={backdrop} size="lg">
                <ModalHeader toggle={modalToggle}>{ 'Reply ID: '+replyId }</ModalHeader>
                <ModalBody>
                    {
                        replyItem ?
                            <Reply
                                key={replyId}
                                item={replyItem}
                                postDetails=""
                            />
                           
                            // console.log(replyItem)
                        : ''
                  }
                  
                </ModalBody>
                <ModalFooter className="">
                    <Button color="secondary" onClick={modalToggle}>
                        Cancel
                    </Button>
                    <Button  color="danger" onClick={e => handleDelete(replyId)}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
  
}

const mapStateToProps = state  => {
    return {
        userDetails: state.loginAuth.userDetails,
        deleteCommunityReply : state.deleteCommunityReply
    }
      
}

export default connect(mapStateToProps)(ManageReplies)

