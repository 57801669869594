export const FETCH_APPHOURUSED_LOGS_REQUEST = 'FETCH_APPHOURUSED_LOGS_REQUEST';
export const FETCH_APPHOURUSED_LOGS_SUCCESS = 'FETCH_APPHOURUSED_LOGS_SUCCESS';
export const FETCH_APPHOURUSED_LOGS_FAILURE = 'FETCH_APPHOURUSED_LOGS_FAILURE';

export const FETCH_APP_LOG_REQUEST = 'FETCH_APP_LOG_REQUEST';
export const FETCH_APP_LOG_SUCCESS = 'FETCH_APP_LOG_SUCCESS';
export const FETCH_APP_LOG_FAILURE = 'FETCH_APP_LOG_FAILURE';

export const FETCH_PREMIUMHOUR_LOGS_REQUEST = 'FETCH_PREMIUMHOUR_LOGS_REQUEST';
export const FETCH_PREMIUMHOUR_LOGS_SUCCESS = 'FETCH_PREMIUMHOUR_LOGS_SUCCESS';
export const FETCH_PREMIUMHOUR_LOGS_FAILURE = 'FETCH_PREMIUMHOUR_LOGS_FAILURE';

export const SEND_EMAIL_APPHOURUSED_REQUEST = 'SEND_EMAIL_APPHOURUSED_REQUEST';
export const SEND_EMAIL_APPHOURUSED_SUCCESS = 'SEND_EMAIL_APPHOURUSED_SUCCESS';
export const SEND_EMAIL_APPHOURUSED_FAILURE = 'SEND_EMAIL_APPHOURUSED_FAILURE';