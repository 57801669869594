import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavbarBrand
} from "reactstrap";
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form';
import { useEffect,useState } from "react";
import { loginUser } from "redux/UserAuthentication/LoginActions";
import { connect, useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { clearloginAuthenticatedErrors } from "redux/UserAuthentication/LoginActions";
import ForgotPasswordModal from "components/Modal/ForgotPasswordModal";
import { updateForgotPassword } from "redux/UserAuthentication/ForgotPasswordActions";
const Login = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
  
  const modalForgotPassword = props.updateForgotPasswordModalStatus.status;

  const toggleForgotPassword = () =>{
    dispatch(updateForgotPassword(!props.updateForgotPasswordModalStatus.status));
    
  } 

  useEffect(() =>{
    const messageRequired = "This field is required.";

    register("email", { 
        required: messageRequired, 
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address."
        } 
    }); 
    register("password", { required: messageRequired })

  },[])

  
  
  const onSubmit = (data) => {
    const userData = {
      email: data.email,
      password: data.password
    }
    
    dispatch(loginUser(userData));
  }


  // if(props.loginAuth.loading){
  //   Swal.fire({
  //     allowOutsideClick : false,
  //     didOpen: () => {
  //       Swal.showLoading()
        
  //     }
  //   });

  // }
  // else{
   
    
    // if(props.signupUser.signupuser.length && typeof props.signupUser.error === "undefined"){
    //   Swal.fire({
    //       title: 'Success!',
    //       text: 'Registered successfully!',
    //       icon : 'success',
    //       timer: 3000
          
    //     }
    //   ).then(function (result) {
          
    //     history.push("/auth/login");
      
    //   });

    //   dispatch(signupUserReset());
      
    // }

    if(props.loginAuth.errors != null){

      // if(props.loginAuth.error.hasOwnProperty('email')){
        Swal.fire({
          title:'Failed!',
          text: props.loginAuth.errors.general,
          icon :'error',
          timer: 3000
          
        })

        
      // }
      // else{
      //   Swal.fire({
      //     title:'Failed!',
      //     text:'Something went wrong, Please try again!',
      //     icon :'error',
      //     timer: 3000
          
      //   })
      // }
          
      dispatch(clearloginAuthenticatedErrors());       
      
    }

  // }



 

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center ">
              <strong>SIGN IN</strong>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit(onSubmit)} role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="text"
                    autoComplete="new-email"
                    name="email"
                    onChange={e => setValue("email", e.target.value)}
                  />
                </InputGroup>
                { errors.email && <small className="text-danger">{errors.email.message}</small> }
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    onChange={e => setValue("password", e.target.value)}
                  />
                </InputGroup>
                { errors.password && <small className="text-danger">{errors.password.message}</small> }
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              
              onClick={(e) => {e.preventDefault(); toggleForgotPassword()}}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
              onClick={(e) => {e.preventDefault(); history.push("/auth/register");}}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
      {/* <CoursesModal toggleForgotPassword={toggleForgotPassword} modalForgotPassword={modalForgotPassword} /> */}
      <ForgotPasswordModal modalForgotPassword={modalForgotPassword} toggleForgotPassword={toggleForgotPassword}  /> 
    </>
  );
};

const mapStateToProps = state => {
  return {
    loginAuth : state.loginAuth,
    updateForgotPasswordModalStatus : state.updateForgotPasswordModalStatus,
   
  }
}

export default connect(mapStateToProps)(Login);
