import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { MentionsInput, Mention} from "react-mentions";
import '../../assets/css/mention-style.css';

import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    Input,
    Image,
    Form,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
  } from "reactstrap";

  import Header from 'components/Headers/Header';
  import { connect } from "react-redux";
  import { useDispatch } from "react-redux";
  import classnames from "classnames";
  import Swal from "sweetalert2";
  import TextareaAutosize from "react-textarea-autosize";
  import Select, { components } from "react-select";
  import { createCommunityUasPost } from "redux/Community/UasAction"
  import { app, db } from "../../firebase";
  import {
    getDocs,
    getDoc,
    setDoc,
    addDoc,
    collection,
    query,
    where,
    onSnapshot,
    doc,
    orderBy,
    limit,
  } from "firebase/firestore";
  import UasPost from "components/Community/UasPost";
  import SearchUasPost from "components/Community/SearchUasPost";
  import { sendEmailNotification } from "redux/Community/EmailAction";

  const Uas = (props) => {
  const dispatch = useDispatch();
  const [anonymous, setAnonymous] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isUas, setIsUas] = useState(false);
  const [allUasPosts, setAllUasPosts] = useState([]);
  const [postContent, setPostContent] = useState("");
  const [category, setCategory] = useState("General");
  const fileInputRef = useRef(null);
  const [fileItemsLimit, setFileItemsLimit] = useState(10);
  const [imagePreview, setImagePreview] = useState([]);

  const [savePosts, setSavePosts] = useState([]);

  const [searchPost, setSearchPost] = useState("");
  const [searchedPost, setSearchedPost] = useState([]);

  const catoptions = ["All", "General", "Classes", "MCAT"];
  const [activeTab, setActiveTab] = useState("All");

  const [allUser, setAllUser] = useState([]);
  const [mentionValue, setMentionValue] = useState([]);
  
  useEffect(() => {
    const fetchAllUser  = async () => {
      let users = [];
      const userCollection = collection(db, "users");
      const getUser = query(userCollection, orderBy('name'));
      const querySnapshot = await getDocs(getUser);
      querySnapshot.forEach((doc) => {
        let v = doc.data();
         users.push({
          id: v.email,
          display: v.name,
        });
        setAllUser(users);
      });
    };
    fetchAllUser();
  }, [])

  const searchUser = (search) => {
    return allUser.filter(user => user.display.match(new RegExp(search, "i")));
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(db, "communityUasPosts"),
        orderBy("createdAt", "desc")
      ),
      (snapshot) => {
        setAllUasPosts(
          snapshot.docs.map((doc) => ({ ...doc.data(), postid: doc.id }))
        );
      },
      (error) => {console.log(error);}
    );
    return unsub;
  }, []);

  const searchAPost = async (value) => {
    let search = await arraySearch(allUasPosts, value);
    setSearchedPost(search);
    setSearchPost(value);
  };

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      return (
        value.textContent.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.userHandle.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.category.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  useEffect(() => {
    if (props.updateUserCommunitySettings.updateusercommunitysettings.length) {
      if (
        typeof props.updateUserCommunitySettings.updateusercommunitysettings[0]
          .anonymousPost &&
        props.updateUserCommunitySettings.updateusercommunitysettings[0]
          .anonymousPost === true
      ) {
        console.log(
          props.updateUserCommunitySettings.updateusercommunitysettings
        );
        setAnonymous(
          props.updateUserCommunitySettings.updateusercommunitysettings[0]
            .anonymousPost
        );
      } else {
      }
    } else {
      if (
        typeof props.userDetails.anonymousPost !== "undefined" &&
        props.userDetails.anonymousPost === true
      ) {
        console.log(props.userDetails);
        setAnonymous(props.userDetails.anonymousPost);
      } else {
      }
    }
  }, [props.userDetails, props.updateUserCommunitySettings]);

  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const createPost = () => {
    setBackdrop(true);
    setModal(true);
  };

  //Forms
  const handleChange = (event) => {
    setPostContent(event.target.value);
  };

  const categoryOptions = [
    { value: "General", label: "General" },
    { value: "Classes", label: "Classes" },
    { value: "MCAT", label: "MCAT" },
  ];

  const handleCategoryChange = (data) => {
    setCategory(data.value);
  };

  const clickInputFile = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const maxVideoSizeInMB = 20;
      const maxImageSizeInMB = 10;
      const allowedImgTypes = [
        "image/jpg",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];
      const allowedVideoTypes = ["video/mp4"];
      if (e.target.files.length > fileItemsLimit) {
        Swal.fire({
          title: "Upload limit!",
          text: " Files cannot be more than " + fileItemsLimit + " items",
          icon: "error",
        });
      } else {
        let pushItem = [];
        for (let i = 0; i < e.target.files.length; i++) {
          // console.log(e.target.files[i].name);
          // console.log(e.target.files.item(i).name);
          let fileSizeInMB = e.target.files[i].size / (1024 * 1024);
          if (allowedVideoTypes.includes(e.target.files[i].type)) {
            if (fileSizeInMB > maxVideoSizeInMB) {
              Swal.fire({
                title: "Maximum limit!",
                text:
                  e.target.files[i].name +
                  " File size exceeds the maximum limit of " +
                  maxVideoSizeInMB +
                  "MB",
                icon: "error",
              });
              return;
            } else {
              pushItem.push(e.target.files[i]);
            }
          } else if (allowedImgTypes.includes(e.target.files[i].type)) {
            if (fileSizeInMB > maxImageSizeInMB) {
              Swal.fire({
                title: "Maximum limit!",
                text:
                  e.target.files[i].name +
                  " File size exceeds the maximum limit of " +
                  maxImageSizeInMB +
                  "MB",
                icon: "error",
              });
              return;
            } else {
              pushItem.push(e.target.files[i]);
            }
          } else {
            Swal.fire({
              title: "Invalid file!",
              text: " Upoad a valid file ",
              icon: "error",
            });
            return;
          }
        }
        setImagePreview(pushItem);
      }
    }
  };

  useEffect(() => {
    if (fileInputRef.current) fileInputRef.current.value = null;
  }, [imagePreview]);

  const removeFile = (i) => {
    const newPreview = imagePreview.filter((item, index) => index !== i);
    setImagePreview(newPreview);
  };
  

  const onSubmit = async () => {

    const email = postContent.match(/[^(]+(?=\))/g);
    const usernameList = postContent.match(/\[(.*?)\]/g);
    let newContent = postContent;
    if(email) {
      let formattedUsers = [];
    usernameList.forEach(user => {
      const newUser = user.replace(/[\[\]']+/g, '');
      const trimmedUser = newUser.trimStart().split(" ");
      formattedUsers.push(trimmedUser[0]);
    });

    const user= {
      userName: formattedUsers,
    };

    const recipient = {
      email: email,
    };
    dispatch(sendEmailNotification({user: user, recipient: recipient }));

    mentionValue.forEach(mention => {
      const displayValue = mention.match(/\[(.*?)\]/g)[0].replace(/[\[\]']+/g, '');
      newContent = newContent.replaceAll(mention, `<span class='text-primary'>${displayValue}</span>`);
      setPostContent(newContent);
    });
    }
   
    const formData = new FormData();
    formData.append("userHandle", props.userDetails.handle);
    formData.append("textContent", newContent);
    formData.append("userId", props.userDetails.userId);
    formData.append("category", category);

    if (imagePreview.length) {
      for (let i = 0; i < imagePreview.length; i++) {
        formData.append(`files[${i}]`, imagePreview[i]);
      }
    }

    if (anonymous === true) {
      formData.append("anonymous", 1);
      dispatch(createCommunityUasPost(formData));
      setImagePreview([]);
      setPostContent("");
    } else {
      dispatch(createCommunityUasPost(formData));
      setImagePreview([]);
      setPostContent("");
    }
    setModal(!modal);
  };

  useEffect(() => {
    setIsUas(props.userDetails.ultimateAdvisingStudent);
  }, [props.userDetails.ultimateAdvisingStudent])

  useEffect(() => {
    if (props.createCommunityUasPost.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (props.createCommunityUasPost.createcommunityuaspost.length) {
        Swal.fire({
          title: "Success!",
          text: "Successfully created a post!",
          icon: "success",
          timer: 3000,
        });
      } else if (typeof props.createCommunityUasPost.error !== "undefined") {
        Swal.fire({
          title: "Oopps!",
          text: "Something went wrong!",
          icon: "error",
          timer: 3000,
        });
      }
    }
  }, [props.createCommunityUasPost]);

  useEffect(() => {
    if (typeof props.userDetails.handle != "undefined") {
      const unsubsave = onSnapshot(
        query(
          collection(db, "communitySaveUasPosts"),
          where("userHandle", "==", props.userDetails.handle),
          orderBy("createdAt", "desc")
        ),
        (snapshot) => {
          setSavePosts(
            snapshot.docs.map((doc) => ({ ...doc.data(), saveid: doc.id }))
          );
        },
        (error) => {}
      );
      return unsubsave;
    }
  }, []);
  
  return (
    isUas ? (<>
      <Header></Header>
      <Container className="">
              <Row>
                <Col lg="8">
                  <FormGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search a post"
                        type="text"
                        onChange={(e) => searchAPost(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {searchPost ? (
                searchedPost.length ? (
                  <SearchUasPost value={searchPost} items={searchedPost} />
                ) : (
                  "No results found"
                )
              ) : (
                <>
                  <Row>
                    <Col lg="8" className="d-flex justify-content-between">
                      <div>
                        <h1>Ultimate Advising Student</h1>
                        <p className="text-muted">
                          {allUasPosts.length + " Posts"}
                        </p>
                      </div>
                      <div>
                        <Button
                          color="success"
                          className="float-right"
                          onClick={createPost}
                        >
                          Create Post
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="8"
                      className="mt-3 mb-4 d-flex justify-content-center"
                    >
                      <Nav fill pills>
                        {catoptions.length > 0
                          ? catoptions.map((c, i) => {
                              return (
                                <NavItem className="ml-1" key={i}>
                                  <NavLink
                                    key={c}
                                    className={classnames({
                                      active: activeTab === c,
                                    })}
                                    onClick={() => {
                                      toggle(c);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {c}
                                  </NavLink>
                                </NavItem>
                              );
                            })
                          : ""}
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" className="mt-3 p-2">
                      <TabContent activeTab={activeTab}>
                        {catoptions.map((c, i) => {
                          return (
                            <TabPane tabId={c} key={i}>
                              {allUasPosts.length > 0
                                ? allUasPosts.map((val, key) => {
                                    return c == "All" ? (
                                      <UasPost
                                        key={c + "-" + val.postid}
                                        item={val}
                                        userList={allUser}
                                      />
                                    ) : c == val.category ? (
                                      <UasPost
                                        key={c + "-" + val.postid}
                                        item={val}
                                        userList={allUser}
                                      />
                                    ) : (
                                      ""
                                    );
                                  })
                                : ""}
                            </TabPane>
                          );
                        })}
                      </TabContent>
                    </Col>
                  </Row>
                </>
              )}
              <Modal
                isOpen={modal}
                toggle={modalToggle}
                backdrop={backdrop}
                centered
              >
                <ModalHeader toggle={modalToggle}>Create Post</ModalHeader>
                <ModalBody className="pt-1">
                  <div className="d-flex flex-row  mb-1">
                    <img
                      src={
                        "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                        props.userDetails.profilePicture
                      }
                      className="mr-2 rounded-circle"
                      alt={props.userDetails.name}
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    <div className="media-body">
                      <MentionsInput 
                        onChange={handleChange} 
                        value={postContent}
                        className="mentionWrapper"
                        placeholder="Type something here." 
                        allowSuggestionsAboveCursor={true}
                        allowSpaceInQuery={true}
                        >
                        <Mention 
                          className="mentionedFriend"
                          appendSpaceOnAdd={true} 
                          data={searchUser} 
                          onAdd={(id, display) => {
                            setMentionValue([...mentionValue, "@[" + display + "](" + id + ")"]);
                          }}/>
                      </MentionsInput>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center mb-1 ml-6"
                    style={{ overflowY: "auto" }}
                  >
                    {imagePreview
                      ? imagePreview.map((file, i) => {
                          return (
                            <div style={{ position: "relative" }} key={file.name}>
                              <Button
                                close
                                outline
                                onClick={(event) => removeFile(i)}
                              />
                              {file.type == "video/mp4" ? (
                                <video controls width="250" height="150">
                                  <source
                                    type={file.type}
                                    src={URL.createObjectURL(file)}
                                  />
                                </video>
                              ) : (
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt=""
                                  height="150"
                                  width="250"
                                  className=" m-2 rounded d-block "
                                  style={{ overflowY: "scroll" }}
                                />
                              )}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="border-top mt-3 mb-2"></div>
                  <div className="d-flex align-items-center  justify-content-between ">
                    <div className=" bg-white">
                      <input
                        type="file"
                        id="files"
                        accept="video/*,image/*"
                        multiple
                        onChange={(event) => handleFileChange(event)}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <Button
                        onClick={(event) => clickInputFile(event)}
                        color=""
                        // style={{
                        //     fontSize: '16px',
                        // }}
                      >
                        <i className="fas fa-solid fa-camera "></i>
                      </Button>
                    </div>
                    <div>
                      <Row>
                        <Col lg="12">
                          <Select
                            className="float-left"
                            placeholder="Select a category"
                            options={categoryOptions}
                            onChange={handleCategoryChange}
                            defaultValue={{ label: "General", value: "General" }}
                            value={categoryOptions.find(
                              (option) => option.value === category
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        onClick={onSubmit}
                        disabled={
                          postContent || imagePreview.length ? false : true
                        }
                      >
                        Post
                      </Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Container>
  
      </>
      ) : (
        <>
        <Header></Header>
          <Container className="mt--7" fluid>
            {/* <div className="header-body">
              <iframe src="https://link.optimizemd.io/widget/bookings/tpc-success/discovery-call" width={1000} height={900} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe>
            </div> */}

            <Row className='mb-3'>
                    <Col>
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <iframe src="https://link.optimizemd.io/widget/bookings/tpc-success/discovery-call" width={"100%"} height={900} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"></iframe>
                            </CardHeader>
                            {/* <CardBody>
                                
                                
                            </CardBody> */}
                        </Card>
                        
                    </Col> 
                </Row>

          </Container>
        </>
      )
  )
  }

  const mapStateToProps = (state) => {
    return {
      userDetails: state.loginAuth.userDetails,
      fetchDetailsSubscription: state.fetchDetailsSubscription,
      createCommunityUasPost: state.createCommunityUasPost,
      updateUserCommunitySettings: state.updateUserCommunitySettings,
      sendEmailNotification: state.sendEmailNotification,
    };
  };
  
  export default connect(mapStateToProps)(Uas);