import React, { useState, useEffect, SyntheticEvent } from "react";
import Header from "components/Headers/Header";
import DataTable from "react-data-table-component";

import {
  Card,
  CardTitle,
  CardText,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  CardFooter,
  Button,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
  Spinner,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { connect, useDispatch } from "react-redux";
import {
  createCoachTutorAvailability,
  createCoachTutorAvailabilityReset,
  fetchCoachTutorAvailability,
  deleteCoachTutorAvailability,
  deleteCoachTutorAvailabilityReset,
  fetchHistoryCoachTutorAvailability,
} from "redux/ManageCoachTutorAvailability/ManageCoachTutorAvailabilityActions";
import Swal from "sweetalert2";
import { app, db } from "../../firebase";
import Select from "react-select";
import CoachSchedule from "components/Pages/CoachSchedule";
import {
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
  or,
} from "firebase/firestore";

const config = {
  clientId:
    "449085225291-8923envh8uu4ilt2jscrqii76mp8gmiv.apps.googleusercontent.com",
  apiKey: "AIzaSyDQ_BmfIYtyM1jZjpht6TQvmpyIm5P64jQ",
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const ManageCoachTutorAvailability = (props) => {
    const dispatch = useDispatch();

    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(true);
    const [repeatDays, setRepeatDays] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [selectedDays, setSelectedDays] = useState([]);
    const [schedules, setSchedules]  = useState([]);

    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);

    // useEffect(() => {
    //     const unsub = onSnapshot(query(collection(db, "coachAvailableSchedule"), where("userHandle", "==",props.userDetails.handle ), orderBy("createdAt","desc")), (snapshot) =>
    //         setSchedules(snapshot.docs.map((doc) => (
    //           { ...doc.data(), schedid: doc.id 
                
    //           }
    //       )))
    //     );
    //     return unsub
    // },[props]);
    // useEffect(() => {
    // const unsub = onSnapshot(
    //     query(collection(db, "coachAvailableSchedule"), where("userHandle", "==" ,props.userDetails.handle), orderBy("createdAt", "desc")),
    //     (snapshot) => {
    //         setSchedules(
    //         snapshot.docs.map((doc) => ({ ...doc.data(), schedid: doc.id }))
    //         );
    //     },
    //     (error) => {
    //         console.log("Error");
    //     }
    //     );
    //     return unsub;
    // }, [props]);

    useEffect(() => {
       dispatch(fetchCoachTutorAvailability({handle: props.userDetails.handle}));
    }, [props.userDetails]);

  useEffect(() => {
    if (props.fetchCoachTutorAvailability.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }

    if (props.fetchCoachTutorAvailability.fetchcoachtutoravailability.length) {
      setData(
        props.fetchCoachTutorAvailability.fetchcoachtutoravailability[0]
      );
        setFilter(
        props.fetchCoachTutorAvailability.fetchcoachtutoravailability[0]
      );

      
    }
  }, [props.fetchCoachTutorAvailability]);

    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
    };

    let twoDaysBefore = moment().add(1, "day");
    let valid = function (current) {
        return current.isAfter(twoDaysBefore);
    };
    const handleStartDateOnchange = (e) => {
        setStartDate(new Date(e.toJSON()));
    }
    const handleEndDateOnchange = (e) => {
        setEndDate(new Date(e.toJSON()));
    }
    const handleStartTimeOnchange = (e) => {
        setStartTime(new Date(e.toJSON()));
    }
    const handleEndTimeOnchange = (e) => {
        setEndTime(new Date(e.toJSON()));
    }

  useEffect(() => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const setdays = [];
    days.forEach((d) => {
      setdays.push({
        value: d,
        label: d,
      });
    });
    // setdays.unshift({ value: "all", label: "Everyday" });
    setRepeatDays(setdays);
  }, []);

  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const createAvailability = () => {
    setBackdrop(true);
    setModal(true);
  };

  const saveSchedule = async () => {
      setModal(!modal)
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
        const dataDays = [];
        if (selectedDays) {
        selectedDays.map((i) => dataDays.push(i.value));
        }

        const docs = {
            startdate: convertThedate(startDate),
            enddate: convertThedate(endDate),
            starttime: moment.utc(startTime).format(),
            endtime: moment.utc(endTime).format(),
            description: description,
            //   status: switched,
            days: dataDays,
            userHandle: props.userDetails.handle,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };

        const newData = await addDoc(collection(db, "coachAvailableSchedule"), docs);
        const docRef = doc(db, "coachAvailableSchedule", newData.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            Swal.fire({
                title: "Success",
                text: "Sucessfully created",
                icon: "success",
                timer: 3000,
            });
            
        }

      dispatch(fetchCoachTutorAvailability({handle: props.userDetails.handle}));
  }

  const convertThedate = (date) => {
     return moment(date).utcOffset(0, false).format('ll');
  }

  const convertDefault = (date) => {
    return moment(date).utcOffset(0, true).format()
  }

  const convertTheTime = (time) => {
    return moment(new Date(time)).utcOffset(0, false).format("h:mm A") 
  }

   const convertTimeToUTC = (time) => {
        return moment(moment(time).utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

  const columns = [
   
    {
      name: "Date",
      selector: (row) => {
         return convertThedate(row.startdate) + ' - '+ convertThedate(row.enddate) ;
      },
    },
    {
      name: "Repeat Days",
      selector: (row) => {
         return row.days.toString()
      },
    },

    {
      name: "Time",
      selector: (row) => {
        return convertTimeToLocal(row.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(row.endtime).format("h:mm A")
      },
      wrap: true,
    },
    // {
    //   name: "Created at",
    //   selector: (row) => {
    //     return convertThedate(row.createdAt) 
    //   },
    //   wrap: true,
    // },
   
    {
      name: "Action",
      cell: (row) => (
        <CoachSchedule  item={row}/>
      ),

      // center: true,
    },
  ];

   const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        // backgroundColor: "#ccc",
      },
    },
  };

    useEffect(() => {
    console.log(data)
    const result = data.filter((item) => {
      return (
        item.days.toString().match(search.toLocaleLowerCase()) ||
        convertThedate(item.startdate).toLowerCase().match(search.toLocaleLowerCase()) ||
        convertThedate(item.enddate).toLowerCase().match(search.toLocaleLowerCase()) ||
        convertTheTime(item.starttime).toLowerCase().match(search.toLocaleLowerCase()) ||
        convertTheTime(item.endttime).toLowerCase().match(search.toLocaleLowerCase()) 
      );
    });
    console.log(result)
    setFilter(result);
  }, [search]);

  return (
    <>
      <Header></Header>

      <Container className="mt--7" fluid>
        {/* <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-1">
                <div className="float-left d-none d-md-block d-lg-block">
                  <h className="h3 mb-0">Manage Coach Tutor Availability</h>
                </div>
                <div>
                  <Button
                    color="success"
                    className="float-right"
                    onClick={createAvailability}
                  >
                    Create Schedule Availability
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Set Availability
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Date <span className="text-danger">*</span>
                        </label>
                        <ReactDatetime
                        //   onChange={(e) => handleDateOnChange(e)}
                          isValidDate={valid}
                          timeFormat={false}
                          className="w-100"
                          inputProps={{ placeholder: "Select Date" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          From <span className="text-danger">*</span>
                        </label>
                        <ReactDatetime
                          value={timeFrom}
                          onChange={(e) => handleTimeFromOnChange(e)}
                          timeConstraints={{
                            minutes: { min: 0, max: 0, step: 0 },
                          }}
                          dateFormat={false}
                          className="w-100"
                          inputProps={{ placeholder: "Select time" }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          To <span className="text-danger">*</span>
                        </label>
                        <ReactDatetime
                          value={timeTo}
                          onChange={(e) => handleTimeToOnChange(e)}
                          timeConstraints={{
                            minutes: { min: 0, max: 0, step: 0 },
                          }}
                          dateFormat={false}
                          className="w-100"
                          inputProps={{ placeholder: "Select time" }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Description for student
                        </label>
                        <Input
                          id="inputNotesForStudents"
                          name="inputExperienceDescription"
                          placeholder="Write a description..."
                          rows="5"
                          type="textarea"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Col lg="12 mt-3"></Col>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                //   onClick={(e) => handleCoachTutorAvailabilityOnClick(e)}
                  type="submit"
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </div>
        </Row> */}
        <Row>
          <Col>
            <Card className="shadow">
            <CardHeader className="border-1">
                <div className="float-left d-none d-md-block d-lg-block">
                  <h3 className="h3 mb-0">Manage Coach Tutor Availability</h3>
                </div>
                 <div>
                  <Button
                    color="success"
                    className="float-right"
                    onClick={createAvailability}
                  >
                    Create Schedule Availability
                  </Button>
                </div>
               
              </CardHeader>
              <CardBody> 
               
                <Row>
                  <Col >
                   {data.length ? (
                      
                      <DataTable
                        customStyles={tableHeaderstyle}
                        columns={columns}
                        data={filter}
                        pagination
                        wrap
                        // selectableRows
                        // fixedHeader
                        selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                          <input
                            type="text"
                            className="w-50 form-control"
                            placeholder="Search a keyword content..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        }
                        subHeaderAlign="right"
                      />
                    ) : (
                      <Alert color="primary">No data available .</Alert>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>

       
       
        <Modal
          isOpen={modal}
          toggle={modalToggle}
          backdrop={backdrop}
          size="lg"
          centered
        >
          <ModalHeader toggle={modalToggle}>
            Create Recurring Availability
          </ModalHeader>
          <ModalBody className="pt-1">
            <Row>
                <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        Start Date <span className="text-danger"> *</span>
                    </label>
                    <ReactDatetime
                        onChange={(e) => handleStartDateOnchange(e)}
                        isValidDate={valid}
                        timeFormat={false}
                        className="w-100"
                        inputProps={{ placeholder: "Select Date" }}
                    />
                    </FormGroup>
                </Col>
                  <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        End Date  <span className="text-danger">*</span>
                    </label>
                    <ReactDatetime
                        onChange={(e) => handleEndDateOnchange(e)}
                        isValidDate={valid}
                        timeFormat={false}
                        className="w-100"
                        inputProps={{ placeholder: "Select Date" }}
                    />
                    </FormGroup>
                </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Start Time <span className="text-danger">*</span>
                  </label>
                  <ReactDatetime
                    value={startTime}
                    onChange={(e) => handleStartTimeOnchange(e)}
                    timeConstraints={{
                       minutes: { min: 30, max: 30, step: 31 },
                    }}
                    dateFormat={false}
                    className="w-100"
                    inputProps={{ placeholder: "Select time" }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    End Time <span className="text-danger">*</span>
                  </label>
                  <ReactDatetime
                    value={endTime}
                    onChange={(e) => handleEndTimeOnchange(e)}
                    timeConstraints={{
                       minutes: { min: 30, max: 30, step: 31 },
                    }}
                    dateFormat={false}
                    className="w-100"
                    inputProps={{ placeholder: "Select time" }}
                  />
                </FormGroup>
             
              </Col>
              <Col lg="12">
                    <Label>Select days to repeat the time schedule</Label>
                    <FormGroup>
                        <Select
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={customStyles}
                        isMulti
                        options={repeatDays}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={selectedDays}
                        onChange={setSelectedDays}
                        />
                    </FormGroup>
                </Col>
                <Col lg="12">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        Description
                    </label>
                    <Input
                        placeholder="Write a description..."
                        rows="5"
                        type="textarea"
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    </FormGroup>
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={modalToggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={saveSchedule}>Save</Button>
          </ModalFooter>
        </Modal>

     
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    createCoachTutorAvailability: state.createCoachTutorAvailability,
    fetchCoachTutorAvailability: state.fetchCoachTutorAvailability,
    fetchHistoryCoachTutorAvailability:state.fetchHistoryCoachTutorAvailability,
    deleteCoachTutorAvailability: state.deleteCoachTutorAvailability,
  };
};

export default connect(mapStateToProps)(ManageCoachTutorAvailability);
