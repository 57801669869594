import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  Col,
  FormGroup,
  CardBody,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CustomInput,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Spinner
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  fetchAllUsers,
  fetchUserDetails,
  updateUserDetails,
  updateUserDetailsReset,
  updateMultiUsers,
  updateMultiUsersReset,
} from "redux/ManageUsers/ManageUsersAction";

import { customDropdown } from "util/reactCustomStyles";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { app, db } from "../../firebase";
import classnames from "classnames";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
const ManageRegisteredUsers = (props) => {
  	const dispatch = useDispatch();

   	const [userLoading, setUserLoading] = useState(true);
  	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
  	const [filter, setFilter] = useState([]);

  	const [checkUsers, setCheckUsers] = useState([]);


  	const [mainActiveTab, setMainActiveTab] = useState("Users");
   	const toggleMain = (tab) => {
		if (mainActiveTab !== tab) setMainActiveTab(tab);
	};

  	useEffect(() => {
		const fetchAllUsers = async () => {
			const querySnapshot = await getDocs(collection(db, "users"));
			let users = [];
			querySnapshot.forEach(async (docs) => {
				let v = docs.data();
				if(typeof  v.mcat_purchase_img != "undefined"){
					v.mcat = 'mcat'
				}else{
					v.mcat = 'No'
				}
				if(typeof v.assignedCoach != "undefined" ){
					if(v.assignedCoach){
						const docRef = doc(db, "users", v.assignedCoach);
						const docSnap = await getDoc(docRef);
						v.mycoach = docSnap.data()
					}
				}
				if(typeof  v.admin === "undefined" || v.admin === false || v.admin == "false"){
					if(typeof  v.coach === "undefined" || v.coach === false || v.coach == "false"){
						if(v.plan === "free account" ){
							if(v.ultimateAdvisingStudent == "false" || v.ultimateAdvisingStudent === false || typeof  v.ultimateAdvisingStudent === "undefined"){
								users.push(v);
							}	
						}
					
					}
				}
				
			
			});
		
			setData(users);
			setFilter(users);
		};
		fetchAllUsers();
	}, []);

  	useEffect(() => {
		if(filter.length){
			setUserLoading(false)
		}
  	}, [filter]);

  	const convertDate = (item) => {
    	return moment(item).utcOffset(0, false).format("YYYY-MM-DD");
  	};

  	const notUAS = (data) => {
		if(typeof data == "undefined"){
			return "false";
		}else{
			if (data == "true" || data === true ){
				return "false";
			}
		}
  	}

	const [modalUpdateUser, setModalUpdateUser] = useState(false);
	const [modalUpdateMultiUsers, setModalUpdateMultiUsers] = useState(false);
	const [selectedUsers, setselectedUsers] = useState([]);


	  useEffect(() => {
    console.log(data)
    const result = data.filter((item) => {
      return (
        item.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.email.toLowerCase().match(search.toLocaleLowerCase()) ||
		item.mcat.toLowerCase().match(search.toLocaleLowerCase()) ||
        convertDate(item.createdAt).toLowerCase().match(search.toLocaleLowerCase()) 
      );
    });
  
    setFilter(result);
  }, [search]);


    const columns = [
    {
      name: "Name",
      selector: (row) => {
		return(
			
			<Media className="align-items-center">
				<a
				className="avatar rounded-circle mr-3 "
				
				onClick={(e) => e.preventDefault()}
			>
				{
				typeof row.profilePicture != "undefined" ? (
				<div className="card-profile-div-sm card-profile-div-md">
					<div className="card-profile-image">
						<div
							className="profile-picture-sm profile-picture-md"
							style={{
							backgroundImage:
								'url("https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/' +
								row.profilePicture +
								'")',
							}}
						></div>
					</div>
				</div>
				) : 
				(
					<div className="rounded-circle profile-letter-all-users "
						style={{width: "38px", height: "38px"}}>
						{row.name.trim()[0].toUpperCase() }
					</div>
				)
				}
			</a>
			
				<span className="mb-0 text-sm">{row.name}</span>
			</Media>
			
	
		)
	  },
    //   wrap: true,
    },
	{
      name: "Name",
      selector: (row) => {
		return row.email
	  },
     
    },
	{
      name: "Coach",
      selector: (row) => {
        return row.mycoach ?  row.mycoach.name : "N/A";
      },
    },
    {
      name: "Entry Date",
      selector: (row) => {
        return convertDate(row.createdAt);
      },
    },
	{
      name: "MCAT Purchased",
      selector: (row) => {
        return  row.mcat == "No" ? "N/A" : <a href={"https://storage.googleapis.com/tpcdashboard.appspot.com/mcatpowerbookusers/"+row.mcat_purchase_img}>Downloa Purchased Photo</a>
      },
    },
    {
    name: "Subscription",
    selector: (row) =>   {
		return (
			row.ultimateAdvisingStudent == "true" || row.ultimateAdvisingStudent === true
			? "Ultimate Advising Student"
			: typeof row.subsId != "undefined"
			? "PRO Member"
			: "Free Account"
						
		)
	},
    wrap: true,
    },
	
    {
      name: "Action",
      cell: (row) => (
        <>
		   <button
         		className="btn btn-sm btn-primary"
            >
               View Details
            </button>
        </>
      ),

      center: true,
    },
  ];
    const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        // backgroundColor: "#ccc",
      },
    },
  };

  return (
    <>
      <Header></Header>
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              	<CardHeader className="border-1">
					<div className="float-left">
					<h5 className="h3 mb-0">Manage Registered Users </h5>
					{/* <h5 className="h3 mb-0 text-yellow">
						{props.userDetails} Premium Tutoring Hours
						Remaining
					</h5> */}
					</div>
              	</CardHeader>
              	<CardBody>
                	<Row>
					<Col className="mt-3 mb-4 d-flex justify-content-center">
						<Nav fill pills>
							<NavItem className="ml-1" >
							<NavLink
								className={classnames({
								active: mainActiveTab === "Users",
								})}
								onClick={() => {
								toggleMain("Users");
								}}
								style={{ cursor: "pointer" }}
							>
								Users
							</NavLink>
							</NavItem>
							<NavItem className="ml-1" >
							<NavLink
								className={classnames({
								active: mainActiveTab === "Settings",
								})}
								onClick={() => {
								toggleMain("Settings");
								}}
								style={{ cursor: "pointer" }}
							>
								Settings
							</NavLink>
							</NavItem>
							
						</Nav>
					</Col>
					</Row>
					<Row>
						<Col lg="12" className="mt-3 p-2">
							<TabContent activeTab={mainActiveTab}>
								<TabPane tabId="Users">
									<Row>
										<Col>
									{ userLoading ? 
										<div className="m-5">
											<Alert color="secondary" className="text-center">
												Collecting data.. Please wait while loading {" "}
												<Spinner size="sm" color="primary" />{" "}
											</Alert>
										</div>
									 : data.length ? 
										   (	<DataTable
												customStyles={tableHeaderstyle}
												columns={columns}
												data={filter}
												pagination
												wrap
												// selectableRows
												// fixedHeader
												selectableRowsHighlight
												highlightOnHover
												subHeader
												subHeaderComponent={
												<input
													type="text"
													className="w-50 form-control"
													placeholder="Search a user .."
													value={search}
													onChange={(e) => setSearch(e.target.value)}
												/>
												}
												subHeaderAlign="right"
											/> )
											: ( <Alert color="primary">No data available .</Alert> )
									
										}
										</Col>
									</Row>
								</TabPane>
								<TabPane tabId="Settings">
									<Row>
									</Row>
								</TabPane>
							
							</TabContent>
						</Col>
					</Row>
            	</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

   

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fetchAllUsers: state.fetchAllUsers,
    fetchUserDetails: state.fetchUserDetails,
    updateUserDetails: state.updateUserDetails,
    updateMultiUsersReducer: state.updateMultiUsersReducer,
    fetchCoaches: state.fetchCoaches,
  };
};

export default connect(mapStateToProps)(ManageRegisteredUsers);
