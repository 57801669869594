import React, { useEffect, useState } from 'react'
import {

    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table

} from "reactstrap";



const SchoolDOModal = (props) => {

    let [applicationDeadlineDay, setApplicationDeadlineDay] = useState(null);
    let [applicationDeadlineMonth, setApplicationDeadlineMonth] = useState(null);
    let [applicationDeadlineYear, setApplicationDeadlineYear] = useState(null);

    useEffect(() => {

        let applicationDeadlineDate = new Date(props.medicalSchoolDetails.overview.priApplLatestDtText);

        setApplicationDeadlineDay(applicationDeadlineDate.getDate());
        setApplicationDeadlineMonth(applicationDeadlineDate.toLocaleString('default', { month: 'long' }));
        setApplicationDeadlineYear(applicationDeadlineDate.getFullYear());

    }, [])

    const formatterUSD = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    
      const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    return (
    <>
        <Col lg="12 text-center mb-3"><h2 className="text-warning">DETAILS</h2></Col>
        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Application Deadline : <span className="font-weight-normal">{applicationDeadlineMonth} {applicationDeadlineDay}, {applicationDeadlineYear}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">In-state annual tuition: <span className="font-weight-normal">{formatterUSD.format(props.medicalSchoolDetails.overview.tuitionAndFeesRes)}</span></h3></Col>
        <Col lg="1"></Col>
        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Mean GPA : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg && props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg != "0.00" ? props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg : "Not Available"}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Mean MCAT : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg && props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg != "0.0" ? props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg : "Not Available"}</span></h3></Col>
        <Col lg="1"></Col>
        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Institution : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.publicPrivate == "I" ? "Private" : props.medicalSchoolDetails.overview.publicPrivate == "P" ? "Public" : "Not Provided"}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Campus Type : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.campusType}</span></h3></Col>
        <Col lg="1"></Col>
        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Year Founded : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolInformation.yearFounded ? props.medicalSchoolDetails.medSchoolInformation.yearFounded : "Not Provided"}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm">Require CASPer : <span className="font-weight-normal">{props.medicalSchoolDetails.requireCasper == "Y"? <label className='text-warning'>{"Yes"}</label> : "Not Provided"}</span></h3></Col>
        <Col lg="1"></Col>
        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm"><i className="fa fa-envelope"></i> {props.medicalSchoolDetails.campusList[0].email ? <a href={"mailto:"+props.medicalSchoolDetails.campusList[0].email} className='text-primary'> {props.medicalSchoolDetails.campusList[0].email}</a> : "Not Available"}</h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder text-sm"><i className="fa fa-phone"></i> {props.medicalSchoolDetails.campusList[0].phone ? <a href={"tel:+"+props.medicalSchoolDetails.campusList[0].phone} className='text-primary'> {formatPhoneNumber(props.medicalSchoolDetails.campusList[0].phone)}</a> : "Not Available"} </h3></Col>
        <Col lg="1"></Col>
        <Col lg="12 text-center mt-4">
            <a href={props.medicalSchoolDetails.admUrl} target='__blank' className='btn btn-info'>Visit Website</a>
        </Col>
    </>
  )
}

export default SchoolDOModal