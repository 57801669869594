import React, {useState, useEffect} from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    CardImg,
    CardImgOverlay,
    TabContent, TabPane, Nav, NavItem, NavLink, 
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
    Alert,
    Spinner,
    Media,
    Table
} from "reactstrap";
import { connect, useDispatch } from 'react-redux';
import classnames from "classnames";
import DataTable from "react-data-table-component";
import {fetchAppHourUsedLogs} from "redux/Logs/LogsAction";
import moment from "moment-timezone";

const Logs = (props) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
   
	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
 
    // useEffect(() => {
    //     if(props.items.length){
    //        setData(props.items)
    //        setLoading(false)
    //     }
	// }, [props.items]);


	const modifiedSearch = (item) =>  {
		if(item !== undefined){
			if(item.name !== undefined){
				return  item.name.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}

    const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }

    const loadHistory = () =>{
        dispatch(fetchAppHourUsedLogs())
    }

    useEffect(() => {
        dispatch(fetchAppHourUsedLogs())
    }, []);

    useEffect(() => {
		if (props.fetchAppHourUsedLogs.loading) {
           setLoading(true)
		} else {
			
			if (props.fetchAppHourUsedLogs.logs.length) {
                setData(props.fetchAppHourUsedLogs.logs)
                setLoading(false)
			}
			
		}
	}, [props.fetchAppHourUsedLogs]);

    return (
        <>
		    <Card className="shadow" >
                <CardBody>
                    <CardTitle>
                        Updates History <span className="fas fa-sync-alt ml-2 text-info" role="button" onClick={loadHistory} title="Reload history"></span>
                    </CardTitle>
                        { 
                                
                        loading ? 
                            <div className="m-5">
                                <Alert color="secondary" className="text-center">
                                    Loading.. Please wait while loading {" "}
                                    <Spinner size="sm" color="primary" />{" "}
                                </Alert>
                            </div>
                        : data.length ? 
                            <div style={{maxHeight: "600px", overflowY:"scroll"}}>
                                {
                                    data.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)).map((d,i)=>(
                                        <Card key={d.docid} className="py-2 px-3 mb-1" role="button">
                                        
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h5 className="text-uppercase mb-0">{d.item.category.replace("_"," ")} 
                                                    {/* <span className="text-success ml-2">{Number(d.item.value)}</span> */}
                                                    </h5>
                                                    <h6 className="text-muted mb-0">Student:  {d.student.name}</h6> 
                                                    <h6 className="text-muted mb-0">Coach: {d.coach.name}</h6> 
                                                </div>
                                                <div className="text-center">
                                                    <h4 className="text-success ml-2"> +{Number(d.item.value) - Number(d.item.prev) }</h4>
                                                    <h6 className="text-muted">{convertDate(d.createdAt)}</h6> 
                                                </div>
                                            </div>
                                        
                                        </Card>
                                    ))
                                }
                            </div>
    
                        : 
                        <Alert color="secondary">No data available .</Alert>
                    }

                </CardBody>
            </Card>

        </>
    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchAppHourUsedLogs: state.fetchAppHourUsedLogs
    }
      
}

export default connect(mapStateToProps)(Logs)
