import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardSubtitle,
    CardText,
    CardBody,
    CardTitle,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    CardImg,
    Media,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import CourseItemDetails from "components/Courses/CourseItemDetails" 

const ManageCourseItem = (props) => {

    const addZeroes = num => num.toLocaleString("en",{minimumFractionDigits: 2})

    const [item,setItem ] = useState(props.item)

    const handleEditCourse = (e, i) => {
        props.handleEditDetailsCallback(i)
    }

    const handleEditCourseLessons = (e, i) => {
        props.handlehandleEditCourseLessonsCallback(e, i)
    }

    const handleDeleteCourse = (e, id) => {
        props.handleDeleteDetailsCallback(id)
    }

    let lessonNumber = 0;

    if(item.category !== undefined){
        item.category.forEach(element => {
            lessonNumber = lessonNumber +  element.lessons.length
        });
    }

    const [lessonLength, setLessonNumber] = useState(lessonNumber)
   
    //Dropdown Behaviour 
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    //Modal Behaviour
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const viewCourse = () => {
        setBackdrop(true);
        setModal(true);
    };

    const convertThedate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }


    
return (
    <>
    {/* <Card className="shadow-lg rounded mb-3 position-relative" > */}
    <Card className="shadow rounded mb-5 h-100" outline>
        <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle} direction="left">
            <DropdownToggle className="" role="button" tag="span" style={ {   fontSize: '25px', position: 'absolute', zIndex:'999',right:'9px',top:'5px'}}>
                <i className="fa fa-ellipsis-v" aria-hidden="true" type="ellipsis" />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem  onClick={e => viewCourse(item)}>View </DropdownItem>
                <DropdownItem  onClick={e => handleEditCourse(e, props.index)}>Edit </DropdownItem>
                <DropdownItem  onClick={e => handleEditCourseLessons(e, item)}>Edit Lessons</DropdownItem>
                <DropdownItem onClick={e => handleDeleteCourse(e, typeof item.tpcCourseId != "undefined" ? item.tpcCourseId : item.datCourseId)}>Delete </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        <CardBody >
            {/* <Row> */}
            <Row className="h-100 d-flex flex-row flex-wrap">
                {/* <Col lg="6"> */}
                <Col lg="12" md="12" sm="12" className=" d-flex flex-column flex-nowrap">
                    {item.courseThumbnail != "" ? 
                    
                        <CardImg
                            alt="..."
                            src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-`+(typeof item.tpcCourseId != "undefined" ? 'tpc' : 'dat')+`-courses/` + item.courseThumbnail+``}
                            
                            top
                            // style={{height : "100%", objectFit: "fill", width:"100%"}}
                        />
                        
                        :

                        <CardImg
                            alt="..."
                        
                            src={require("assets/img/brand/no-image.jpg")}
                            top
                            // style={{height : "100%", objectFit: "fill", width:"100%"}}
                        />
                    }
                </Col>
                {/* <Col lg="6" className=" d-flex flex-column flex-wrap"> */}
                <Col lg="12" md="12" sm="12" className="d-flex flex-column flex-wrap">
                    <div className="mb-auto pt-3 pb-1 px-2">
                        <h3 role="button" onClick={e => viewCourse(item)} >{item.courseTitle}</h3>
                        {/* <small className="mr-3"><b>Author:</b> {item.instructorName} </small>
                        <small onClick={e => handleEditCourseLessons(e, item)} role="button" className="text-primary">
                            <u>{item.category !== undefined ? item.category[0].lessons.length : 0} Lessons</u>
                        </small> */}
                        
                        

                        {/* <small>
                            { 
                                item.courseDescription.length > 99 ?
                                <>
                                    {item.courseDescription.slice(0, 90) } <span className="text-primary " role="button" > ...read more </span>
                                </>
                                : item.courseDescription
                            }
                            
                            
                        </small> */}
                    </div>
                    <div className="mb-1  px-2">
                        <div>
                            <Badge color={item.visibility == "published" ? "info" : "danger"} className="mr-3">{item.visibility}</Badge>
                            <Badge color="info">{item.members}</Badge>
                        </div>
                        
                        {item.coursePrice? 
                            <Button
                                color="primary"
                                className="mt-4 w-100 font-weight-bolder"
                                // onClick={e => handleStartCourse(e, item)}
                        
                                >
                                
                                ${addZeroes(Number(item.coursePrice))}
                            </Button>
                        : ""}

                        <div className="mt-2 px-1">
                            <small className="mr-3">{item.category !== undefined ? lessonLength : 0} Lessons</small>
                            <small className="mr-3">By: {item.instructorName} </small>
                        </div>
                      
                        
                        
                    </div>
                    
                </Col>
            </Row>
        </CardBody>
    </Card>
    <Modal
        isOpen={modal}
        toggle={modalToggle}
        backdrop={backdrop}
        size="xl"
        style={{width: '100%'}}
            
          
    >
        <ModalHeader toggle={modalToggle}>
                {item.courseTitle}
        </ModalHeader>
          <ModalBody className=""  >
            <Row>
                <Col md="4">
                    <Card className="mb-1">
                        {item.instructorHeadshot != "" ? 
                    
                            <CardImg
                                alt="..."
                                src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + item.instructorHeadshot+``}
                                
                                top
                                style={{height : "100%", objectFit: "fill", width:"100%"}}
                            />
                            
                            :

                            <CardImg
                                alt="..."
                            
                                src={require("assets/img/brand/no-image.jpg")}
                                top
                                style={{height : "100%", objectFit: "fill", width:"100%"}}
                            />
                        }
                        <CardBody>
                        <CardTitle tag="h3">{item.instructorName}</CardTitle>
                        <CardSubtitle tag="h6" className="mb-2 text-muted">Course Instructor</CardSubtitle>
                        <CardText>{item.instructorBio ? item.instructorBio : '"Instructor Bio Goes Here"' }</CardText>
                      
                        </CardBody>

                    </Card>
                </Col>
                <Col md="8">
                    <Card>
                        {/* {item.courseThumbnail != "" ? 
                    
                            <CardImg
                                alt="..."
                                src={`https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + item.courseThumbnail+``}
                                
                                top
                                style={{ objectFit: "fill", width:"100%"}}
                            />
                            
                            :

                            <CardImg
                                alt="..."
                            
                                src={require("assets/img/brand/no-image.jpg")}
                                top
                                style={{ objectFit: "fill", width:"100%"}}
                            />
                        } */}
                        <CardBody>
                            <CardTitle tag="h1">{item.courseTitle} <Button color="primary" className="float-right rounded-pill" >${ addZeroes(Number(item.coursePrice))}</Button></CardTitle>
                            <Badge color={item.visibility == "published" ? "info" : "danger"} className="mr-3">{item.visibility}</Badge>
                            <Badge color="primary">For {item.members}</Badge>
                            <CardText className="mt-3">{item.courseDescription ? item.courseDescription : '"Course Description Goes Here"' }</CardText>

                            <CardSubtitle tag="h6" className="mt-4 text-muted">Last Update {convertThedate(item.updatedAt)}</CardSubtitle>

                        {

                          
                            typeof item.category !== "undefined" ? 
                            //   console.log( item.category)
                                item.category.map((category, c) => {
                                    return <CourseItemDetails key={c} item={category} index={c+1}/> 
                                })
                            : "No Lessons"

                        }
                          

                        </CardBody>

                    </Card>

                </Col>
            </Row>
         
          </ModalBody>
       
        </Modal>

    </>
)


}

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(ManageCourseItem);