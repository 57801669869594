/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Table  } from "reactstrap";
import { fetchGrade } from "redux/Grades/GradesActions";
import { fetchCourse } from 'redux/Courses/CoursesActions';
const GpaGraph = (props) => {

    const dispatch = useDispatch();
    // useEffect(() => {
    //     const userDetails = props.userDetails;
    //     // dispatch(fetchGrade({handle : userDetails.handle}));
        
    // }, [props.userDetails])

    const [grade, setGrade] = useState([]) 

    useEffect(() => {
        const data = {
            route : "",
            userId : props.userDetails.userId,
            handle:props.userDetails.handle
        }
        
        dispatch(fetchCourse(data))
        
    }, [props.userDetails])

    const getGradesByCoursesAndSection = (courses) =>{
        return calculateGrades(["freshman", "sophomore", "junior", "senior", "postbac", "graduate", 'cumulative'], courses)
    }

    useEffect(() => {
            
        if(props.fetchCourses.courses.length){
            let grades = getGradesByCoursesAndSection(props.fetchCourses.courses)
            // console.log(grades)
            setGrade([grades])
        }
        
    }, [props.fetchCourses])

    const calculateGrades = (sections, courses) =>{

        return sections.reduce((accum, section, i)=>{
            let sectionCourses = sections[i]!=='cumulative'? courses.filter(c => c.section === section) : courses;
            let reducedCourses = convertCourses(sectionCourses);
    
            let science = reducedCourses.filter(c => c.bcpm === true)
            let other = reducedCourses.filter(c => c.bcpm === false)
    
            //Sum of Credit Hours
            let scienceCredits = getTotalCredits(science)
            let otherCredits = getTotalCredits(other)
            let sumCredits = parseFloat(scienceCredits) + parseFloat(otherCredits)
    
            let sectionTotal = getTotalQualityPoints(reducedCourses)/getTotalCredits(reducedCourses) || 0
            let sectionScience = getTotalQualityPoints(science)/getTotalCredits(science) || 0
            let sectionOther = getTotalQualityPoints(other)/getTotalCredits(other) || 0
    
            accum[section+"Total"] = parseFloat(sectionTotal.toFixed(2))
            accum[section+"Science"] = parseFloat(sectionScience.toFixed(2))
            accum[section+"Other"] = parseFloat(sectionOther.toFixed(2))
            accum[section+"ScienceCredits"] = parseFloat(scienceCredits.toFixed(2))
            accum[section+"OtherCredits"] = parseFloat(otherCredits.toFixed(2))
            accum[section+"TotalCredits"] = parseFloat(sumCredits.toFixed(2))
    
            return accum
    
        }, {})
    }

    const convertGPA = (grade) => {
        let conversion = {
            'A+': 4.0,
            'A': 4.0,
            'A-': 3.7,
            'AB': 3.5,
            'B+': 3.3,
            'B': 3.0,
            'B-': 2.7,
            'BC': 2.5,
            'C+': 2.3,
            'C': 2.0,
            'C-': 1.7,
            'CD': 1.5,
            'D+': 1.3,
            'D': 1.0,
            'D-': 0.7,
            'DE': 0.5,
            'F': 0.0
        }
    
        return conversion[grade]
    }
    
    const convertCredits = (credit) => {
        switch (credit){
            case 0.5: return 0.3
            case 1.0: return 0.7
            case 1.5: return 1.0
            case 2.0: return 1.3
            case 2.5: return 1.7
            case 3.0: return 2.0
            case 3.5: return 2.3
            case 4.0: return 2.7
            case 4.5: return 3.0
            case 5.0: return 3.3
            case 6.0: return 4.0
            case 7.0: return 4.7
            case 8.0: return 5.3
            case 9.0: return 6.0
            case 10.0: return 6.7
            case 12.0: return 8.0
            case 15.0: return 10.0
            case 20.0: return 13.3
            default: return credit
        }
    }

    const getTotalQualityPoints = (courses) => {
        return courses.reduce((accum, curr) => accum + curr.qualityPoints, 0)
    }
    
    const getTotalCredits = (courses) => {
        return courses.reduce((accum, curr) => accum + curr.credits, 0)
    }

    const convertCourses = (courses) =>{
        let PFTW = ["Pass", "Fail", "Transfer", "Withdraw"]
        let coursesLessPFT = courses.filter(course => !PFTW.includes(course.grade))
        return coursesLessPFT.map(course =>{
            const credits = course.quarter? convertCredits(parseFloat(course.credits)): parseFloat(course.credits)
            return {
                bcpm:course.bcpm,
                qualityPoints: convertGPA(course.grade) * credits,
                credits: credits
            }
        })
    }

    const sections = ["freshman", "sophomore", "junior", "senior", "postbac", "graduate", 'cumulative']

  return (
    <>
        <Card className="card-profile shadow">
            <CardBody >
                <CardTitle  tag="h3" className="text-uppercase text-muted mb-3">
                 
                </CardTitle>
                <div className="table-responsive ">
                    <Table  bordered hover>
                        <thead className="">
                            <tr>
                                <td  colspan="9" className="align-middle text-center">
                                    <h5>Estimated AMCAS GPA Breakdown</h5>
                                </td>
                            </tr>
                            <tr>
                               
                                <th colspan="3" rowspan="2" className="align-middle"><h5>Status</h5></th>
                                <th colspan="2" className="text-center align-middle "><h5>BCPM</h5></th>
                                <th colspan="2" className="text-center align-middle"><h5>AO</h5></th>
                                <th colspan="2" className="text-center align-middle"><h5>Total</h5></th>
                            </tr>
                            <tr>
                                <th className="text-center"><b>GPA</b></th>
                                <th className="text-center"><b>Hours</b></th>
                                <th className="text-center"><b>GPA</b></th>
                                <th className="text-center"><b>Hours</b></th>
                                <th className="text-center"><b>GPA</b></th>
                                <th className="text-center"><b>Hours</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                grade.length ? 
                              
                              
                                    sections.map((section,i) =>
                                        {
                                            let bcpm_gpa = section+"Science"
                                            let bcpm_credit = section+"ScienceCredits"
                                            let bcpm_total = section+"Total"
                                            
                                            let ao_gpa = section+"Other"
                                            let ao_credit = section+"OtherCredits"
                                            let total_credit = section+"TotalCredits"

                                        return(
                                            <tr>
                                                <td className="text-uppercase align-baseline " colspan="3" >{section}</td>
                                                <td className="text-center">{grade[0][bcpm_gpa] !== 0 ? grade[0][bcpm_gpa].toFixed(2): ""}</td>
                                                <td className="text-center">{typeof grade[0][bcpm_credit] !== "undefined" ? grade[0][bcpm_credit] !== 0 ? grade[0][bcpm_credit].toFixed(2) : "" : ""}</td>
                                                <td className="text-center">{grade[0][ao_gpa] !== 0 ? grade[0][ao_gpa].toFixed(2): "" }</td>
                                                <td className="text-center">{typeof grade[0][ao_credit] !== "undefined" ? grade[0][ao_credit] !== 0 ? grade[0][ao_credit].toFixed(2) : "": ""}</td>
                                                <td className="text-center">{grade[0][bcpm_total] !== 0 ? grade[0][bcpm_total].toFixed(2) : ""}</td>
                                                <td className="text-center">{typeof grade[0][total_credit] !== "undefined" ? grade[0][total_credit] !== 0 ? grade[0][total_credit].toFixed(2) : "": "" }</td>
                                            </tr>
                                        )
                                        
                                        }
                                    )
                                      
                                : ""

                            }
                           
                        </tbody>
                    </Table>
                    <Table bordered className="mt-3">
                        <tbody>
                            <tr>
                                <th>Supplemental Hours</th>
                                <th>P/F-Pass: </th>
                                <th>P/F-Fail: </th>
                                <th>A/P: </th>
                                <th>CLEP: </th>
                                <th>Other: </th>
                            </tr>
                        </tbody>
                    </Table>   
                    
                    <Table borderless className="mt-3">
                        <tbody>
                            <tr>
                                <td className="align-baseline"><h3>BCPM: </h3></td>
                                <td className="align-baseline"><b>Biology, Chemistry, Physics, and Math</b></td>
                            </tr>
                            <tr>
                                <td className="align-baseline"><h3>AO: </h3></td>
                                <td className="align-baseline"><b>All other classes not classified as biology, chemistry, physics, or math</b></td>
                            </tr>
                        </tbody>   
                    </Table>

                </div>
            </CardBody>
        </Card>
         
    </>
  );
};

const mapStateToProps = state => {
  return {
    fetchGrade : state.fetchGrade,
    fetchCourses : state.fetchCourses,
    userDetails : state.loginAuth.userDetails,
  }
}

export default connect(mapStateToProps)(GpaGraph);
