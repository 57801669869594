import React, { useState, useEffect } from "react";

import {
  Card,
  CardTitle,
  CardText,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Pagination,
  FormGroup,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Tooltip,
  Alert,
  Form,
  Label
} from "reactstrap";
// import moment from 'moment';
import ReactDatetime from "react-datetime";
import { useDispatch } from "react-redux";
import { fetchAvailableSchedule } from "redux/ScheduleTutor/ScheduleTutorActions";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import {
  fetchRequestTutor,
  cancelRequestTutor,
  cancelRequestTutorReset,
  fetchRequestTutorHistory,
} from "redux/ScheduleTutor/ScheduleTutorActions";
import { app, db } from "../../firebase";
import {
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
  or,
} from "firebase/firestore";
import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import Select from "react-select";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
const RequestsTable = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [itemDetails, setItemDetails] = useState();

  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const showRequestDetails = (e,item) => {
    console.log(item)
    setItemDetails(item)
    setBackdrop(true);
    setModal(true);
  };

  useEffect(() => {
   dispatch(fetchRequestTutor({ studentHandle: props.userDetails.handle }));
  }, []);

  useEffect(() => {
    if (props.fetchRequestTutor.fetchrequesttutor.length) {
      const collect = [];
      props.fetchRequestTutor.fetchrequesttutor[0].map((v) => {
       
        if (
          // moment(v.time).utcOffset(0, false).format() >
          // moment(new Date()).tz("America/New_York").format()
          ["pending", "approved","disapproved"].includes(v.status)
        ) {
          collect.push(v);
        }
      });
      setData(collect);
      setFilter(collect);
    }
  }, [props.fetchRequestTutor.fetchrequesttutor]);

  const handleCancelRequestOnClick = (e, doc) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        doc.date =  moment(doc.starttime).format('ll') != moment(doc.endtime).format('ll') ? moment(doc.starttime).format('ll')+" - "+moment(doc.endtime).format('ll'):  moment(doc.starttime).format('ll')
        doc.time= moment(doc.starttime).format('h:mm A')+ "-"+ moment(doc.endtime).format('h:mm A') 
        dispatch(cancelRequestTutor({ handle: props.userDetails, docs: doc }));
      }
    });
  };

     useEffect(() => {
    if (props.cancelRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (props.cancelRequestTutor.cancelrequesttutor.length) {
        Swal.fire({
          title: "Success!",
          text: "Successfully cancelled request!",
          icon: "success",
          timer: 3000,
        }).then(function (result) {
      
          dispatch(fetchRequestTutor({ studentHandle: props.userDetails.handle }));
          dispatch(
            fetchRequestTutorHistory({
              studentHandle: props.userDetails.handle,
            })
          );
          dispatch(cancelRequestTutorReset());
        });
      } else if (typeof props.cancelRequestTutor.error !== "undefined") {
        Swal.fire({
          title: "Failed!",
          text: "Failed to cancel request",
          icon: "error",
          timer: 3000,
        }).then(function (result) {
          //   dispatch(fetchCoachTutorAvailability({ handle : props.userDetails.handle}))
          dispatch(
            fetchRequestTutor({ studentHandle: props.userDetails.handle })
          );
          dispatch(
            fetchRequestTutorHistory({
              studentHandle: props.userDetails.handle,
            })
          );
          dispatch(cancelRequestTutorReset());
        });
      }
    }
  }, [props.cancelRequestTutor]);

  // const convertTime = (item) => {
  //   return item.type && item.type == "free"
  //     ? moment(new Date(item.time)).utcOffset(0, false).format("h:00 A") +
  //         "-" +
  //         moment(new Date(item.time))
  //           .utcOffset(0, false)
  //           .add(30, "minutes")
  //           .format("LT")
  //     : moment(new Date(item.time)).utcOffset(0, false).format("h:00 A") +
  //         "-" +
  //         moment(new Date(item.time))
  //           .utcOffset(0, false)
  //           .add(1, "hours")
  //           .format("h:00 A");
  // };

  const convertDate = (item) => {
    return moment(item).utcOffset(0, false).format("YYYY-MM-DD");
  };

   const convertTimeToUTC = (time) => {
        return moment(moment(time).utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

  const columns = [
     {
    name: "Coach",
    wrap: "true",
    selector: (row) => {
      return(
          <Media className="align-items-center">  
            {
              typeof row.coach.profilePicture != "undefined" ? (
              <div className="card-profile-div-sm card-profile-div-md">
                <div className="card-profile-image">
                  <div
                    className="profile-picture-sm profile-picture-md"
                    style={{
                    backgroundImage:
                      'url("https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/' +
                      row.coach.profilePicture +
                      '")',
                    }}
                  ></div>
                </div>
              </div>
              ) : 
              (
              <div className="rounded-circle profile-letter-all-users mr-2" 
                style={{
                    width: "30px",
                    height: "30px",
                    fontSize: "16px"
                  }}>
                  {row.coach.name.trim()[0].toUpperCase() }
                </div>
              )
            }
            <span className="mb-0 text-sm">{row.coach.name}</span>
          </Media>
        )
      },
        width: '200px'
	  },
    {
      name: "Date",
      wrap: "true",
      selector: (row) => {
        return moment(row.starttime).format('ll') != moment(row.endtime).format('ll') ? moment(row.starttime).format('ll')+" - "+moment(row.endtime).format('ll'):  moment(row.starttime).format('ll')
      },
    },
    {
      name: "Type",
      selector: (row) => {
        return  row.requestType.toUpperCase() 
      },
      wrap: true,
    },
     {
      name: "Duration",
      selector: (row) => {
        return  row.duration 
      },
      wrap: true,
    },
     {
      name: "Time",
      wrap: true,
      selector: (row) => {
        return convertTimeToLocal(row.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(row.endtime).format("h:mm A")
      },
    
    },
    {
      name: "Status",
      selector: (row) => (
        <Badge color="" className="badge-dot mr-4">
          <i
            className={
              row.status == "pending"
                ? "bg-info"
                : row.status == "approved"
                ? "bg-success"
                : row.status == "disapproved"
                ? "bg-danger"
                : "bg-warning"
            }
          />
          {row.status}
        </Badge>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {row.status == "approved" ? (
            <a
              href={row.studentMeetingLink}
              target="__blank"
              className="btn btn-sm btn-primary"
            >
              Join Zoom Meeting
            </a>
          ) :  row.status == "disapproved" ? (
          
              <button
               onClick={(e) => showRequestDetails(e, row)}
                className="btn btn-sm btn-primary"
              >
               View Details
              </button>
           
          ) :  
              <button
                onClick={(e) => handleCancelRequestOnClick(e, row)}
                className="btn btn-sm btn-danger"
              >
                Cancel Request
              </button>
            
        }
        </>
      ),

      center: true,
    },
  ];

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        // backgroundColor: "#ccc",
      },
    },
  };

  useEffect(() => {
    console.log(data)
    const result = data.filter((item) => {
      return (
        item.coach.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.requestType.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.duration.toLowerCase().match(search.toLocaleLowerCase()) ||
        convertDate(item.date).toLowerCase().match(search.toLocaleLowerCase()) ||
        item.starttime.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.endtime.toLowerCase().match(search.toLocaleLowerCase()) ||
        item.status.toLowerCase().match(search.toLocaleLowerCase()) 
        
      );
    });
    console.log(result)
    setFilter(result);
  }, [search]);

  return (
    <>
    <Card className="shadow">
      <CardHeader className="border-0 h3">MANAGE REQUESTS</CardHeader>
      <CardBody>
        {data.length ? (
          
          <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            wrap
            // selectableRows
            // fixedHeader
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="w-50 form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign="right"
          />
        ) : (
          <Alert color="primary">No data available .</Alert>
        )}
      </CardBody>
    </Card>
     <Modal
        isOpen={modal}
        toggle={modalToggle}
        backdrop={backdrop}
        
        centered
      >
        <ModalHeader toggle={modalToggle}>
        {'Schedule Id: '} {itemDetails ? itemDetails.docId : ""}
        </ModalHeader>
        <ModalBody className="pt-1">
          {
            itemDetails ? 
            <>
            <Row>
              <Col lg="2">
                Coach: 
              </Col>
              <Col lg="10">
                {itemDetails.coach.name }
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="2">
                Date: 
              </Col>
              <Col lg="10">
                {convertDate(itemDetails.date)}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="2">
                Time: 
              </Col>
              <Col lg="10">
                {itemDetails.starttime + ' - ' + itemDetails.endtime}
              </Col>
            </Row>
              <Row className="mt-2">
              <Col lg="2">
                Type: 
              </Col>
              <Col lg="10">
                {itemDetails.requestType.toUpperCase()}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="2">
                Duration: 
              </Col>
              <Col lg="10">
                {itemDetails.duration}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="2">
                Status: 
              </Col>
              <Col lg="10">
                
                  <Badge color="" className="badge-dot mr-4">
                  <i
                    className={
                      itemDetails.status == "pending"
                        ? "bg-info"
                        : itemDetails.status == "approved"
                        ? "bg-success"
                        : itemDetails.status == "disapproved"
                        ? "bg-danger"
                        : "bg-warning"
                    }
                  />
                {itemDetails.status}
                </Badge> 
              </Col>
            </Row>
         
            </>
            : ""
            }

        </ModalBody>
      
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchRequestTutor: state.fetchRequestTutor,
    cancelRequestTutor: state.cancelRequestTutor,
  };
};

export default connect(mapStateToProps)(RequestsTable);
