export const CREATE_WORK_ACTIVITY_REQUEST = 'CREATE_WORK_ACTIVITY_REQUEST';
export const CREATE_WORK_ACTIVITY_SUCCESS = 'CREATE_WORK_ACTIVITY_SUCCESS';
export const CREATE_WORK_ACTIVITY_FAILURE = 'CREATE_WORK_ACTIVITY_FAILURE';
export const CREATE_WORK_ACTIVITY_RESET = 'CREATE_WORK_ACTIVITY_RESET';

export const FETCH_WORK_ACTIVITIES_REQUEST = 'FETCH_WORK_ACTIVITIES_REQUEST';
export const FETCH_WORK_ACTIVITIES_SUCCESS = 'FETCH_WORK_ACTIVITIES_SUCCESS';
export const FETCH_WORK_ACTIVITIES_FAILURE = 'FETCH_WORK_ACTIVITIES_FAILURE';

export const UPDATE_WORK_ACTIVITY_REQUEST = 'UPDATE_WORK_ACTIVITY_REQUEST';
export const UPDATE_WORK_ACTIVITY_SUCCESS = 'UPDATE_WORK_ACTIVITY_SUCCESS';
export const UPDATE_WORK_ACTIVITY_FAILURE = 'UPDATE_WORK_ACTIVITY_FAILURE';
export const UPDATE_WORK_ACTIVITY_RESET = 'UPDATE_WORK_ACTIVITY_RESET';

export const OPEN_WORK_ACTIVITY_MODAL = 'OPEN_WORK_ACTIVITY_MODAL'
export const CLOSE_WORK_ACTIVITY_MODAL = 'CLOSE_WORK_ACTIVITY_MODAL'

export const EDIT_WORK_ACTIVITY = 'EDIT_WORK_ACTIVITY';
export const NEW_WORK_ACTIVITY = 'NEW_WORK_ACTIVITY';


export const DELETE_WORK_ACTIVITY_REQUEST = 'DELETE_WORK_ACTIVITY_REQUEST';
export const DELETE_WORK_ACTIVITY_SUCCESS = 'DELETE_WORK_ACTIVITY_SUCCESS';
export const DELETE_WORK_ACTIVITY_FAILURE = 'DELETE_WORK_ACTIVITY_FAILURE';
export const DELETE_WORK_ACTIVITY_RESET = 'DELETE_WORK_ACTIVITY_RESET';