import React, { useEffect, useState }  from 'react'
import {

    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table

} from "reactstrap";

const SchoolMDModal = (props) => {

    let [applicationDeadlineDay, setApplicationDeadlineDay] = useState(null);
    let [applicationDeadlineMonth, setApplicationDeadlineMonth] = useState(null);
    let [applicationDeadlineYear, setApplicationDeadlineYear] = useState(null);

    let [classesBeginDay, setClassesBeginDay] = useState(null);
    let [classesBeginMonth, setClassesBeginMonth] = useState(null);
    let [classesBeginYear, setClassesBeginYear] = useState(null);

    const combinedDegreesFormat = {
        "COMB_MDMBA" : "Combined medical degree/M.B.A.",
        "COMB_MDJD" : "Combined medical degree/J.D.",
        "COMB_MDMPP" : "Combined medical degree/M.P.P.",
        "COMB_MDPHD" : "Combined medical degree/Ph.D.",
        "COMB_MDMPH" : "Combined medical degree/M.P.H."
    }

    const specialProgramFormat = {
        "SPECIAL_SUMMER" : "Summer programs",
        "SPECIAL_POSTBAC" : "Postbaccalaureate program",
        "SPECIAL_ADDL_INFO" : "Special programs - additional information",
    }

    const matriculationDataFormat = {
        "APPLIED" : "Verified Applications",
        "INTERVIEWED" : "Interviewed",
        "DEFERRED" : "Deferred",
        "EAP" : "Early Assurance Program",
        "EDP" : "Early Decision Program",
        "BACMD" : "Baccaluareate/MD",
        "MDPHD" : "MD/PhD",
        "MATRIC" : "Matriculated",
    }

    useEffect(() => {

        let applicationDeadlineDate = new Date(props.medicalSchoolDetails.overview.priApplLatestDtText);

        setApplicationDeadlineDay(applicationDeadlineDate.getDate());
        setApplicationDeadlineMonth(applicationDeadlineDate.toLocaleString('default', { month: 'long' }));
        setApplicationDeadlineYear(applicationDeadlineDate.getFullYear());

        let classesBeginDate = new Date(props.medicalSchoolDetails.medSchoolInformation.startMonthYear);

        setClassesBeginDay(classesBeginDate.getDate());
        setClassesBeginMonth(classesBeginDate.toLocaleString('default', { month: 'long' }));
        setClassesBeginYear(classesBeginDate.getFullYear());

    }, [])

    const formatterUSD = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    
      const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }


    const addDots = (input) => {
        return input.split('').join('.');
    }

    const appliedData = props.medicalSchoolDetails.medSchoolMatDatas.find(data => data.matType === 'APPLIED');
    const matriculatedData = props.medicalSchoolDetails.medSchoolMatDatas.find(data => data.matType === 'MATRIC');
    const interviewedData = props.medicalSchoolDetails.medSchoolMatDatas.find(data => data.matType === 'INTERVIEWED');
  return (
    <>
        <Col lg="12 text-center mb-3"><h2 className="text-warning">DETAILS</h2></Col>

        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder ">Application Deadline : <span className="font-weight-normal">{applicationDeadlineMonth} {applicationDeadlineDay}, {applicationDeadlineYear}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder ">Institution : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.publicPrivate == "I" ? "Private" : props.medicalSchoolDetails.overview.publicPrivate == "P" ? "Public" : "Not Provided"}</span></h3></Col>
        
        <Col lg="1"></Col>

        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder ">In-state annual tuition: <span className="font-weight-normal">{formatterUSD.format(props.medicalSchoolDetails.overview.tuitionAndFeesRes)}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder ">Out-state annual tuition: <span className="font-weight-normal">{formatterUSD.format(props.medicalSchoolDetails.overview.tuitionAndFeesNonres)}</span></h3></Col>
        <Col lg="1"></Col>

        <Col lg="1"></Col>
        <Col lg="5"><h3 className="font-weight-bolder ">Mean GPA : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg && props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg != "0.00" ? props.medicalSchoolDetails.medSchoolMatDemo.gpaAcceptedTotalAvg : "Not Available"}</span></h3></Col>
        <Col lg="5"><h3 className="font-weight-bolder ">Mean MCAT : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg && props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg != "0.0" ? props.medicalSchoolDetails.medSchoolMatDemo.mcatAcceptedTotalAvg : "Not Available"}</span></h3></Col>
        <Col lg="1"></Col>

        <Col lg="1"></Col>
        
        <Col lg="5"><h3 className="font-weight-bolder ">Require CASPer : <span className="font-weight-normal">{props.medicalSchoolDetails.requireCasper == "Y"? <label className='text-info'>{"Yes"}</label> : "Not Provided"}</span></h3></Col>
        <Col lg="1"></Col>

        <Col md="12 mt-4">
            <hr></hr>
            <label className="font-weight-bolder mb-3 text-primary">GENERAL INFORMATION</label>

            <Row>

                <Col lg="1"></Col>
                <Col lg="5"><h3 className="font-weight-bolder ">Year Founded : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolInformation.yearFounded ? props.medicalSchoolDetails.medSchoolInformation.yearFounded : "Not Provided"}</span></h3></Col>
                <Col lg="5"><h3 className="font-weight-bolder ">Campus Type : <span className="font-weight-normal">{props.medicalSchoolDetails.overview.campusType}</span></h3></Col>
                <Col lg="1"></Col>

                <Col lg="1"></Col>
                <Col lg="5"><h3 className="font-weight-bolder ">Total enrollment : <span className="font-weight-normal">{props.medicalSchoolDetails.medSchoolInformation.totalEnrollment}</span></h3></Col>
                <Col lg="5"><h3 className="font-weight-bolder ">Class begin : <span className="font-weight-normal">{classesBeginMonth} {classesBeginDay}, {classesBeginYear}</span></h3></Col>
                <Col lg="1"></Col>
        
            </Row>
        </Col>


        <Col md="12 mt-4">
            <hr></hr>
            <label className="font-weight-bolder mb-3 text-primary">COMBINED DEGREE PROGRAMS</label>

            <Row>

                <Col lg="12">
                    <h3 className="font-weight-bolder ">Baccalaureate/MD Program</h3>
                    {props.medicalSchoolDetails.medSchoolInformation.programUndergradCollege ? 
                        <>
                            <p className='font-weight-600 mb-0 text-default'>Undergraduate college(s) where baccalaureate degree will be completed</p>
                            <p>{props.medicalSchoolDetails.medSchoolInformation.programUndergradCollege}</p>
                            <p className='font-weight-600 mb-0 text-default'>About this program</p>
                            <p>{props.medicalSchoolDetails.medSchoolInformation.programAbout}</p>

                            <p className='font-weight-600 mb-0 text-default'>Website for further Information</p>
                            <p><a href={props.medicalSchoolDetails.medSchoolInformation.programWebSite} className='text-info'>{props.medicalSchoolDetails.medSchoolInformation.programWebSite}</a></p>
                        </>
                    : <p>Not Available</p>}
                    <h3 className="font-weight-bolder ">Graduate/MD Program</h3>
                    
                    <Row>
                        {props.medicalSchoolDetails.medSchoolPrograms.map((value, index) => {

                                if(value.programType.includes("COMB_MD") && value.programType != "COMB_MDGRAD"){
                                    return  <Col lg="6" className='mb-4'>

                                            <p className='font-weight-600 mb-0 text-default'>{combinedDegreesFormat[value.programType]}</p>
                                            {value.yn == "Y" ? <>
                                                <a  href={value.url} className='text-info' target='_blank'><i className="fa fa-globe"></i> Program Website</a>
                                                <p className='mb-1 text-default'><i class="fas fa-user-circle"></i> {value.cname}</p>
                                                <p className='mb-1 text-default'><i class="fas fa-phone"></i> {formatPhoneNumber(value.phone)}</p>
                                                <a href={"mailto:"+value.email} className='text-info'><i class="fas fa-envelope"></i> {value.email}</a>
                                                </>
                                            : "Not Available"}


                                        </Col>
                                }

                                if(value.programType == "COMB_OTHER1"){
                                    return   <Col lg="12" className='mb-4'><p className='font-weight-600 mb-0 text-default'>Combined degree programs - additional information</p>
                                            
                                                {value.yn == "Y" ? <>
                                            <p className='mb-1 text-default'>{value.info}</p>
                                            <a  href={value.url} className='text-info' target='_blank'> {value.url}</a>
                                            </>
                                            : "Not Available"}
                                        </Col>
                                }

                            }

                            
                        )}
                        
                    </Row>


                    <h3 className="font-weight-bolder ">Special Program</h3>

                    <Row>
                        {props.medicalSchoolDetails.medSchoolPrograms.map((value, index) => {

                                if(value.programType == "SPECIAL_SUMMER" || value.programType == "SPECIAL_POSTBAC"){
                                    return  <Col lg="6" className='mb-4'>

                                            <p className='font-weight-600 mb-0 text-default'>{specialProgramFormat[value.programType]}</p>
                                            {value.yn == "Y" ? <>
                                                <a  href={value.url} className='text-info' target='_blank'><i className="fa fa-globe"></i> Program Website</a>
                                                <p className='mb-1 text-default'><i class="fas fa-user-circle"></i> {value.cname}</p>
                                                <p className='mb-1 text-default'><i class="fas fa-phone"></i> {formatPhoneNumber(value.phone)}</p>
                                                <a href={"mailto:"+value.email} className='text-info'><i class="fas fa-envelope"></i> {value.email}</a>
                                                </>
                                            : "Not Available"}


                                        </Col>
                                }

                                if(value.programType == "SPECIAL_ADDL_INFO"){
                                    return   <Col lg="12" className='mb-4'><p className='font-weight-600 mb-0 text-default'>{specialProgramFormat[value.programType]}</p>
                                            
                                                {value.yn == "Y" ? <>
                                            <p className='mb-1 text-default'>{value.info}</p>
                                            <a  href={value.url} className='text-info' target='_blank'> {value.url}</a>
                                            </>
                                            : "Not Available"}
                                        </Col>
                                }

                            }

                            
                        )}
                        
                    </Row>

                </Col>
        
            </Row>
        </Col>

        <Col md="12 mt-4">
            <hr></hr>
            <label className="font-weight-bolder mb-3 text-primary">MISSION</label>

            <Row>

                <Col lg="12">
                    <h3 className="font-weight-bolder ">Mission statement</h3>
                    <p>{props.medicalSchoolDetails.medSchoolInformation.missionStatement}</p>

                    <h3 className="font-weight-bolder ">General information</h3>
                    <p>{props.medicalSchoolDetails.medSchoolInformation.generalInformation}</p>

                    <h3 className="font-weight-bolder ">Medical school’s welcome or introductory video</h3>
                    <p><a href={props.medicalSchoolDetails.medSchoolInformation.introductoryVideoUrl} className='text-info'>{props.medicalSchoolDetails.medSchoolInformation.introductoryVideoUrl}</a></p>
                    
                </Col>

            </Row>
        </Col>
        
        <Col md="12 mt-4">
            <hr></hr>
            <label className="font-weight-bolder mb-3 text-primary">INTERVIEW INFORMATION</label>

            <Row>

                <Col lg="6">
                    <h3 className="font-weight-bolder ">Interview invitations sent, beginning</h3>
                    <p>{props.medicalSchoolDetails.medSchoolApplication.interviewInvitations}</p>

                </Col>
                <Col lg="6">
                    <h3 className="font-weight-bolder ">Availability of regional interviews</h3>
                    <p>{props.medicalSchoolDetails.medSchoolApplication.regionalInterview}</p>

                </Col>

                <Col lg="6">
                    <h3 className="font-weight-bolder ">Interview format</h3>
                    <p>{props.medicalSchoolDetails.medSchoolApplication.interviewFormat}</p>

                </Col>

                <Col lg="6">
                    <h3 className="font-weight-bolder ">Interview of video interview</h3>
                    <p>{props.medicalSchoolDetails.medSchoolApplication.videoInterview}</p>

                </Col>

                <Col lg="12">
                    <h3 className="font-weight-bolder ">Interviewed</h3>
                    
                    {props.medicalSchoolDetails.medSchoolMatDatas.map((value, index) => {

                            if(value.matType == "INTERVIEWED" ){
                                return  <>

                                        <p className='font-weight-600 mb-1'>In-state : {value.res}</p>
                                        <p className='font-weight-600 mb-1'>Out-of-state : {value.nonres}</p>
                                        <p className='font-weight-600 mb-1'>International : {value.international}</p>
                                        <p className='font-weight-600 mb-1'>Total : {value.total}</p>


                                    </>
                            }

                        }

                    )}
                </Col>

                <Col lg="12 mt-3">
                    <h3 className="font-weight-bolder ">Information about interview day</h3>
                    <p>{props.medicalSchoolDetails.medSchoolApplication.typicalInterviewDay}</p>

                </Col>

            </Row>
        </Col>


        <Col md="12 mt-4">
            <hr></hr>
            <label className="font-weight-bolder mb-3 text-primary">REQUIRED / RECOMMENDED PREMEDICAL COURSEWORK</label>

            <Row>

                <Col md="12 mt-3 table-responsive">

                    <Table className="align-items-center rounded shadow" striped bordered>
                        <thead className="text-default">
                            
                            
                            <tr>
                                <th>Courses</th>
                                <th>Required/Recommended</th>
                                <th>Lab</th>
                                <th>Credit Hours</th>
                                <th>Pass/Fail Accepted*</th>
                                <th>AP Accepted?</th>
                                <th>Online Accepted?</th>
                                <th>Community College Accepted?</th>
                                <th>Notes</th>
                                {/* <td>{props.medicalSchoolDetails.programDirectorInformation.name}</td> */}
                            </tr>
                                            

                        </thead> 
                        <tbody>
                      

                                {props.medicalSchoolDetails.medSchoolCourseworks.map((value, index) => {

                                        if(value.displayCourse == "Y"){
                                            return  <tr>

                                                <td className='font-weight-600 mb-1'>{value.courseworkDisplayName}</td>
                                                <td className='font-weight-600 mb-1'>{value.courseworkNeeded == "Required" ? "Required" : ""}</td>
                                                <td className='font-weight-600 mb-1 text-center'>{value.courseworkLab == null ? "" : value.courseworkLab == "Y" ? <i class="fas fa-check-circle text-success"></i> : ""}</td>
                                                <td className='font-weight-600 mb-1 text-center'>{value.courseworkHours == null ? "" : value.courseworkHours}</td>
                                                <td className='font-weight-600 mb-1 text-center'>{value.courseworkPassFailAccepted == null ? "" : value.courseworkPassFailAccepted == "Y" ? <i class="fas fa-check-circle text-success"></i> : <i class="fas fa-times-circle text-danger"></i>}</td>
                                                <td className='font-weight-600 mb-1 text-center'>{value.courseworkAPAccepted == null ? "" : value.courseworkAPAccepted == "Y" ? <i class="fas fa-check-circle text-success"></i> : <i class="fas fa-times-circle text-danger"></i>}</td>
                                                <td className='font-weight-600 mb-1 text-center'>{value.courseworkOnlineAccepted == null ? "" : value.courseworkOnlineAccepted == "Y" ? <i class="fas fa-check-circle text-success"></i> : <i class="fas fa-times-circle text-danger"></i>}</td>
                                                <td className='font-weight-600 mb-1 text-center'>{value.courseworkCommCollegeAccepted == null ? "" : value.courseworkCommCollegeAccepted == "Y" ? <i class="fas fa-check-circle text-success"></i> : <i class="fas fa-times-circle text-danger"></i>}</td>
                                                <td className='font-weight-600 mb-1'>{value.courseworkNotes}</td>


                                            </tr>
                                        }
                                        
                                        

                                    }

                                )}

                           
                        </tbody>
                    </Table>
                    

                </Col>
            </Row>
        </Col>

        <Col md="12 mt-4">
            <hr></hr>
            <label className="font-weight-bolder mb-3 text-primary">MATRICULATION DATA FOR FIRST YEAR CLASS</label>

            <Row>

                <Col md="12 mt-3 table-responsive">
                                    
                    <Table className="align-items-center rounded shadow" striped bordered>
                        <thead className="text-default">
                            
                            
                            <tr>
                                <th>Categories</th>
                                <th>In-State</th>
                                <th>Out-of-state</th>
                                <th>International</th>
                                <th>Total</th>
            
                            </tr>
                                            

                        </thead> 
                        <tbody>
                      

                                {props.medicalSchoolDetails.medSchoolMatDatas.map((value, index) => {

                                        
                                    return  <tr>

                                        <td className='font-weight-600 mb-1'>{matriculationDataFormat[value.matType]}</td>
                                        <td className='font-weight-600 mb-1'>{value.res}</td>
                                        <td className='font-weight-600 mb-1'>{value.nonres}</td>
                                        <td className='font-weight-600 mb-1'>{value.international}</td>
                                        <td className='font-weight-600 mb-1'>{value.total}</td>
                                       


                                    </tr>
                                        
                                        
                                        

                                    }

                                )}

                                <tr>

                                    <td className='font-weight-600 mb-1'>Matriculated Percentage</td>
                                    <td className='font-weight-600 mb-1'>{((matriculatedData.res/appliedData.res)*100).toFixed(2)}%</td>
                                    <td className='font-weight-600 mb-1'>{((matriculatedData.nonres/appliedData.nonres)*100).toFixed(2)}%</td>
                                    <td className='font-weight-600 mb-1'>{((matriculatedData.international/appliedData.international)*100).toFixed(2)}%</td>
                                    <td className='font-weight-600 mb-1'>{((matriculatedData.total/appliedData.total)*100).toFixed(2)}%</td>

                                </tr>

                                <tr>

                                    <td className='font-weight-600 mb-1'>Interviewed Percentage</td>
                                    <td className='font-weight-600 mb-1'>{((interviewedData.res/appliedData.res)*100).toFixed(2)}%</td>
                                    <td className='font-weight-600 mb-1'>{((interviewedData.nonres/appliedData.nonres)*100).toFixed(2)}%</td>
                                    <td className='font-weight-600 mb-1'>{((interviewedData.international/appliedData.international)*100).toFixed(2)}%</td>
                                    <td className='font-weight-600 mb-1'>{((interviewedData.total/appliedData.total)*100).toFixed(2)}%</td>

                                </tr>
                           
                        </tbody>
                    </Table>
                    

                </Col>
            </Row>
        </Col>


        {props.medicalSchoolDetails.campusList ?
            <Col md="12 mt-4">
                <hr></hr>
                <label className="font-weight-bolder mb-3 text-primary">CONTACTS</label>

                <Row>

                    <Col lg="12">
                        <p className='mb-1 text-default'>{props.medicalSchoolDetails.campusList[0].campusName}</p>
                        <p className='mb-1 text-default'>{props.medicalSchoolDetails.campusList[0].addressLabel}</p>
                        <p className='mb-1 text-default'>{props.medicalSchoolDetails.campusList[0].addressLine2}</p>
                        <p className='mb-1 text-default'>{props.medicalSchoolDetails.campusList[0].city},{props.medicalSchoolDetails.campusList[0].stateCd} {props.medicalSchoolDetails.campusList[0].postCd} {props.medicalSchoolDetails.campusList[0].countryCd}</p>
                        <p className='mb-1 text-default'><i class="fas fa-phone"></i>  {formatPhoneNumber(props.medicalSchoolDetails.campusList[0].phone)}</p>
                        <p className='mb-1 text-default'><i class="fas fa-fax"></i>  {formatPhoneNumber(props.medicalSchoolDetails.campusList[0].fax)}</p>
                        <a href={"mailto:"+props.medicalSchoolDetails.campusList[0].email} className='text-info'><i class="fas fa-envelope"></i> {props.medicalSchoolDetails.campusList[0].email}</a>
                        <br></br>
                        <a  href={props.medicalSchoolDetails.admUrl} className='text-info' target='_blank'><i className="fa fa-globe"></i> Medical School Website</a>
                    </Col>

                </Row>
            </Col>
        : "" }

        <Col lg="12 text-center mt-4">
            <a href={props.medicalSchoolDetails.admUrl} target='__blank' className='btn btn-info'>Visit Website</a>
        </Col>

    </>
  )
}

export default SchoolMDModal