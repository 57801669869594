import React, {useRef, useState, useEffect} from 'react'
import Header from 'components/Headers/Header';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    CardImg,
    CardImgOverlay,
    TabContent, TabPane, Nav, NavItem, NavLink, 
    FormGroup,
    FormFeedback, 
    FormText ,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
    Alert,
    Spinner,
    Media,
    Table
} from "reactstrap";

import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import classnames from "classnames";
import DataTable from "react-data-table-component";
import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
import ReactDatetime from "react-datetime";
import {
    fetchAllUsers,
    fetchUserDetails,
    updateUserDetails,
    updateUserDetailsReset,
} from "redux/ManageUsers/ManageUsersAction";
import {fetchAppHourUsedLogs,fetchPremiumHourLogs,sendEmailApplicationHourUsed} from "redux/Logs/LogsAction";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import Logs from "components/Logs/Logs";
import StudentFreeHourLogs from "components/Logs/StudentFreeHourLogs";
import StudentPremiumHourLogs from "components/Logs/StudentPremiumHourLogs";
import moment from "moment-timezone";

const ManageApplicationHours = (props) => {
    const dispatch = useDispatch()
    

    const [loading, setLoading] = useState(true);
   
	const [search, setSearch] = useState("");
  	const [data, setData] = useState([]);
  	const [filter, setFilter] = useState([]);

    useEffect(() => {
        if(props.userDetails.handle){
            dispatch(
                fetchAllUsers({
                    route: "",
                    subscriptionFilter: "Ultimate Advising Student",
                })
            );
           
        }
        dispatch(fetchAppHourUsedLogs())
		
	}, [props.userDetails]);

    useEffect(() => {
		if (props.fetchAllUsers.loading) {
            setLoading(true)

			// Swal.fire({
			// 	allowOutsideClick: false,
			// 	didOpen: () => {
			// 	Swal.showLoading();
			// 	},
			// });
		} else {
			
			if (props.fetchAllUsers.allusers.length) {
				setData(props.fetchAllUsers.allusers);
				setFilter(props.fetchAllUsers.allusers);	
			}else{
				setData([]);
				setFilter([]);	
			}
			
			setLoading(false)
		}
	}, [props.fetchAllUsers]);

    const [logsData, setLogsData] = useState([]);

    useEffect(() => {
        if (props.fetchAppHourUsedLogs.loading) {
        } else {
            if (props.fetchAppHourUsedLogs.logs.length) {
                setLogsData(props.fetchAppHourUsedLogs.logs)
            }else{
                setLogsData([]) 
            }	
        }
        
	}, [props.fetchAppHourUsedLogs]);


    const hourOptions = [
        {
            label: '.5',
            value: '0.5',
        },
        {
            label: '.75',
            value: '0.75',
        },
        {
            label: '1',
            value: '1',
        },
        {
            label: '1.5',
            value: '1.5',
        },
        {
            label: '2',
            value: '2',
        },
    ];
    const [selectedHour, setSelectedHour] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [psValue, setPsValue] = useState(0)
    const [actValue, setActValue] = useState(0)
    const [miValue, setMiValue] = useState(0)
    const [secValue, setSecValue] = useState(0)
    const [generalValue, setGeneralValue] = useState(0)
    const [totalHour, setTotalHour] = useState(0)

    const handleHours = (data) =>{
        console.log(data)
        if(data.category == "personal_statement"){
            setPsValue(data.value)
        }else if(data.category == "activities"){
            setActValue(data.value)
        }
        else if(data.category == "mock_interview"){
            setMiValue(data.value)
        }else if(data.category == "secondaries"){
            setSecValue(data.value)
        }else{
            setGeneralValue(data.value)
        }
        setNoValueError("")
    }

    // const [selectedHistory, setSelectedHistory] = useState([]);
    // const [selectedHistoryLoading, setSelectedHistoryLoading] = useState(true);
    
    const  editUserDetails = (e,userID) =>{
        setSelectedUser([])
        dispatch(fetchUserDetails({ userId: userID }));
        dispatch(fetchCoaches({}));
        setCoach("");
        setSessionDate()
        setSessionFrom()
        setSessionTo()
        setComment()
        setHideError(true)
        setNoValueError("")
        setModal(true)
    }

    const managePremiumHours = (e,userID) =>{
        dispatch(fetchUserDetails({ userId: userID }));
        dispatch(fetchCoaches({}));
        setCoach("");
        setModal2(true)
    }

    
	useEffect(() => {
		if (props.fetchUserDetails.userdetails.length) {
            let user = props.fetchUserDetails.userdetails
            console.log(user)
            setSelectedUser(props.fetchUserDetails.userdetails[0])
            calculateTotalHours(props.fetchUserDetails.userdetails[0])
        }else{
            setSelectedUser([])
        }
    },[props.fetchUserDetails.userdetails])

    // useEffect(() => {
    //     if(selectedUser){
    //         calculateTotalHours(selectedUser)
            
    //     }
    // }, [selectedUser]);

    const calculateTotalHours = (selectedUser) =>{
         if(typeof selectedUser.totalHoursUsed !== "undefined"){
            let total = 0
            selectedUser.totalHoursUsed.map((v,i)=>(
                total+= Number(v.value)
            ))
            setTotalHour(total)
        }else{
            setTotalHour(0)
        }
    }

    const columns = [
        {
			name: "Name",
			sortable: true,
			wrap: true,
			width: '250px',
			selector: (row) => {
				return(
					<Media className="align-items-center">
						{
							typeof row.profilePicture != "undefined" ? (
							<div className="card-profile-div-sm card-profile-div-md">
								<div className="card-profile-image">
									<div
									className="profile-picture-sm profile-picture-md"
									style={{
										width: "40px",
										height: "40px",
										backgroundImage:
											'url("https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/' +
											row.profilePicture +
											'")',
									}}
									>
										
									</div>
								</div>
							</div>
							) : 
							(
							<div className="rounded-circle profile-letter-all-users mr-2" 
							style={{
							width: "30px",
							height: "30px",
								fontSize: "16px"
								}}>
								{row.name !== undefined ? row.name.trim()[0].toUpperCase() : "1" }
							</div>
							)
						}
						<span className="mb-0 text-sm">{row.name}</span>
					</Media>
				)
			}
		},
		{
			name: "Premium Hours",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return row.tutorHours
			}
		},
        {
            name: "Cumulative GPA",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return (typeof row.grades !== "undefined") ? row.grades.length == 0 ? "0.0" : Number.isInteger(row.grades.cumulativeTotal) ? row.grades.cumulativeTotal.toFixed(1) : row.grades.cumulativeTotal : "0.0"
			}
            
        },
         {
            name: "Science GPA",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return (typeof row.grades !== "undefined") ? row.grades.length == 0 ? "0.0" : Number.isInteger(row.grades.cumulativeScience) ? row.grades.cumulativeScience.toFixed(1) : row.grades.cumulativeScience : "0.0"
			}
            
        },
        {
			name: "Personal Statement",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return (typeof row.totalHoursUsed !== "undefined") ? row.totalHoursUsed[0].value : 0
			}
		},
        {
			name: "Activities",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return (typeof row.totalHoursUsed !== "undefined") ? row.totalHoursUsed[1].value : 0
			}
		},
        {
			name: "Mock Interview",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return (typeof row.totalHoursUsed !== "undefined") ? row.totalHoursUsed[2].value : 0
			}
		},
        {
			name: "Secondary Essay",
			sortable: true,
            wrap: true,
			selector: (row) => {
				return (typeof row.totalHoursUsed !== "undefined") ? row.totalHoursUsed[3].value : 0
			}
		},
        {
            name: "Manage",
            width: "300px",
            wrap: true,
            cell: (row) => (
                <>
                    <button 
                        onClick={(e) =>editUserDetails(e, row.userId)}
                        className="btn btn-sm btn-primary text-truncate"
                        title="Bonus Coach/Application Hours"
                    >
                        Bonus Coach/Application Hours
                    </button>
                    <button
                        onClick={(e) =>managePremiumHours(e, row.userId)}
                        className="btn btn-sm btn-primary text-truncate"
                        title="Premium Hours"
                    >
                        Premium Hours
                    </button>
                </>
            ),
           
            center: "true",
        },
    ];
    
    const tableHeaderstyle = {
        headCells: {
        style: {
            fontWeight: "bold",
            fontSize: "14px",
            // backgroundColor: "#ccc",
        },
        },
    };

    useEffect(() => {
		const result = data.filter((item) => {
			if(item){
				return (
					modifiedSearch(item.name).match(search.toLocaleLowerCase()) || 
					modifiedSearch(item.email).match(search.toLocaleLowerCase()) 
				)
			}
		});
		
    	setFilter(result);
		
	}, [search]);

	const modifiedSearch = (item) =>  {
		if(item !== undefined){
			if(item.name !== undefined){
				return  item.name.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}

    const [coach, setCoach] = useState();
	const [coachOption, setCoachOption] = useState([]);
    const selectACoach = (e) => {
		// const values = [...e].map((opt) => opt.value);
		setCoach(e.value);
		
	};

    useEffect(() => {
        if (props.fetchCoaches.fetchcoaches.length) {
            const coachesOption = props.fetchCoaches.fetchcoaches[0];
            setCoachOption(coachesOption);
        }
    }, [props.fetchCoaches]);


    const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }

    const convertTimeToUTC = (time) => {
        return moment(moment(time).utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }


    //Modal1 Behaviour
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const [sessionDate, setSessionDate] = useState("")
    const [sessionFrom, setSessionFrom] = useState("")
    const [sessionTo, setSessionTo] = useState("")
    
    const handleChangeDate = (e) =>{
        setSessionDate(new Date(e.toJSON()));
    }
    const handleChangeFrom = (e) =>{
        setSessionFrom(new Date(e.toJSON()));
    }
    const handleChangeTo = (e) =>{
        setSessionTo(new Date(e.toJSON()));
    }

    const useHoursObj = [
        {category: "personal_statement", value: Number(psValue), prev: 0, max: 7},
        {category: "activities", value: Number(actValue) , prev: 0, max: 5},
        {category: "mock_interview", value: Number(miValue) , prev: 0, max: 2},
        {category: "secondaries", value: Number(secValue) , prev: 0, max: 6},
        {category: "general_coaching", value: Number(generalValue) , prev: 0, max: 6},
    ];
    const [noValueError, setNoValueError] = useState()
    const  saveUserSession = () =>{
        setHideError(false)

        let errors = 0
        if(props.userDetails.admin){
            if(!sessionDate || !sessionFrom || !sessionTo || !comment || !coach){
               errors = 1
            }else if(psValue === 0 && actValue === 0 && miValue === 0 && secValue == 0 && generalValue === 0) {
               setNoValueError("Warning! Please add an hour to a category to set changes.")
                errors = 1
            }else{
                errors = 0
            }
        }else{
            if(!sessionDate || !sessionFrom || !sessionTo || !comment ){
                errors = 1
            }else{
                errors = 0
            }
        }
    
      
        if(typeof selectedUser.totalHoursUsed !== "undefined"){

            let updatedHour = []
           
            useHoursObj.forEach(function(d,i){
             
                if(typeof selectedUser.totalHoursUsed[i] !== "undefined"){
                    if(d.category == selectedUser.totalHoursUsed[i].category){
                        let updateValue =   Number(d.value) + Number(selectedUser.totalHoursUsed[i].value)
                        d.prev = selectedUser.totalHoursUsed[i].value
                        d.value = updateValue
                    }
                }
                updatedHour.push(d)
            });
          
            if(errors == 1){
              
            }else{
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to add hours?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes,'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // setModal(!modal)
                        const setData = {
                            by: props.userDetails.handle,
                            updatedBy: coach ? coach : props.userDetails.handle,
                            handle: selectedUser.handle,
                            updateUsedHour: updatedHour,
                            date: moment(new Date(sessionDate)).format(),
                            startTime: convertTimeToUTC(sessionFrom).format(),
                            endTime: convertTimeToUTC(sessionTo).format(),
                            comment: comment,
                            mailDetails: {
                                coach: props.userDetails.name,
                                coachEmail: props.userDetails.email,
                                student: selectedUser.name,
                                studentEmail: selectedUser.email,

                            }
                        }
                        selectedUser.totalHoursUsed = updatedHour
                        
                        calculateTotalHours(selectedUser)
                        dispatch(updateUserDetails(setData));
                        setPsValue(0)
                        setActValue(0)
                        setMiValue(0)
                        setSecValue(0)
                        setGeneralValue(0)
                        dispatch(fetchAppHourUsedLogs({handle: selectedUser.handle}))
                        dispatch(sendEmailApplicationHourUsed(setData))
                        setHideError(true)
                        setNoValueError("")
                        setComment("")
                    
                    }
                })
            }
           

        }else{  
            if(errors == 1){
            }else{
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to add hours?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes,'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // setModal(!modal)
                        const setData = {
                            by: props.userDetails.handle,
                            updatedBy: coach ? coach : props.userDetails.handle,
                            handle: selectedUser.handle,
                            updateUsedHour: useHoursObj,
                            date: moment(new Date(sessionDate)).format(),
                            startTime: convertTimeToUTC(sessionFrom).format(),
                            endTime: convertTimeToUTC(sessionTo).format(),
                            comment: comment,
                            mailDetails: {
                                coach: props.userDetails.name,
                                coachEmail: props.userDetails.email,
                                student: selectedUser.name,
                                studentEmail: selectedUser.email,

                            }
                        }
                        selectedUser.totalHoursUsed = useHoursObj
                        calculateTotalHours(selectedUser)
                        dispatch(updateUserDetails(setData));
                        setPsValue(0)
                        setActValue(0)
                        setMiValue(0)
                        setSecValue(0)
                        setGeneralValue(0)
                        dispatch(fetchAppHourUsedLogs({handle: selectedUser.handle}))
                        dispatch(sendEmailApplicationHourUsed(setData))
                        setHideError(true)
                        setNoValueError("")
                        setComment("")
        
                    }
                })
            }
        }

    }

    useEffect(() => {
        if (props.updateUserDetails.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            if(props.updateUserDetails.userdetails.length){
                Swal.fire({
                    title: "Success!",
                    text: "Successfully updated!",
                    icon: "success",
                    timer: 3000,
                }).then(() => {
                    dispatch(updateUserDetailsReset());
                    dispatch(
                        fetchAllUsers({
                            route: "",
                            subscriptionFilter: "Ultimate Advising Student",
                        })
                    );
                });

            }
            
        }
    }, [props.updateUserDetails]);

    const [hideError, setHideError] = useState(true)
    const [hourErrorMsg,setHourErrorMsg] = useState("")
    const [phour, setPhour] = useState("")
    const handleHourChange = (e) => {
        const hvalue = e.target.value
        setPhour(hvalue)
        
    }

    const [comment, setComment] = useState("")
    const handleCommentChange = (e) =>{
        setComment(e.target.value)
    }

    //Modal2 Behaviour
    const [modal2, setModal2] = useState(false);
    const modalToggle2 = () => setModal2(!modal2);
    const [backdrop2, setBackdrop2] = useState(true);

    const savePremiumHour = (e,mode) => {
        setHideError(false)
    
        let caclHour = 0
        let errors = 0
        if(props.userDetails.admin){
            if(!phour || !comment || !coach){
               errors = 1
            }else{
                errors = 0
            }
        }else{
            if(!phour || !comment ){
                errors = 1
            }else{
                errors = 0
            }
        }
        if(mode == "add"){
            caclHour =  Number(selectedUser.tutorHours) + Number(phour)
        }else{
            if(Number(phour) > Number(selectedUser.tutorHours)){
                setPhour("")
                errors = 1
                setHourErrorMsg( "Deduct hour should not be greater than the current value " )
            }
            
            caclHour =  Number(selectedUser.tutorHours) - Number(phour)
        }

        if(errors == 1){
            
        }else{
            const logs = {
                by: props.userDetails.handle,
                coachHandle: coach ? coach : props.userDetails.handle,
                handle: selectedUser.handle,
                premItem: {
                    previousHour : selectedUser.tutorHours,
                    mode: mode,
                    hour: phour,
                    currentTotal: String(caclHour),
                    comment: comment,
                },
                mailDetails: {
                    coach: props.userDetails.name,
                    coachEmail: props.userDetails.email,
                    student: selectedUser.name,
                    studentEmail: selectedUser.email,
                }
            }
            dispatch(updateUserDetails(logs));
            selectedUser.tutorHours= String(caclHour)
            setPhour("")
            setComment("")
            setCoach("")
            setHideError(true)
            dispatch(fetchPremiumHourLogs({handle: selectedUser.handle}))
            // dispatch(sendEmailApplicationHourUsed(setData))
        }
    }




    return (
        <>
			<Header></Header>
            <Container className="mt--7" fluid>
				<Row>
                    <Col lg="12">
                        <Card className="shadow">
                            <CardBody>
                                <CardTitle>
                                    Log Student Hours
                                </CardTitle>
                                { 
											
                                    loading ? 
                                        <div className="m-5">
                                            <Alert color="secondary" className="text-center">
                                                Fetching students.. Please wait while loading {" "}
                                                <Spinner size="sm" color="primary" />{" "}
                                            </Alert>
                                        </div>
                                    : data.length ? 
                                            <DataTable
                                                progressPending={loading}
                                                customStyles={tableHeaderstyle}
                                                columns={columns}
                                                data={filter}
                                                pagination
                                                wrap
                                                // selectableRows
                                                // fixedHeader
                                                selectableRowsHighlight
                                                highlightOnHover
                                                subHeader
                                                subHeaderComponent={
                                                <input
                                                    type="text"
                                                    className="w-50 form-control"
                                                    placeholder="Search..."
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                                }
                                                subHeaderAlign="right"
                                            />
                                    : 
                                    <Alert color="secondary">No data available .</Alert>
                                
                                
                                }

                            </CardBody>
                        </Card>

                    </Col>
                    <Col lg="5">
                        {/* <Logs items={logsData}/> */}
                    </Col>
                </Row>
            </Container>
            <Modal
                isOpen={modal}
                toggle={modalToggle}
                backdrop={backdrop}
                size="lg"
                centered
            >
                <ModalHeader toggle={modalToggle}>
                    Manage Student's Application Hours
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Card className="mb-2 shadow">
                        <CardBody>
                            <Row>
                                <Col xl="6" className="d-flex align-items-center">
                                    {
                                        selectedUser ? 
                                            <Media className="align-items-center">
                                                {
                                                    typeof selectedUser.profilePicture != "undefined" ? (
                                                        <div className="media">
                                                            <img 
                                                                src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
                                                                className="mr-3" 
                                                                alt="..." 
                                                                style={{
                                                                    width: "60px",
                                                                    height: "60px", 
                                                                }}
                                                            />
                                                            <div className="media-body">
                                                                <h4 className="mt-0">{selectedUser.name}</h4>
                                                                <small>{selectedUser.email}</small>
                                                            
                                                            </div>
                                                        </div>
                                                
                                                    ) : 
                                                    (
                                                        <div className="media">
                                                            <div className="rounded-circle profile-letter-all-users mr-2" 
                                                                style={{
                                                                width: "60px",
                                                                height: "60px",
                                                                    fontSize: "16px"
                                                                    }}>
                                                                    {selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="my-0">{selectedUser.name}</h5>
                                                                <small>{selectedUser.email}</small>
                                                            
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                
                                            </Media>
                                        : ""
                                    }
                                </Col>
                                <Col xl="6"  className="mt-2">
                                    <Card className="card-stats mb-4 mb-xl-1 w-100 shadow">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase mb-0"
                                                    >
                                                        Application Hours Used
                                                    </CardTitle>
                                                   
                                                </div>
                                                <Col className="col-auto">
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {totalHour}
                                                    </span>
                                                    {/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                        <i className="fas fa-atom" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    
                    <Row className="mt-3" >
                       
                        <Col lg="6" >
                            <FormGroup>
                                <Label >Date *</Label>
                                <ReactDatetime
                                    onChange={(e) => handleChangeDate(e)}
                                    timeFormat={false}
                                    value={sessionDate} 
                                    className="w-100"
                                    inputProps={{ placeholder: "Select Date" }}
                                    
                                />
                                <Input
                                    type="hidden"
                                    onChange={(e) => handleChangeDate(e)}
                                    value={sessionDate} 
                                    invalid={!hideError ? sessionDate ? "" : "true" : ""}
                                />
                                <FormFeedback>This field is required!</FormFeedback>
                            </FormGroup>
                            <Row>
                                <FormGroup className="col-lg-6">
                                    <Label >From*</Label>
                                    <ReactDatetime
                                        value={sessionFrom}
                                        onChange={(e) => handleChangeFrom(e)}
                                        timeConstraints={{
                                        minutes: { min: 30, max: 30, step: 31 },
                                        }}
                                        dateFormat={false}
                                        className="w-100"
                                        inputProps={{ placeholder: "Select time" }}
                                        
                                    />
                                    <Input
                                        type="hidden"
                                        value={sessionFrom}  
                                        onChange={(e) => handleChangeFrom(e)}
                                        invalid={!hideError ? sessionFrom ? "" : "true" : ""}
                                    />
                                    
                                    <FormFeedback>This field is required!</FormFeedback>
                                </FormGroup>
                                <FormGroup className="col-lg-6">
                                    <Label >To *</Label>
                                     <ReactDatetime
                                        value={sessionTo}
                                        onChange={(e) => handleChangeTo(e)}
                                        timeConstraints={{
                                        minutes: { min: 30, max: 30, step: 31 },
                                        }}
                                        dateFormat={false}
                                        className="w-100"
                                        inputProps={{ placeholder: "Select time" }}
                                        invalid={!hideError ? sessionTo ? "" : "true" : ""}
                                    />
                                    <Input
                                        type="hidden"
                                        value={sessionTo} onChange={(e) => handleChangeTo(e)}
                                        invalid={!hideError ? sessionFrom ? "" : "true" : ""}
                                    />
                                    <FormFeedback>This field is required!</FormFeedback>
                                </FormGroup>
                            </Row>
                            
                            
                        </Col>
                       
                        <Col lg="6">
                            {
                                props.userDetails.admin == true ?   
                                <FormGroup>
                                    <Label >Select Coach</Label>
                                    <Select

                                        options={coachOption}
                                        classNamePrefix="select"
                                        placeholder="Select a Coach.."
                                        onChange={selectACoach}
                                        formatOptionLabel={(v) => (
                                            <div className="d-flex ">
                                                {v.value ? (
                                                v.image ? (
                                                    <img
                                                    className="rounded-circle  mr-1 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    src={
                                                        "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                                                        v.image
                                                    }
                                                    alt={v.label}
                                                    />
                                                ) : (
                                                    <div
                                                    className="rounded-circle profile-letter-all-users mr-1 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    >
                                                    {v.label ? v.label.trim()[0] : ""}
                                                    </div>
                                                )
                                                ) : (
                                                ""
                                                )}

                                                <span>{v.label}</span>
                                            </div>
                                        )}
                                    />
                                </FormGroup>
                                
                                : ""
                            }
                           
                            <FormGroup>
                                <Input type="textarea" name="text" rows="5" placeholder="Write a comment here..."  value={comment} onChange={handleCommentChange} invalid={!hideError ? comment ? "" : "true" : ""}  />
                                <FormFeedback>This field is required!</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                       

                        {
                            typeof selectedUser.totalHoursUsed !== "undefined" 
                            ? 
                                useHoursObj.map(hObject => ({
                                ...hObject,
                                ...selectedUser.totalHoursUsed.find(myhours => myhours.category === hObject.category)
                                })).map((v, i) => (
                                    <Col  xl="6" className="mb-1" key={i}>
                                        <Card className="card-stats mb-4 mb-xl-0 w-100">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                            {v.category.replace('_',' ')}
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            {v.value}
                                                        </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <Select 
                                                            placeholder='Add..'
                                                            options={hourOptions} 
                                                            styles={customDropdown }  
                                                            onChange={(e) => handleHours({category: v.category, value: e.value})}
                                                            menuPortalTarget={document.body}
                                                            value={hourOptions.find((option) => option.value === selectedHour)}
                                                            
                                                        />  
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                               
                                ))
                                // selectedUser.totalHoursUsed.map((v,i)=>(
                                //     <Col  xl="6" className="mb-1" key={i}>
                                //         <Card className="card-stats mb-4 mb-xl-0 w-100">
                                //             <CardBody>
                                //                 <Row>
                                //                     <div className="col">
                                //                         <CardTitle
                                //                             tag="h5"
                                //                             className="text-uppercase text-muted mb-0"
                                //                         >
                                //                             {v.category.replace('_',' ')}
                                //                         </CardTitle>
                                //                         <span className="h2 font-weight-bold mb-0">
                                //                             {v.value}
                                //                         </span>
                                //                     </div>
                                //                     <Col className="col-auto">
                                //                         <Select 
                                //                             placeholder='Add..'
                                //                             options={hourOptions} 
                                //                             styles={customDropdown }  
                                //                             onChange={(e) => handleHours({category: v.category, value: e.value})}
                                //                             menuPortalTarget={document.body}
                                //                             value={hourOptions.find((option) => option.value === selectedHour)}
                                                            
                                //                         />  
                                //                     </Col>
                                //                 </Row>
                                //             </CardBody>
                                //         </Card>
                                //     </Col>

                                // ))
                            : 
                            useHoursObj.map((v,i)=>(
                                <Col  xl="6" className="mb-1" key={i}>
                                    <Card className="card-stats mb-4 mb-xl-0 w-100">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        {v.category.replace('_',' ')}
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        0
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <Select 
                                                        placeholder='Add..'
                                                        options={hourOptions} 
                                                        styles={customDropdown }  
                                                        onChange={(e) => handleHours({category: v.category, value: e.value})}
                                                        menuPortalTarget={document.body}
                                                        value={hourOptions.find((option) => option.value === selectedHour)}
                                                        
                                                    />  
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                        }

                    </Row>
                    <Row><Col> { noValueError ? <Alert color="danger" className="mt-2">{noValueError}</Alert>  : ""}</Col></Row>
                    <Row>
                        <Col xl="12" className="mt-2">
                            <Card className="shadow">
                                <CardBody>
                                    <CardTitle>
                                        History 
                                    </CardTitle>
                                    {
                                        selectedUser.handle ? 
                                            <StudentFreeHourLogs studenthandle={selectedUser.handle} />
                                        : ""
                                    }
                                    

                                   {/* { 
                                        selectedHistoryLoading ? 
                                            <div className="m-5">
                                                <Alert color="secondary" className="text-center">
                                                    Loading.. Please wait while loading {" "}
                                                    <Spinner size="sm" color="primary" />{" "}
                                                </Alert>
                                            </div>
                                        : 
                                            selectedHistory.length ? 
                                                selectedHistory.map((d,i)=>(
                                                    <Card key={d.docid} className="py-2 px-3 mb-1" role="button">
                                                    
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h5 className="text-uppercase mb-0">{d.item.category.replace("_"," ")} 
                                                            
                                                                </h5>
                                                                <h6 className="text-muted mb-0">Student:  {d.student.name}</h6> 
                                                                <h6 className="text-muted mb-0">Coach: {d.coach.name}</h6> 
                                                            </div>
                                                            <div className="text-center">
                                                                <h4 className="text-success ml-2"> +{Number(d.item.value) - Number(d.item.prev) }</h4>
                                                                <h6 className="text-muted">{convertDate(d.createdAt)}</h6> 
                                                            </div>
                                                        </div>
                                                    
                                                    </Card>
                                                ))
                
                                            : 
                                            <Alert color="secondary">No data available.</Alert>
                                    } */}


                                </CardBody>
                            </Card>
                        </Col>
                        
                    </Row>   
                                    
                </ModalBody>
               
                <ModalFooter>
                   
                    <Button color="secondary" onClick={modalToggle} >
                    Close
                    </Button>
                    
                    <Button color="primary" onClick={saveUserSession} disabled={props.userDetails.admin == true ? !coach ? "disabled" : "" : "" } >
                    Save
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={modal2}
                toggle={modalToggle2}
                backdrop={backdrop2}
                size="lg"
                centered
            >
                <ModalHeader toggle={modalToggle2}>
                    Manage Student's Premium Hours
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Card className="mb-3 shadow">
                        <CardBody>
                            <Row>
                                <Col xl="6">
                                    {
                                        selectedUser ? 
                                            <Media className="align-items-center">
                                                {
                                                    typeof selectedUser.profilePicture != "undefined" ? (
                                                        <div className="media">
                                                            <img 
                                                                src={"https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" + selectedUser.profilePicture}
                                                                className="mr-3" 
                                                                alt="..." 
                                                                style={{
                                                                    width: "60px",
                                                                    height: "60px", 
                                                                }}
                                                            />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">{selectedUser.name}</h5>
                                                                <small>{selectedUser.email}</small>
                                                            
                                                            </div>
                                                        </div>
                                                
                                                    ) : 
                                                    (
                                                        <div className="media">
                                                            <div className="rounded-circle profile-letter-all-users mr-2" 
                                                                style={{
                                                                width: "60px",
                                                                height: "60px",
                                                                    fontSize: "16px"
                                                                    }}>
                                                                    {selectedUser.name !== undefined ? selectedUser.name.trim()[0].toUpperCase() : "1" }
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="my-0">{selectedUser.name}</h5>
                                                                <small>{selectedUser.email}</small>
                                                            
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                
                                            </Media>
                                        : ""
                                    }
                                </Col>
                                <Col xl="6"  className="mt-2">
                                    <Card className="card-stats mb-4 mb-xl-0 w-100 shadow">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase mb-0"
                                                    >
                                                        Remaining Premium Hours
                                                    </CardTitle>
                                                   
                                                </div>
                                                <Col className="col-auto">
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {selectedUser.tutorHours}
                                                    </span>
                                                   
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col lg="4" className="mb-2">
                            <FormGroup>
                                <Label >Hours</Label>
                                <Input type="number" name="premiumhours"  placeholder="Input hours.." value={phour} onChange={(e) => handleHourChange(e)} invalid={!hideError ? phour ? "" : "true" : ""}  />
                                <FormFeedback>{hourErrorMsg ? hourErrorMsg : "The field is required!"}</FormFeedback>
                            </FormGroup>

                        {
                           props.userDetails.admin == true ?   
                                <FormGroup>
                                    <Label >Choose a coach</Label>
                                    <Input type="hidden" invalid={!hideError ? coach ? "" : "true" : ""} />
                                    <Select
                                      
                                        options={coachOption}
                                        classNamePrefix="select"
                                        placeholder="Select a Coach.."
                                        onChange={selectACoach}
                                        formatOptionLabel={(v) => (
                                            <div className="d-flex ">
                                                {v.value ? (
                                                v.image ? (
                                                    <img
                                                    className="rounded-circle  mr-1 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    src={
                                                        "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                                                        v.image
                                                    }
                                                    alt={v.label}
                                                    />
                                                ) : (
                                                    <div
                                                    className="rounded-circle profile-letter-all-users mr-1 "
                                                    style={{ width: "25px", height: "25px" }}
                                                    >
                                                    {v.label ? v.label.trim()[0] : ""}
                                                    </div>
                                                )
                                                ) : (
                                                ""
                                                )}

                                                <span>{v.label}</span>
                                            </div>
                                        )}
                                    />
                                    <FormFeedback  >This field is required!</FormFeedback>
                                </FormGroup>
                                

                            
                            : ""
                        }

                        </Col>
                        <Col lg="8">
                            <FormGroup>
                                <Label >Write a comment</Label>
                                <Input type="textarea" name="text" rows="5" placeholder="Type here..."  value={comment} onChange={handleCommentChange} invalid={!hideError ? comment ? "" : "true" : ""}  />
                                <FormFeedback>This field is required!</FormFeedback>
                               
                            </FormGroup>

                        </Col>
                    </Row>
                     <Row>
                        <Col xl="12" className="mt-2">
                            <Card className="shadow">
                                <CardBody>
                                    <CardTitle>
                                        History 
                                    </CardTitle>
                                    {
                                        selectedUser.handle ? 
                                            <StudentPremiumHourLogs studenthandle={selectedUser.handle} />
                                        : ""
                                    }
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>  
                </ModalBody>
                <ModalFooter>
                    <Button color="danger"  onClick={(e) => savePremiumHour(e, "deduct")}  >
                    Deduct Hour
                    </Button>
                    
                    <Button color="primary" onClick={(e) => savePremiumHour(e, "add")} 
                    >
                    Add Hour
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        fetchAllUsers: state.fetchAllUsers,
        fetchUserDetails: state.fetchUserDetails,
        updateUserDetails: state.updateUserDetails,
        fetchAppHourUsedLogs: state.fetchAppHourUsedLogs,
        fetchPremiumHourLogs: state.fetchPremiumHourLogs,
        sendEmailApplicationHourUsed: state.sendEmailApplicationHourUsed,
        fetchCoaches: state.fetchCoaches,
    }
      
}

export default connect(mapStateToProps)(ManageApplicationHours)
